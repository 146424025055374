import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';




const StockHeader = ({name, image, tagline})=>{
    return(
        <>
        <Row className='mt-3'>
            <Col sm={12} md={4} className="stock-header-img" >
            <Image  src={require(`../assets/logos/${image}`)}  rounded width="200px" height="auto" className="text-right"/>
        </Col>
        <Col sm={12} md={8} className="m-auto px-1 ">
        <h2 className="mb-3  stock-name"> {name}</h2>
        <p className="stock-tagline"> {tagline} </p>
        </Col>
        </Row>
        </>
    );
}

export default StockHeader;