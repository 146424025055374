import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect,useContext } from "react";
import LoadingSpinner from '../Components/LoadingSpinner';
import { AiOutlineUser } from "react-icons/ai";
import AuthContext from '../Components/AuthContext';
import Page from "../Components/Page";


const UpdatePlayerDetails = ()=>{

  const navigate = useNavigate();

  const[showPage, setShowPage] = useState(false);  
  const { authToken }= useContext(AuthContext);


  const [details,setDetails]=useState({
    first_n:'',
    last_n:'',
    age_g:''
});

    const [show1, setShow1] = useState(false);
    const handleClose1 = () => {
        setShow1(false);   
        navigate('/game/account/');     
    }    
    const handleShow1 = () => {
        setShow1(true);
    }   
    
    const [show2, setShow2] = useState(false);
    const handleClose2 = () => {
        setShow2(false);   
        navigate('/game/account/');     
    }    
    const handleShow2 = () => {
        setShow2(true);
    } 


  useEffect(() => {
    getProfileDetails();      
  }, []);

  function changeDetails(e){    
    setDetails({...details,[e.target.name]:e.target.value});  
  }

  const getProfileDetails = async () =>{
    const response = await fetch('/api/playerProfile/', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + String(authToken.access)
        }
    })
    const data = await response.json();
    if(!('message' in data)){
        setDetails(details => ({...details, first_n:data.user.first_name}));
        setDetails(details => ({...details, last_n:data.user.last_name}));
        setDetails(details => ({...details, age_g:data.age_group}));
      
      setShowPage(true);
    }
    else alert(data.message)
    
    }

    const updateDetails = async(e)=>{
        e.preventDefault();
        const response = await fetch('/api/updateUserDetails/', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization' : 'Bearer ' + String(authToken.access)
            },
            body: JSON.stringify({
                first_name: `${details.first_n}`,
                last_name: `${details.last_n}`,
                age_g: `${details.age_g}`,
             })
        })
        const data = await response.json();
        if(data.message === 'USER_DETAILS_UPDATED'){
            handleShow1();
        }
        else{
            handleShow2();
        }
    }

  

    return (<>
        <Page title='Account Update'/>
        <Row className='home-header'>  
        
        <div className="p-1 mb-2 text-center" >
        <span className="inv-color fs-1 roboto-font"> Inve$tor </span> 
        <span className="quest-color fs-1 roboto-font"> Quest TT 2.0 <AiOutlineUser size={30} style={{ fill: 'white' }} /> 
        </span>
        
        <p className="text-light fs-3 roboto-font"> 
        Unlock The Investor In You! </p>
            
        </div>   

    </Row>

    <Row className='border border-2 border-dark mb-5'>
    <Col xs={1}></Col>   

    <Col xs={10} >
    
    { (showPage) ?
    <>
    
    
    <Row>
    <Form className=' mb-3' onSubmit={updateDetails} >
      <Table responsive="lg" bordered  className='my-5'>
        <thead>
          <tr className='bg-profile'>
            <th colSpan={2} className='text-light roboto-font fs-5'>Update Account Details</th>
          </tr>
        </thead>
        <tbody className='fs-5 roboto-font '>

          <tr >
            <td width="30%" className='px-5 ttsec-blue fw-bold'>
            
            
              <Form.Group className="mb-1" >
              <Form.Label className="form-labels fs-6">
                First Name 
              </Form.Label>
              <Form.Control className="mb-3" type="text" maxlength= "100" name = 'first_n' value={details.first_n} onChange={changeDetails} required />
            </Form.Group>            

              
              <Form.Group className="mb-1" >
              <Form.Label className="form-labels fs-6">
                Last Name                
              </Form.Label>
              <Form.Control className="mb-3" type="text" maxlength= "100"  name = 'last_n' value={details.last_n} onChange={changeDetails} required />
             
            </Form.Group>
          
              <Form.Group className="mb-1" >
                <Form.Label className="form-labels fs-6">
                  Age Group
                </Form.Label>
                <Form.Select className="mb-3"  value={details.age_g} name = 'age_g'  onChange={changeDetails} required>
                <option value="1"> 13 - 17 </option>
                <option value="2"> 18 - 35 </option>
                <option value="3"> 36 - 45 </option>     
                <option value="4"> 46 - 55 </option>     
                <option value="5"> 56+ </option>     
                </Form.Select>
            </Form.Group>  

            <div className='text-end'>
              <Button variant='primary' size='sm' className='mx-1' type="submit">
                Update 
              </Button>
              <Button variant='primary' size='sm' onClick={() => navigate("/game/account/")}>
                Cancel
              </Button>
            </div>      
            </td>

            
          </tr>

          
         
        </tbody>
      </Table>
      </Form> 
      
      

    </Row>
    </>
    :
    <>
        <div className="p-3 mb-3 text-center">
            <LoadingSpinner/>
        </div>
    </>

    }


    </Col>    

    <Col xs={1}></Col>    
    
</Row>

<Modal show={show1} onHide={handleClose1} centered backdrop='static' keyboard={false}>  
        <Modal.Header closeButton className='bg-dark py-1'>
            <Modal.Title className='text-light fs-3 text-center  '> 
              Updating Account Data
            </Modal.Title>
          </Modal.Header>      
        <Modal.Body className='bg-light'> 
            <p className='fs-5 '> Data Successfull Updated</p> 
                    
        </Modal.Body>
        <Modal.Footer className='bg-dark py-1'>
            <Button variant="warning" onClick={handleClose1}> Continue </Button>             
        </Modal.Footer>
    </Modal>

    <Modal show={show2} onHide={handleClose2} centered backdrop='static' keyboard={false}>  
        <Modal.Header closeButton className='bg-dark py-1'>
            <Modal.Title className='text-light fs-3 text-center  '> 
              Updating Account Data
            </Modal.Title>
          </Modal.Header>      
        <Modal.Body className='bg-light'> 
            <p className='fs-5 '> Oops! Something went wrong, try again later</p> 
                    
        </Modal.Body>
        <Modal.Footer className='bg-dark py-1'>
            <Button variant="warning" onClick={handleClose2}> Continue </Button>             
        </Modal.Footer>
    </Modal>
      
   
    </>);
}

export default UpdatePlayerDetails;