import { useState,  useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import startJourney from '../assets/audio/start-your-journey.mp3';
import Modal from 'react-bootstrap/Modal';
import { BsChevronDoubleRight } from "react-icons/bs";
import YouTubeVideo from '../Components/YoutubeVideo';
import Page from "../Components/Page";


const Step1 = ()=>{
    const navigate = useNavigate();    
    const audio = new Audio(startJourney);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
      setShow(true);
      audio.play();
    }

    useEffect(() => {
        handleShow();        
      }, []);

      
    return (<>
    <Page title='Get Started'/>
    <Row className='starting-header'>  
        
        <div className="p-1 mb-2 text-center" >
        <span className="text-light fs-1 roboto-font"> Inve$tor Quest TT 2.0</span>  
        
        <p className="text-light fs-3 roboto-font"> 
        Unlock The Investor In You! </p>
            
        </div>   

       
        <div>
        <span className="text-warning fs-5 roboto-font">   Getting Started </span>
        </div>

    </Row>

    <Row className='border border-2 border-dark mb-5'> 
    <Col xs={1}></Col> 

    <Col xs={10} > 

    <Row className='my-3 text-center'>
    <p className=' fs-4 roboto-font ttsec-blue'> The Trinidad and Tobago Securities and Exchange Commission presents: </p>
    <p>
        <span className="inv-color fs-1 roboto-font"> Inve$tor </span> 
        <span className="quest-color fs-1 roboto-font"> Quest TT 2.0</span>
        
    </p>
    </Row>

    <Row className='mt-2  mb-5'>
     <div className='justify-text roboto-font fs-5 '>
     An investing game that is designed to provide users with a practical understanding and 
     exposure to buying and selling (trading) on a stock market. 
     </div> 

     <p className='mt-4 mb-2 home-about-color roboto-font fs-4'>Getting Started with Investor Quest:</p>
     <YouTubeVideo videoURL={'https://www.youtube.com/watch?v=uwz6rWLGiV8'} />
    </Row>

    <Row> {/* Playing the game section */}
    <Col>
    <p className='my-3 quest_stock-heading1'>Important Game Details: </p>
    {/* Start Playing the game */}
    <p className='ttsec-blue fs-4 roboto-font '> Playing the Game: 
    <span className='getting-started-c1'> You can play multiple games also known as quests. </span></p> 
    
    <p className='fs-5 currency justify-text'>
    <BsChevronDoubleRight size={20} style={{ fill: '#ffc107' }} /> There are two (2) types of 
    quests: The ongoing Master Game (Investor Quest) and Mini-Quests with start  and end dates. 
    These mini-quest can pop up at any time and users may have the chance to win prizes.
    </p>
    <p className='fs-5 currency justify-text mt-2'>
    <BsChevronDoubleRight size={20} style={{ fill: '#ffc107' }} /> Each player automatically joins 
    the master quest, however, mini-quests maybe targeted games for specific users.
    </p>
    <p className='fs-5 currency justify-text mt-2 mb-5'>
    <BsChevronDoubleRight size={20} style={{ fill: '#ffc107' }} /> 
    <span className='fw-bold'> Important:</span> Within the games, trades are
     not executed immediately (the transactions would be completed within 24 hours). 
    </p>
    

  {/* End Playing the game */}

   {/* Start Twin Island Hub */}
   <p className='ttsec-blue fs-4 sriracha-font  mt-4'> Twin Island Stock Exchange: 
    <span className='getting-started-c1 roboto-font'> Investor Quest Virtual Stock Market. </span></p> 
    
    <p className='fs-5 currency justify-text'>
    This simulated stock exchange comprises of fictitious companies registered to trade in 
      Investor Quest TT. The Twin Island Stock Exchange currency is quoted in <span className='currency'> $ </span> which 
      represents Investor Quest virtual currency and has the same exchange rate as TT dollars. 
      You can trade in:
    </p>
    <ul className='roboto-font fs-6 px-5' type='square'>
          <li className='my-2'>STOCKS - Fifteen (15) Registered Companies.</li>
          <li className='my-2'>MUTUAL FUNDS - Five (5) Registered Companies</li>
          <li className='my-2'>BONDS - Five (5) Government and Corporate Bonds.</li> 
  </ul>
  <p className='fs-5 currency justify-text mb-5'>
  Also available in our Twin Island Stock Exchange, is a profile on each security offered in our 
  virtual game.  Do your research and choose the security that will help your money and 
  investment portfolio  grow. 
    </p>

  {/* End Twin Island Hub */}

  {/* Start News Alert */}
  <p className='ttsec-blue fs-4 roboto-font mt-4'> News Alerts: 
    <span className='getting-started-c1'> Events Occuring in the Investor Quest World. </span></p> 
    
    <p className='fs-5 currency justify-text'>
    News Alerts give information about activities in the Investor Quest World. 
    These activities would lead to price changes in the companies listed in the Twin Island 
    Stock Exchange. You'll need to analyse these activites in order to be a wise investor 
    and make the right decisions when trading. Remember, all investments include some level of risk. 
    </p>
  {/* End News Alert */}


    </Col>

    </Row>

    <Row className='my-3'>
      <Col className='text-end'>
      <Button variant="warning"  onClick={() => navigate("/start/broker")}>  
      Next Step
    </Button>
      </Col>
    
    </Row> 
    
    </Col>
    


    <Col xs={1}></Col>  
    </Row>
    
    

    <Modal show={show} onHide={handleClose} backdrop='static'  centered>
          
          <Modal.Header closeButton className='bg-primary'>
            <Modal.Title className='text-light roboto-font fs-3 text-center  '> 
              Welcome to Inve$tor Quest
            </Modal.Title>
          </Modal.Header>
          

          <Modal.Body> 
          <div className='text-center my-3'>
            <p className='roboto-font fs-1 '> Unlock The Investor In You!</p>
            <Image src={require('../assets/images/animated-chest.gif')} width="50%" height="auto"/>
          </div>
          </Modal.Body>

          <Modal.Footer className='bg-primary'>
          <Button variant="warning" onClick={handleClose}> Close </Button>
          </Modal.Footer>
      </Modal>

    

        
    </>)
}

export default Step1;