import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Page from "../Components/Page";
const AboutStockMarket = ()=>{    

    
    return(<>
    <Page title='Twin Island Stock Exchange'/>
    <Row className='border border-2 border-dark mb-3'>
        <Col xs={1}></Col>
        <Col xs={10}> {/* Open body */}

        <Row className="my-4">  
            <p className="fw-bold text-center twin-isl-heading">Twin Island Stock Exchange  </p>

        <div className='fs-5 currency mb-3 justify-text'>
      This simulated stock exchange comprises of fictitious companies registered to trade in 
      Investor Quest TT. Players can buy or sell the securities listed to grow their portfolio. 
      <span className='fw-bold'>Share prices in the Twin Island Stock Exchange are quoted in $ which represents Investor Quest virtual currency
      and has the same exchange rate as TT dollars.</span>
      </div>

      <div className='fs-5 currency mb-3 justify-text'>
      Also available in our Twin Island Stock Exchange, is a profile on each security offered in our 
      virtual game. The information given here would help you choose the securities that will help your money and 
      investment portfolio grow.
      </div>

      <div className='my-4 currency'>
        <h4 className='my-3 text-primary fw-bold'> At the 
        <span className='sriracha-font'> Twin Island Stock Exchange </span> you can trade in: </h4>
        <ul className='fs-5 my-2'>
          <li className='my-2'>STOCKS - Fifteen (15) Registered Companies.</li>
          <li className='my-2'>MUTUAL FUNDS - Five (5) Registered Companies</li>
          <li className='my-2'>BONDS - Five (5) Registered Government and Corporate Bonds.</li>
        </ul>

        
      </div>

      <div className='fs-5 currency mb-3 justify-text'>
      *All companies must be registered by a regulatory authority in order to conduct business 
      in the local securities market.
      </div>
            
        </Row>


        </Col> {/* Close body */}
        
        <Col xs={1}></Col>
        </Row>      

    </>);
}

export default AboutStockMarket;
