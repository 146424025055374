import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { useNavigate} from 'react-router-dom';
import Footer from '../Components/Footer';
import ResetNavBar from './ResetNavBar';
import {useState, useEffect } from 'react';
import Page from "../Components/Page";

const ResetPage1 = ()=>{
    const navigate = useNavigate();
    
    const [email, setEmail] = useState("");
    const [emailAvail, setEmailAvail] = useState(true); 

    

    const changeEmail = (e)=>{
        setEmail(e.target.value);
    }

    const checkEmail = async () => {
        const response = await fetch('/api/emailRegistered/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email: `${email}` })
        });
        const data = await response.json();
        return data.message;
    }

    const resetPassword = async (e) =>{
        e.preventDefault();
        const emailReg = await checkEmail();
        if(emailReg ==="EMAIL_REGISTERED"){
            resetP();
        }
        else{
            setEmailAvail(false);
        }
        
    }

    const resetP = async()=>{
        const response = await fetch('/api/addResetDetails/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                email: `${email}`,
             })
        });
        const data = await response.json();
        if(data.message === "RESET_DETAILS_ADDED"){
            navigate("/resetLink/sent");
        }
        else{
            navigate("/resetLink/err");
        }

    }


    return (<>


<Page title='Your Email Address - Password Reset'/>
 <ResetNavBar />
    <Container fluid>

    <Row className='mt-4'>
            <Col sm={12} md={2} className="d-none d-md-block"> </Col>
            <Col sm={12} md={8} >  {/* Main section */}

            <Row className="align-items-center homepage-header-bg border border-1 border-secondary "> {/* header row*/}
                <Col xs={12} sm={4} className = " text-center  my-3">
                    
                <Image  src={require('../assets/logos/IQlogo.png')}   width="70%" height="auto" />
                </Col>
                <Col xs={12} sm={8}>                
                <p className='fs-3 roboto-font text-light text-center my-2'> Password Reset</p>
                </Col>
            </Row> {/* close header row*/}
            

            <Row className='border border-2 border-dark mb-5'> {/* page body row*/}
            <Col sm={12} md={1} className="d-none d-md-block"> </Col>
            <Col sm={12} md={10}> 

            <p className= ' fs-4 my-4 ms-4 currency'>
                A reset password link would be sent to the email entered below: 
            </p>

            <Form className='p-1 mb-5 mx-3' onSubmit={resetPassword}>

            <Form.Group className="mb-3" >
                    <Form.Label className="form-labels fs-5">    Email:
                    </Form.Label>
                    <Form.Control type="email"  value={email}  name = 'email' onChange={changeEmail} required/>
                    { ( !emailAvail)  ?(
                        <Form.Text className="text-danger"> *Email is not registerd with Inve$tor Quest </Form.Text>)
                        : (<Form.Text className="text-danger">  </Form.Text>)
                    }
                </Form.Group>

                <Button variant="primary"  type='submit'>
                    Reset Password
                </Button>
                <Button variant="primary"  className='mx-2' onClick={() => navigate("/")}>
                    Cancel
                </Button>

            </Form>
            
            </Col>
            <Col sm={12} md={1} className="d-none d-md-block"> </Col>
            </Row>
            </Col>
            <Col sm={12} md={2} className="d-none d-md-block"> </Col>
        </Row>       


</Container>
    

<Footer />
</>);

}

export default ResetPage1;