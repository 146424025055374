import { useParams, useNavigate } from 'react-router-dom';
import {useState, useEffect} from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';


const AddMoreAlerts = ()=>{
    const navigate = useNavigate();
    
    const [sec, setSec] = useState([]);
    const {alertId} = useParams();

    const [show, setShow] = useState(false);   
    const handleClose = () => {
      setShow(false);      
    }
    const handleShow = () => setShow(true);

    const [show1, setShow1] = useState(false);   
    const handleClose1 = () => {
      setShow1(false);      
    }
    const handleShow1 = () => setShow1(true);

    

    const [details,setDetails]=useState({
      stockMF:'',
      effect:'INCREASE',
      percent:'1',
      days:'2'
    });

    useEffect(() => {
      getAllStocksMF();
    }, []);


    function changeDetails(e){    
        setDetails({...details,[e.target.name]:e.target.value});  
    }

    const getAllStocksMF = async()=>{   
      const response = await fetch('/gameapi/allStocksAndMF/', {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }        
    });
    const data = await response.json();
    if(! ('message' in data))  {    
      setSec(data);
      setDetails({...details,['stockMF']:data[0].id});
    }  
  }

    
    const addAffected =  async (e) =>{
      e.preventDefault();    
      const response = await fetch('/gameapi/addNextAffected/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({          
          aid: `${alertId}`,
          stockMF: `${details.stockMF}`,
          effect: `${details.effect}`,
          percent: `${details.percent}`,
          days: `${details.days}`,
         })
    });
    const data = await response.json();
    if(data.message === 'STOCKMF_ADDED'){      
      handleShow();
    }
    else if(data.message === 'STOCKMF_ALREADY_ADDED')
      handleShow1();
    
  }
 


    return (
        <>
           
        <Row >
        <Col md={12} lg={2}>
        </Col>
        <Col md={12} lg={8}>
        
        <Row className="p-1 border border-2 border-secondary rounded mt-3 mb-5"> 
            <Row classname = "my-1">
            <h2 className="my-4 text-center form-heading"> Add Next Stock / Mutual Funds Price Change </h2>
            <p className="text-danger fs-6 "> * All Fields Required </p>

            <Form className=' mb-3' onSubmit={addAffected} > 

           

          
          <Form.Group className="mb-1"as={Row} >
            <Form.Label className="admin-form-labels fs-6" column sm={4}>
                Stock / Mutual Fund
            </Form.Label>
            <Col sm={8}>
            <Form.Select className="mb-3"  value={details.stockMF} name = 'stockMF'  onChange={changeDetails} required>
            { sec.map((s)=>(
              <option key={s.id} value={s.id}> 
                {s.registered_entity}
              </option>
            ))}
                                         
            </Form.Select>
            </Col>
        </Form.Group>

        
        <Form.Group className="mb-1"as={Row} >
            <Form.Label className="admin-form-labels fs-6" column sm={4}>
                Effect On Price
            </Form.Label>
            <Col sm={8}>
            <Form.Select className="mb-3"  value={details.effect} name = 'effect'  onChange={changeDetails} required>
            <option value="INCREASE"> Increase </option>              
            <option value="DECREASE"> Decrease </option>                             
            </Form.Select>
            </Col>
        </Form.Group>
          
          
          <Form.Group className="mb-1" as={Row}>
              <Form.Label className="admin-form-labels fs-6" column sm={4}>
                Percentage Change
              </Form.Label>
              <Col sm={8}>
              <Form.Control className="mb-3" type="number"  min='1' step='0.1'  name = 'percent' value={details.percent} onChange={changeDetails} required />
              </Col>
          </Form.Group>

          <Form.Group className="mb-1" as={Row}>
              <Form.Label className="admin-form-labels fs-6" column sm={4}>
                Number Days
              </Form.Label>
              <Col sm={8}>
              <Form.Control className="mb-3" type="number"  min='2' step='1' name = 'days' value={details.days} onChange={changeDetails} required />
              </Col>
          </Form.Group>

          
              <div className="text-center">
            
            <Button variant="primary" type="submit" >
              Add Data
            </Button>

            <Button variant="primary" className='mx-2'  onClick={() => navigate("/admin/upcomingAlerts/")}>
              No More To Add
            </Button>

          </div> 
             </Form>

            </Row>
        </Row>
        </Col>

        <Col  md={12} lg={2}>
        </Col>
    
    </Row>

    
      <Modal show={show} onHide={handleClose}  centered>  
        <Modal.Header closeButton className='bg-dark py-1'>
            <Modal.Title className='text-light fs-3 text-center  '> 
              Add News Alert - Details
            </Modal.Title>
          </Modal.Header>      
        <Modal.Body className='bg-light'> 
            <p className='fs-4 '> Details Successfully Added.</p> 
                    
        </Modal.Body>
        <Modal.Footer className='bg-dark py-1'>
            <Button variant="warning" onClick={handleClose}> Continue </Button> 
        </Modal.Footer>
    </Modal>

    <Modal show={show1} onHide={handleClose1}  centered>  
        <Modal.Header closeButton className='bg-dark py-1'>
            <Modal.Title className='text-light fs-3 text-center  '> 
              Add News Alert - Details
            </Modal.Title>
          </Modal.Header>      
        <Modal.Body className='bg-light'> 
            <p className='fs-4 '> This Stock / Mutual Fund has already been added.</p> 
                    
        </Modal.Body>
        <Modal.Footer className='bg-dark py-1'>
            <Button variant="warning" onClick={handleClose1}> Continue </Button> 
        </Modal.Footer>
    </Modal>
    </>
    );
}

export default AddMoreAlerts;