import Button from 'react-bootstrap/Button';
import{ useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import AuthContext from '../Components/AuthContext';

const AdminUserTable = ({userData})=>{
    const navigate = useNavigate();
    const { userid }= useContext(AuthContext);

    

    return(<>

<Table  hover className='my-3' responsive>
            <thead className='bg-profile'>    
                <tr className=' text-light fs-5 roboto-font'>
                <th >First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                 <th></th>
                </tr>  
            </thead>
            <tbody className='admin-table-data'>
                { userData.map((u, index) =>(
                    <tr key={index}>
                        <td>{u.user.first_name}</td>
                        <td>{u.user.last_name}</td>
                        <td>{u.user.email}</td>
                        {(userid == u.user.id)?
                        <td></td>
                        :
                        <td>
                            <Button variant="danger" size="sm" onClick={() => navigate(`/admin/deleteAdminUser/${u.user.id}`)} >
                                Delete User
                            </Button>
                        </td>
                        }
                        
                    </tr>
                ))}
            </tbody>
        </Table>

        
    
    </>);
}

export default AdminUserTable;