import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import {Link} from "react-router-dom";
import Logout from "../Components/Logout";
import {  AiOutlineHome, AiOutlineUser, AiOutlineLineChart } from "react-icons/ai";
import {  BsShieldPlus,  BsBell } from "react-icons/bs";






const AdminNavBar = ({firstName})=>{
        
    
    return(
        <>
    <Navbar  variant="dark" expand="lg" className="px-5 mb-4 trading-form-bg ">
      
      <Navbar.Brand as={Link} to="/"> 
       <span className="text-light roboto-font">Inve$tor Quest</span> 
       <span className="text-info roboto-font"> (Admin) </span>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="container-fluid ">
        <Nav.Link as={Link} to="/admin/" className= "fw-bold text-warning" >
          <AiOutlineHome size={25} style={{ fill: '#ffc107' }} /> Admin Home 
          </Nav.Link>

          <NavDropdown title={
                    <span className="text-warning my-auto">
                        <BsShieldPlus size={25} style={{ fill: '#ffc107' }} /> Quests</span>
                }
                id="collasible-nav-dropdown" menuVariant="dark" className= "fw-bold text-warning" >
                
                <NavDropdown.Item as={Link} to="/admin/createQuest/" className= "text-warning" >
                 Create Quest 
                </NavDropdown.Item>

                <NavDropdown.Item as={Link} to="/admin/manageQuest/" className= "text-warning" >
                 Manage Quest
                </NavDropdown.Item>
          </NavDropdown>

          <NavDropdown title={
                    <span className="text-warning my-auto">
                        <BsBell size={25} style={{ fill: '#ffc107' }} />
                        News Alerts</span>
                }
                id="collasible-nav-dropdown" menuVariant="dark" className= "fw-bold text-warning" >
                
                <NavDropdown.Item as={Link} to="/admin/createNews/" className= "text-warning" >
                 Create News Alert 
                </NavDropdown.Item>

                <NavDropdown.Item as={Link} to="/admin/upcomingAlerts/" className= "text-warning" >
                 Upcoming Alerts
                </NavDropdown.Item>

                <NavDropdown.Item as={Link} to="/admin/activeAlerts/" className= "text-warning" >
                 Active Alerts
                </NavDropdown.Item>

          </NavDropdown>  

          <NavDropdown title={
                    <span className="text-warning my-auto">
                        <AiOutlineUser size={25} style={{ fill: '#ffc107' }} />
                        Users</span>
                }
                id="collasible-nav-dropdown" menuVariant="dark" className= "fw-bold text-warning" >
                
                <NavDropdown.Item as={Link} to="/admin/createAdminUser/" className= "text-warning" >
                 Create Admin Account
                </NavDropdown.Item>

                <NavDropdown.Item as={Link} to="/admin/viewAdminUsers/" className= "text-warning" >
                 View All Admins
                </NavDropdown.Item>

                <NavDropdown.Item as={Link} to="/admin/viewAllPlayers/" className= "text-warning" >
                 View All Players
                </NavDropdown.Item>

                <NavDropdown.Item as={Link} to="/admin/playerStats/" className= "text-warning" >
                 Player Statistics
                </NavDropdown.Item>

          </NavDropdown> 
          
          <Nav.Link as={Link} to="/admin/stockExchange" className= "fw-bold text-warning" >
                <AiOutlineLineChart size={25} style={{ fill: '#ffc107' }} /> Market Prices
            </Nav.Link>         
          

          

        <Nav.Item className="ms-auto px-1">
          
          <span className="text-info mx-1"> Hello {firstName}</span>
          <span className=" mx-2">
          <Logout /> 
          </span>
          </Nav.Item>


        
        </Nav>
      </Navbar.Collapse>
    
  </Navbar>
        </>
    );
}

export default AdminNavBar;