import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import {Link} from "react-router-dom";
import { FaHome} from "react-icons/fa";

const StockMarketNavBar = ()=>{
    
   
    


    return(
        <>
    <Navbar   expand="lg" variant="dark" className="px-5 mb-2  home-header ">      
      
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="container-fluid ">
          <Nav.Link as={Link} to="./" className= "text-light fw-bold"> 
            <FaHome size={25} style={{ fill: 'white' }} /> Stock Exchange Home 
          </Nav.Link>
          <Nav.Link as={Link} to="./allStocks" className= "text-light fw-bold"> 
          <img src={require('../assets/images/stock_icon.png')} alt= "S" height="25px" width="auto" className="px-1" fluid/>
            Stocks 
          </Nav.Link>
          <Nav.Link as={Link} to="./allMututalFunds" className= "text-light fw-bold"> 
          <img src={require('../assets/images/mf_icon.png')} alt= "MF" height="25px" width="auto" className="px-1" fluid/>
             Mutual Funds 
          </Nav.Link>
          <Nav.Link as={Link} to="./allBonds" className= "text-light fw-bold" > 
            <img src={require('../assets/images/bond_icon.png')} alt= "B" height="25px" width="auto" className="px-1" fluid/>
             Bonds
          </Nav.Link>
         
        </Nav>
      </Navbar.Collapse>
    
  </Navbar>

  
        </>
    );
}

export default StockMarketNavBar;