import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { useNavigate,  useOutletContext} from 'react-router-dom';
import {  useState, useEffect } from 'react';
import LoadingSpinner from '../Components/LoadingSpinner';
import Image from 'react-bootstrap/Image';
import {  BsBell } from "react-icons/bs";
import { DateTime } from "luxon";
import Page from "../Components/Page";

const NewsAlertSummary = () =>{

    const navigate = useNavigate();
    const [gameData, setGameData] = useOutletContext(); 
    
    const[showPage, setShowPage] = useState(false);
    const [newsAlerts, setNewsAlerts] = useState([]);

    useEffect(() => { 
        someNewsAlerts();
      },[]);
    
      const someNewsAlerts = async ()=>{
        const response = await fetch('/gameapi/someAlerts/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            status: 'SHOW',
            number: '5'
           })
      });
      const data = await response.json();
      if(! ('message' in data)){   
        for(let i=0; i<data.length; i++)  {
          let im = 'Alert0.png';
          if (i != 0){
              if(i%4 == 0)
                im = 'Alert1.png';
              else if (i%4 == 1)
                im = 'Alert2.png';
              else if (i%4 == 2)
                im = 'Alert3.png';
              else
                im = 'Alert4.png';
          }
         let al = {
            alert_date: data[i].alert_date,
            heading: data[i].heading,
            details: data[i].details,
            image: im
          }
          setNewsAlerts(original => [...original, al])
        }
        setShowPage(true);
      }
        
    }



    return(<>
    <Page title='News Alert Summary'/>
    <Row className='border border-2 border-dark mb-3'>
    
        <Row className='my-3'> 
        <Col sm={1}></Col>

        <Col sm={10} > {/* start middle/main column */}
        <h1 className='my-3 roboto-font fs-2 text-success text-center '>News Alert Summary</h1>
        
        <Row className='my-4 border border-1 p-2'>
        <Col xs={9}>       
        <span className='fs-5 text-primary roboto-font'> 
        This page only shows the last five(5) alerts, for full details go to the News Alert Page: </span>  
        </Col>

        <Col xs={3} className='text-end'> 
        <Button variant="outline-dark"  onClick={() => navigate("/game/alerts")}>  
        <BsBell size={25} /> News Alerts
        </Button>
        </Col>

        </Row>

        { (showPage) ?
    <>
    
    <Row>
        
      
    { newsAlerts.map((a, index)=>(
        <Table key={index} bordered className='my-3' responsive='lg'>
          <thead bordered className='border border-secondary border-1 '>
            {(index==0)?
              <tr className='bg-alerts text-warning roboto-font fs-5 mt-3'>
                <th colSpan={2}>Latest News Alert - {DateTime.fromISO(a.alert_date).toLocaleString(DateTime.DATE_MED)}</th>
              </tr>
              :
              <tr className='ttsec-blue-bg text-light roboto-font fs-5 '>
                <th colSpan={2}>News Alert - {DateTime.fromISO(a.alert_date).toLocaleString(DateTime.DATE_MED)} </th>
              </tr>
          }
              
          </thead>
          <tbody>
            <tr className=' home-header'>
              <td width='30%' className='text-center border border-secondary border-1' >
                <Image  src={require(`../assets/images/${a.image}`)}  roundedCircle width="70%" height="auto" fluid/>
              </td>
              <td className='bg-light'>
                <p className='currency fs-4 fw-bold my-2'> {a.heading}</p>
                <p className='currency fs-6 justify-text'> {a.details}</p>
              </td>
            </tr>
          </tbody>

        </Table>
    ))}
    
    </Row>
    </>
    :
    <>
        <div className="p-3 mb-3 text-center">
            <LoadingSpinner/>
        </div>
    </>

    }


       
        


               

        </Col> {/* end middle column */}

        <Col sm={1}></Col>
        </Row>
    </Row>
    
    </>);
}

export default NewsAlertSummary;