import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { Link} from "react-router-dom";
import Logout from "./Logout";
import {  AiOutlineHome, AiOutlineLineChart, AiOutlineUser } from "react-icons/ai";
import {  BsCheckCircle, BsBell, BsBook } from "react-icons/bs";





const NavigationBar = ({firstName, playerName})=>{
    


    return(
        <>
    <Navbar bg="dark" variant="dark" expand="lg" className="px-5 mb-4">
      
      <Navbar.Brand as={Link} to="/"> 
       <span className="inv-color roboto-font">Inve$tor</span> <span className="quest-color roboto-font">Quest TT 2.0</span> 
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="container-fluid ">
          <Nav.Link as={Link} to="/game" className= "fw-bold" >
          <AiOutlineHome size={25} style={{ fill: 'grey' }} /> Home 
          </Nav.Link>
          <Nav.Link as={Link} to="/game/stockMarket" className= "fw-bold" >
            <AiOutlineLineChart size={25} style={{ fill: 'grey' }} /> Stock Exchange
          </Nav.Link>
          <Nav.Link as={Link} to="/game/alerts" className= "fw-bold" >
            <BsBell size={25} style={{ fill: 'grey' }} /> News Alerts
          </Nav.Link> 
          <Nav.Link as={Link} to="/game/completedQuests" className= "fw-bold" >
           <BsCheckCircle size={25} style={{ fill: 'grey' }} /> Completed Quests
            </Nav.Link>         
          <Nav.Link as={Link} to="/game/account" className= "fw-bold">
          <AiOutlineUser size={25} style={{ fill: 'grey' }} /> User Account
            </Nav.Link>
            <Nav.Link as={Link} to="/game/resources" className= "fw-bold">
          <BsBook size={25} style={{ fill: 'grey' }} /> Resources
            </Nav.Link>

          <Nav.Item className="ms-auto px-1">
          <span className="text-warning"> Hello {firstName},</span>
          <span className="text-info mx-1"> ({playerName})</span>
           <Logout />        
        </Nav.Item>

              
            

        
        </Nav>
      </Navbar.Collapse>
    
  </Navbar>
        </>
    );
}

export default NavigationBar;