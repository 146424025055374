import { useNavigate, useOutletContext, Link } from 'react-router-dom';
import {useState, useEffect} from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { DateTime } from "luxon";

const CreateNewAlert = ()=>{
    const navigate = useNavigate();

    const [sec, setSec] = useState([]);
    const [longDate, setLongDate] = useState('');
     
    const [showDate, setShowDate] = useState(false);
    const [today, setToday] = useState();
    const [alertID, setAlertID] = useState(0);
    
    const [show, setShow] = useState(false); 
    const handleClose = () => {
      setShow(false);      
    }
    const handleShow = () => setShow(true);

    

    const [details,setDetails]=useState({
      heading:'',
      alert_date:'',
      details:'',
      stockMF:'',
      effect:'INCREASE',
      percent:'1',
      days:'2'
    });

    useEffect(() => {
      getAllStocksMF();
    }, []);

    useEffect(() => {
      var t = new Date(); 
      var dd = t.getDate();
      var mm = t.getMonth() + 1;
      var yyyy = t.getFullYear();
      if (mm.toString().length == 1)
          mm = '0' + mm;
      var dateT = yyyy + "-" + mm + "-" + dd;
      setToday(dateT);
    }, []);

    useEffect(() => {
      if(showDate){
          setLongDate(DateTime.fromISO(details.alert_date).toLocaleString(DateTime.DATE_HUGE));
      }
    }, [details.alert_date]);

   

   


    function changeDetails(e){    
        setDetails({...details,[e.target.name]:e.target.value});  
        if(e.target.name === 'alert_date'){
          setShowDate(true);
      }
    }

    const getAllStocksMF = async()=>{   
      const response = await fetch('/gameapi/allStocksAndMF/', {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }        
    });
    const data = await response.json();
    if(! ('message' in data))  {    
      setSec(data);
      setDetails({...details,['stockMF']:data[0].id});
    }  
  }

   
    const addNewAlert =  async (e) =>{
      e.preventDefault();    
      const response = await fetch('/gameapi/addNewsAlert/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          heading: `${details.heading}`,
          alert_date: `${details.alert_date}`,
          details: `${details.details}`,
          stockMF: `${details.stockMF}`,
          effect: `${details.effect}`,
          percent: `${details.percent}`,
          days: `${details.days}`
         })
    });
    const data = await response.json();
    if(! ('message' in data)){      
      setAlertID(data.news_alert_id);
      handleShow();
    }
    else
      alert(data.message);

    
  }
 


    return (
        <>
           
        <Row >
        <Col md={12} lg={2}>
        </Col>
        <Col md={12} lg={8}>
        <Row className="p-1 border border-2 border-secondary rounded mb-5"> 
            <Row classname = "my-1">
            <h2 className="my-3 text-center form-heading"> Add News Alert  </h2>
            <p className="text-danger fs-6 "> * All Fields Required </p>

            <Form className=' mb-3' onSubmit={addNewAlert} > 

            <Form.Group className="mb-1" as={Row}>
              <Form.Label className="admin-form-labels fs-6" column sm={4}>
                Alert Heading 
              </Form.Label>
              <Col sm={8}>
              <Form.Control className="mb-3" type="text" maxlength= "200" name = 'heading' value={details.heading} onChange={changeDetails} required />
            </Col>
              
              </Form.Group>

              <Form.Group className="mb-3" as={Row}>
              <Form.Label className="admin-form-labels fs-6" column sm={4}>
                Alert Date
              </Form.Label>
              <Col sm={8}>
              <Form.Control type="Date"  value={details.alert_date} name = 'alert_date' onChange={changeDetails}  min={today} required />
              <Form.Text className="text-dark">
                  {longDate}
              </Form.Text> 
              </Col>
          </Form.Group>

          

          <Form.Group className="mb-3" as={Row}>
                <Form.Label className="admin-form-labels fs-6" column sm={4}>
                  Details 
                </Form.Label>
                <Col sm={8}>
                <Form.Control as="textarea" rows={7}  value={details.details}  name = 'details' onChange={changeDetails} required/>
                </Col>
          </Form.Group>

          <Form.Group className="mt-5 mb-3" as={Row}>
              <Form.Label className="admin-form-labels fs-5 roboto-font text-primary" column sm={12}>

                Stock or Mutual Fund Affected:
                <span className='text-secondary fs-6'>(additional values can be added later)</span>
              </Form.Label>              
          </Form.Group>

          <Form.Group className="mb-1"as={Row} >
            <Form.Label className="admin-form-labels fs-6" column sm={4}>
                Stock / Mutual Fund
            </Form.Label>
            <Col sm={8}>
            <Form.Select className="mb-3"  value={details.stockMF} name = 'stockMF'  onChange={changeDetails} required>
            { sec.map((s)=>(
              <option key={s.id} value={s.id}> 
                {s.registered_entity}
              </option>
            ))}
                                         
            </Form.Select>
            </Col>
        </Form.Group>

        
        <Form.Group className="mb-1"as={Row} >
            <Form.Label className="admin-form-labels fs-6" column sm={4}>
                Effect On Price
            </Form.Label>
            <Col sm={8}>
            <Form.Select className="mb-3"  value={details.effect} name = 'effect'  onChange={changeDetails} required>
            <option value="INCREASE"> Increase </option>              
            <option value="DECREASE"> Decrease </option>                             
            </Form.Select>
            </Col>
        </Form.Group>
          
          
          <Form.Group className="mb-1" as={Row}>
              <Form.Label className="admin-form-labels fs-6" column sm={4}>
                Percentage Change
              </Form.Label>
              <Col sm={8}>
              <Form.Control className="mb-3" type="number"  min='1' step='0.1'  name = 'percent' value={details.percent} onChange={changeDetails} required />
              </Col>
          </Form.Group>

          <Form.Group className="mb-1" as={Row}>
              <Form.Label className="admin-form-labels fs-6" column sm={4}>
                Number Days
              </Form.Label>
              <Col sm={8}>
              <Form.Control className="mb-3" type="number"  min='2' step='1' name = 'days' value={details.days} onChange={changeDetails} required />
              </Col>
          </Form.Group>

          
              <div className="text-center">
            
            <Button variant="primary" type="submit" >
              Add News Alert
            </Button>

            <Button variant="primary" className='mx-2'  onClick={() => navigate("/admin/")}>
              Cancel 
            </Button>

          </div> 
             </Form>

            </Row>
        </Row>
        </Col>

        <Col  md={12} lg={2}>
        </Col>
    
    </Row>

    
      <Modal show={show} onHide={handleClose} backdrop='static' keyboard={false} centered>  
        <Modal.Header closeButton className='bg-dark py-1'>
            <Modal.Title className='text-light fs-3 text-center  '> 
              Add News Alert
            </Modal.Title>
          </Modal.Header>      
        <Modal.Body className='bg-light'> 
            <p className='fs-4 '> Alert Successfully Added.</p> 
                    
        </Modal.Body>
        <Modal.Footer className='bg-dark py-1'>
            <Button variant="warning" onClick={() => navigate(`/admin/moreAlertDetails/${alertID}`)}> More Price Changes </Button> 
            <Button variant="warning" onClick={() => navigate("/admin/upcomingAlerts/")}> Done </Button> 
        </Modal.Footer>
    </Modal>
    </>
    );
}

export default CreateNewAlert;