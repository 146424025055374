import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useParams, Outlet } from 'react-router-dom';
import {  useState, useContext, useEffect } from 'react';
import AuthContext from '../Components/AuthContext';
import QuestNav from './QuestNav';
import LoadingSpinner from '../Components/LoadingSpinner';
import { DateTime } from "luxon";


const PlayerQuest = () => {

  const [gameData, setGameData] = useState({});
  const {authToken}= useContext(AuthContext);
  const {questId} = useParams();
  const [showDetails, setShowDetails] = useState(false);
  
  
  useEffect(() => { 
    getGameDetails();       
  },[]);

  const getGameDetails = async () =>{
    const response = await fetch('/gameapi/getQuestPlayerById/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + String(authToken.access)
        },
        body: JSON.stringify({
            qid: `${questId}`,
         })
    });
    const data = await response.json();    
    setGameData(data);
    
    setShowDetails(true);
   // getStocksOwned();
  }






  
  return (
    <>
     
      <Row className='trading-form-bg pt-3 '>         
          {(showDetails) ?
          <> 
            <Col sm={12} md={7}>
            <h1 className="mb-3 quest-play-heading text-center text-info"> {gameData.quest.name} </h1> 
            </Col>
            <Col sm={12} md={5}>
            <h5 className="mb-3 quest-play-startdate text-info"> 
            <span className='text-light'> Quest Start Date: </span>
                {DateTime.fromISO(gameData.quest.start_date).toLocaleString(DateTime.DATE_HUGE)}
            </h5>
            { (gameData.quest.type === 'ON_GOING') ?
              <>
                <h5 className="mb-3 quest-play-startdate text-info">  
                <span className='text-light'> Quest End Date: </span> (On going Quest)   </h5>
              </>
              :
              <> 
                <h5 className="mb-3 quest-play-startdate text-info"> 
                <span className='text-light'> Quest End Date: </span>
                {DateTime.fromISO(gameData.quest.end_date).toLocaleString(DateTime.DATE_HUGE)}
                 </h5> 
                
              </>
            }
            </Col> 
            <QuestNav/>
          </>
          : <>
            <div className="p-3 mb-3 text-center"> 
                <LoadingSpinner/>  
            </div>
          </>
          }             
        </Row>   

        { (showDetails) ?
        <>
        <Outlet context={[gameData, setGameData]}/>
        </>
        :
        <></>

        }

        
          
      
    </>
  );
};

export default PlayerQuest;
