import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { useState, useEffect } from "react";
import LoadingSpinner from '../Components/LoadingSpinner';
import AdminUserTable from './AdminUserTable';

const ViewAllAdminUsers = ()=>{
    
    const[loaded, setLoaded] = useState(true);
    const [userData, setUserData] = useState([]);
    const [query, setQuery] = useState("");

    useEffect(() => {
        getUserDetails();
       }, []);

    const getUserDetails = async ()=>{
        const response = await fetch('/api/allUsersByType/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(
                { type: 'admin' 
            })
        });
        const data = await response.json();
        if(!('message' in data)){
            setUserData(data);            
            setLoaded(true);
        }        
    }

    const search = (userData) =>{
        return userData.filter((u) =>
            u.user.first_name.toLowerCase().includes(query.toLowerCase()) ||
            u.user.last_name.toLowerCase().includes(query.toLowerCase()) ||
            u.user.email.toLowerCase().includes(query.toLowerCase()) 
        );
    }

    return (<>
    <Row>
    <h2 className="my-3 text-center form-heading"> TTSEC Admin Users </h2>
    {(loaded) ?
    <> 
        <Row>
          <Col xs={1}>
          </Col >  
            <Col xs={10}>
            <Form.Group className="my-3"> 
            <Form.Label className="admin-form-labels fs-5" >
                Find User: 
              </Form.Label>                 
                <Form.Control type="text"  onChange={e=> setQuery(e.target.value)}/>              
            </Form.Group>
            <AdminUserTable userData={search(userData)}/>
            </Col>

            
            <Col xs={1}>
          </Col>  
        </Row>
        
    </>
    :
    <> 
        <div className="p-3 mb-3 text-center">
                <LoadingSpinner/>
        </div>
    </>
    }
    
    </Row>

        
    </>);
}

export default ViewAllAdminUsers;