import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import {useNavigate, Link, useLocation} from "react-router-dom";


const RegNavBar = ()=>{
    const navigate = useNavigate();
    return(<>
    
    <Navbar bg="dark" variant="dark" expand="sm" className="px-5 mb-5">      
    <Navbar.Brand as={Link} to="/"> 
       <span className="inv-color roboto-font">Inve$tor</span> <span className="quest-color roboto-font">Quest TT 2.0</span> 
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="container-fluid ">       

          <Nav.Item className="ms-auto px-1">
          <span className="text-white"> Already have an account?  </span>
            <Button variant="warning" type="submit" size="sm" className="text-right mx-3" onClick={() => navigate("/login")}>
                Login
            </Button>
         
    </Nav.Item>            
            

        
        </Nav>
      </Navbar.Collapse>
    
  </Navbar>
    
    </>);
}

export default RegNavBar;