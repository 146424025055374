import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect, useContext } from "react";
import {  BsCheckCircle, BsMapFill } from "react-icons/bs";
import LoadingSpinner from '../Components/LoadingSpinner';
import AuthContext from '../Components/AuthContext';
import { DateTime } from "luxon";
import Page from "../Components/Page";



const AllCompletedQuests = ()=>{
  const navigate = useNavigate();
  const[showPage, setShowPage] = useState(false);
  const[gameData, setGameData] = useState([]);
  const { authToken }= useContext(AuthContext);  
  const [leadData, setLeadData] = useState([]);

  const [cash, setCash] = useState(0);
  const [s, setS] = useState(0);
  const [mf, setMf] = useState(0);
  const [b, setB] = useState(0);

  var CurrencyFormat = require('react-currency-format');
  var ordinal = require('ordinal')

  const [show, setShow] = useState(false); 
    const handleClose = () => {
      setShow(false);      
    }

  const handleShow = (c, st, m, bd) => {
    setCash(c);
    setS(st);
    setMf(m)
    setB(bd);
    setShow(true);
  }

  const [show1, setShow1] = useState(false); 
    const handleClose1 = () => {
      setShow1(false);      
    }

  const handleShow1 = (id) => {
    getLeaderboardData(id);
    setShow1(true);
  }

  const getLeaderboardData = async (id) =>{
    const response = await fetch('/gameapi/questLeaderboard/', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          qid: `${id}`
         })
    });
    const data = await response.json(); 
    if(! ('message' in data))  {    //24th may  update: all_pending_transactions   in views
        setLeadData(data);
    }  
  }

  useEffect(() => {
    getAllCompletedQuest();  
  }, []);

  const getAllCompletedQuest = async ()=>{
    const response = await fetch('/gameapi/allCompletedQuest/', {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + String(authToken.access)
        }
    })
    const data = await response.json();
    if(!('message' in data)){
      setGameData(data);      
    }
    setShowPage(true);
   
}


    return (<>
        <Page title='Completed Quests'/>
        <Row className='home-header'>  
        
        <div className="p-1 mb-2 text-center" >
        <span className="inv-color fs-1 roboto-font"> Inve$tor </span> 
        <span className="quest-color fs-1 roboto-font"> Quest TT 2.0 <BsCheckCircle size={30} style={{ fill: 'white' }} /> 
        </span>
        
        <p className="text-light fs-3 roboto-font"> 
        Unlock The Investor In You! </p>
            
        </div>   

    </Row>

    <Row className='border border-2 border-dark mb-5'>
    <Col xs={1}></Col>   

    <Col xs={10} >
    <Row > {/* header row*/}
    <h3 className='home-headings res-heading text-center mt-4 '>Completed Quests </h3>
    
    <div className='text-center my-1'>
      <Image  src={require('../assets/images/Ribbon_Blue.png')}  rounded width="45px" height="auto" />
      (1st-10th)
      <Image  src={require('../assets/images/Ribbon_Yellow.png')}  rounded width="45px" height="auto" />
      (11th & over)
      </div>

      </Row> {/* close header row*/}
    
    { (showPage) ?
    <>
    {(gameData.length == 0)?
    <p className='text-center text-primary my-3'>
      You haven't completed any mini-quests as yet. Look out for the next available one.

    </p>
    :
    <Row className='my-5'>
      
    <Table responsive bordered className='border border-1'>
      <thead className='fs-5 text-info roboto-font text-center bg-dark border border-secondary border-1'>
        <th></th>
        <th>Quest</th>
        <th>End Date</th>
        <th>Final Rank</th>
        <th  className='text-center'>Portfolio Value</th>
        <th></th>
      </thead>
      <tbody className='fs-5 roboto-font text-center'>
      {gameData.map((g, index)=>(
      <tr key={index} className='border border-1'>
        <td>
          { (g.current_position <= 10 && g.current_position >=1) ?
          <Image  src={require('../assets/images/Ribbon_Blue.png')}  rounded width="45px" height="auto" />
          :
          <>
            {(g.current_position > 10)?
            <Image  src={require('../assets/images/Ribbon_Yellow.png')}  rounded width="45px" height="auto" />
              :
              <></>
            }
          </>
          }
        </td>

        <td>{g.quest.name} </td>

        <td> 
        {DateTime.fromISO(g.quest.end_date).toLocaleString(DateTime.DATE_MED)}
        </td>
        {(g.current_position >= 1)?
        <td className='text-danger fw-bold'> {ordinal(g.current_position)}</td>
        :
        <td>*Not Ranked</td>
        }
        

        <td className='text-center currency fs-4 text-success'>  
        <CurrencyFormat value={parseFloat(g.current_value)}  
                            fixedDecimalScale={2} decimalScale={2} displayType={'text'} 
                            thousandSeparator={true} prefix={'$'} 
         />
        
        
        </td>

        <td>
        <div className="d-grid gap-2">
        <Button variant='primary' onClick={()=>handleShow(g.cash_value, g.stock_value, g.mf_value, g.bonds_value)} size='sm' >
                    Portfolio Details
                </Button>
        
        <Button variant='warning' onClick={()=>handleShow1(g.quest.id)} size='sm' >
                    Leaderboard
                </Button>
        </div>
        </td>

        
          
      </tr>
    ))}

      </tbody>

    </Table>
    
    <p className='text-danger my-2'>*If not ranked: You joined a quest but didn't make any trades.</p>
    </Row>
    }
    
    
    
    </>
    :
    <>
        <div className="p-3 mb-3 text-center">
            <LoadingSpinner/>
        </div>
    </>

    }


    </Col>    

    <Col xs={1}></Col>    
    
</Row>

<Modal show={show} onHide={handleClose}  >  
        <Modal.Header closeButton className='bg-primary py-1'>
            <Modal.Title className='text-light fs-3 '> 
              Portfolio Breakdown
            </Modal.Title>
          </Modal.Header>      
        <Modal.Body > 
        <Table bordered>
          <tbody className='fs-4 currency'>
            <tr className='graph-cash'>
              <td>Final Cash Value:</td>
              <td className='text-end'>
              <CurrencyFormat value={parseFloat(cash)}  
                            fixedDecimalScale={2} decimalScale={2} displayType={'text'} 
                            thousandSeparator={true} prefix={'$'} 
              />
              </td>
            </tr>

            <tr className='graph-stock'>
              <td>Final Stock Value:</td>
              <td className='text-end'>
              <CurrencyFormat value={parseFloat(s)}  
                            fixedDecimalScale={2} decimalScale={2} displayType={'text'} 
                            thousandSeparator={true} prefix={'$'} 
              />
              </td>
            </tr>

            <tr className='graph-mf'>
              <td>Final Mutual Funds Value:</td>
              <td className='text-end'>
              <CurrencyFormat value={parseFloat(mf)}  
                            fixedDecimalScale={2} decimalScale={2} displayType={'text'} 
                            thousandSeparator={true} prefix={'$'} 
              />
              </td>
            </tr>

            <tr className='graph-bonds'>
              <td>Final Bond Value:</td>
              <td className='text-end'>
              <CurrencyFormat value={parseFloat(b)}  
                            fixedDecimalScale={2} decimalScale={2} displayType={'text'} 
                            thousandSeparator={true} prefix={'$'} 
              />
              </td>
            </tr>

          </tbody>
        </Table>
                            
        </Modal.Body>
        <Modal.Footer className='bg-primary py-1'>
            
            <Button variant="warning" onClick={handleClose}> Close </Button> 
        </Modal.Footer>
    </Modal>

    <Modal show={show1} onHide={handleClose1} scrollable={true} size="lg" >  
        <Modal.Header closeButton className='bg-primary py-1'>
            <Modal.Title className='text-light fs-3 '> 
              Leaderboard
            </Modal.Title>
          </Modal.Header>      
        <Modal.Body > 
        <Table responsive='lg' className='px-5'>
            <thead>
                <tr className='bonds-inv-c2 fs-4 currency '>
                    <th width='15%'>  </th>                    
                    <th width='20%'> Player </th>
                    <th width='20%'> Rank </th>
                    <th width='25%' className='text-end'> Portfolio Value </th>
                    
                </tr>
            </thead>
            <tbody>
            { leadData.map((lb, index)=>(
                <tr key={index} className='fs-5 roboto-font'> 
                <td className='text-center'>                
                <Image  src={require(`../assets/images/${lb.player_image}`)}   width="45px" height="auto" />
                </td>
                <td >               
                    {lb.player_name}
                 </td>
                {(parseInt(lb.rank) <= 10)?
                    <td className='text-primary'> {ordinal(lb.rank)} </td>
                :
                    <td> {ordinal(lb.rank)} </td>
                }
                
                
                
                <td className='fs-4 text-end currency fw-bold'>
                <CurrencyFormat value={parseFloat(lb.portfolio_value)}  
                            fixedDecimalScale={2} decimalScale={2} displayType={'text'} 
                            thousandSeparator={true} prefix={'$'} 
                    />   
                </td>                
                
                
                
                
            </tr>
        ))}

            </tbody>
       
</Table>


                            
        </Modal.Body>
        <Modal.Footer className='bg-primary py-1'>
            
            <Button variant="warning" onClick={handleClose1}> Close </Button> 
        </Modal.Footer>
    </Modal>


   
    </>);
}

export default AllCompletedQuests;