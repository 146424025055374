import { useNavigate, useOutletContext, Link } from 'react-router-dom';
import {useState, useEffect} from 'react';
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FiInfo } from "react-icons/fi";
import Page from "./Page";


const Register1 = () =>{
  const navigate = useNavigate();
  const [details, setDetails] = useOutletContext();
  const [nameAvail, setNameAvail] = useState(true);
  const [maxDate, setMaxDate] = useState("");
  const [minDate, setMinDate] = useState("");
  const [acceptUsername, setAcceptUsername] = useState(false);
  const [usernameMessage, setUsernameMessage] = useState(""); //In-Game Name
  const [acceptFirstName, setAcceptFirstName] = useState(false);
  const [firstNameMessage, setFirstNameMessage] = useState("");
  const [acceptLastName, setAcceptLastName] = useState(false);
  const [lastNameMessage, setLastNameMessage] = useState("");
  const [showOtherLoc, setShowOtherLoc] = useState(false);
  const [otherLoc, setOtherLoc] = useState("");
   
  useEffect(() => {
      let max_year = new Date().getFullYear()-8; 
      const max_d = max_year+"-12-31";
      setMaxDate(max_d);

      let min_year = new Date().getFullYear()-100; 
      const min_d = min_year+"-12-31";
      setMinDate(min_d);
  }, []);

  useEffect(() => {
    if(nameAvail)
      setUsernameMessage("");
    else
      setUsernameMessage("*Username not available");  
  }, [nameAvail]);

  useEffect(() => {
    if(details.loc === "Other")
      setShowOtherLoc(true);
    else
      setShowOtherLoc(false);  
  }, [details.loc]);

  useEffect(() => { //message if first doesn't meet the requirements        
    if(details.fName.length === 0){
      setFirstNameMessage("");
      setAcceptFirstName(false);
    }
    else{
        //letters, -, spaces only:
        let expression = new RegExp ("^[A-Za-z-' ]*$");
        let accept = expression.test(details.fName);
        if(!accept ){
          setFirstNameMessage("*First name does not meet the requirements.");//""
          setAcceptFirstName(false);
        }          
        else{
          setFirstNameMessage("");
          setAcceptFirstName(true);
        }
    }
  },[details.fName]);

  useEffect(() => { //message if first doesn't meet the requirements        
    if(details.lName.length === 0){
      setLastNameMessage("");
      setAcceptLastName(false);
    }
    else{
        //letters, -, spaces only:
        let expression = new RegExp ("^[A-Za-z-' ]*$");
        let accept = expression.test(details.lName);
        if(!accept ){
          setLastNameMessage("*Last name does not meet the requirements.");//""
          setAcceptLastName(false);
        }          
        else{
          setLastNameMessage("");
          setAcceptLastName(true);
        }
    }
  },[details.lName]);



  useEffect(() => { //message if username doesn't meet the requirements        
    if(details.pName.length === 0){
      setUsernameMessage("");
      setAcceptUsername(false);
    }
    else{
        //letters, digits, _ only:
        let expression = new RegExp ("^[A-Za-z0-9_]*$");
        let accept = expression.test(details.pName);
        if(!accept || details.pName.length < 6 ){
          setUsernameMessage("*Username does not meet the requirements.");//""
          setAcceptUsername(false);
        }          
        else{
          setUsernameMessage("");
          setAcceptUsername(true);
        }
    }
  },[details.pName]);

  function changeDetails(e){    
      setDetails({...details,[e.target.name]:e.target.value});  
      if(e.target.name === "pName"){
          setNameAvail(true)
      }  
  }

  const continueRegistration = async (event)=>{
      event.preventDefault();
      if(acceptFirstName && acceptLastName && acceptUsername){ //if the names are valid 
        const available = await checkName(); //check if the user name is available
        if(available === "YES"){
            navigate("/register/continue/");
        }
        else{
            setNameAvail(false)
        }
      }        
  }

  const checkName = async () => {
      const response = await fetch('/api/playerNameAvailable/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ p_name: `${details.pName}` })
      });
      const data = await response.json();        
      return data.message;
  }

  

   const usernameTooltip = (props) => (
      <Tooltip id="link-tooltip"  {...props}>        
        minimum 6 characters, letters, digits & underscores only
      </Tooltip>
    );

    const nameTooltip = (props) => (
      <Tooltip id="link-tooltip"  {...props}>        
        letters, spaces, single quotes & dashes only
      </Tooltip>
    );

    const otherLocTooltip = (props) => (
      <Tooltip id="link-tooltip"  {...props}>        
        *if geographic location is other
      </Tooltip>
    );
  
    


  return (
  <>
      <Page title='Create An Account - Step 1'/>
            <Row className="p-1 border border-2 border-warning rounded">
        <Row classname = "my-1">
          <Col sm={12}>
            <div className='text-center my-3'>
              <Image src={require('../assets/logos/IQlogo.png')} width="20%" height="auto"/>
            </div>
          
          <h2 className="mb-3 text-center form-heading"> Create An Account (Step 1) </h2>
          <p className="form-extras "> *Required Field</p>
          </Col>
        </Row>

      
          
          <Form className=' mb-3' onSubmit={continueRegistration} >
            <Row className='px-1'>
              <Col md={12} lg={6}>
              <Form.Group className="mb-1" >
              <Form.Label className="form-labels fs-6">
                First Name* 
                <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={nameTooltip}
              >
              <Link to="" style={{ color: 'black' }}>{< FiInfo/>}</Link>  
              </OverlayTrigger>
              </Form.Label>
              <Form.Control className="mb-3" type="text" maxlength= "100" name = 'fName' value={details.fName} onChange={changeDetails} required />
              <Form.Text className="text-danger">
                  {firstNameMessage}
              </Form.Text> 
            </Form.Group>
              </Col>

              <Col md={12} lg={6}>
              <Form.Group className="mb-1" >
              <Form.Label className="form-labels fs-6">
                Last Name* 
                <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={nameTooltip}
              >
              <Link to="" style={{ color: 'black' }}>{< FiInfo/>}</Link>  
              </OverlayTrigger>
              </Form.Label>
              <Form.Control className="mb-3" type="text" maxlength= "100"  name = 'lName' value={details.lName} onChange={changeDetails} required />
              <Form.Text className="text-danger">
                  {lastNameMessage}
              </Form.Text> 
            </Form.Group>
              </Col>
            </Row>

            <Row className='px-1'>
              <Col md={12} lg={6}>
              <Form.Group className="mb-1" >
              <Form.Label className="form-labels fs-6">
                Username*  
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={usernameTooltip}
              >
              <Link to="" style={{ color: 'black' }}>{< FiInfo/>}</Link>  
              </OverlayTrigger>
              </Form.Label>
              <Form.Control className="mb-3" type="text" maxlength= "100" value={details.pName} name = 'pName' onChange={changeDetails} required />
              <Form.Text className="text-danger">
                  {usernameMessage}
              </Form.Text>  
              </Form.Group>
              </Col>

              <Col md={12} lg={6}>
              <Form.Group className="mb-1" >
                <Form.Label className="form-labels fs-6">
                  Gender
                </Form.Label>
                <Form.Select className="mb-3"  value={details.gender} name = 'gender'  onChange={changeDetails} required>
                <option value="NONE_ENTERED"> Prefer not to say </option>                
                <option value="Male"> Male </option>
                <option value="Female"> Female </option>                           
                </Form.Select>
              </Form.Group>
              </Col>
            </Row>
          
            <Row className='px-1'>
              <Col md={12} lg={6}>
              <Form.Group className="mb-1" >
                <Form.Label className="form-labels fs-6">
                  Student or Adult*
                </Form.Label>
                <Form.Select className="mb-3"  value={details.playerType} name = 'playerType'  onChange={changeDetails} required>
                <option value="Student"> Student </option>
                <option value="Adult"> Adult </option>            
                </Form.Select>
            </Form.Group>
              </Col>
              <Col md={12} lg={6}>
              <Form.Group className="mb-1" >
              <Form.Label className="form-labels fs-6">
                School or Organization  
              </Form.Label>
              <Form.Control type="text"  maxlength= "200" name = 'schOrg' value={details.schOrg} onChange={changeDetails}  />
            </Form.Group>
              </Col>
            </Row>

            <Row className='px-1'>
              <Col md={12} lg={6}>
              <Form.Group className="mb-1" >
                <Form.Label className="form-labels fs-6">
                  Geographic Location*
                </Form.Label>
                <Form.Select className="mb-3"  value={details.loc} name = 'loc'  onChange={changeDetails} required>
                <option value="North East, Trinidad"> North East, Trinidad </option>
                <option value="North West, Trinidad"> North West, Trinidad </option>
                <option value="East, Trinidad"> East, Trinidad </option>
                <option value="Central, Trinidad"> Central, Trinidad </option>
                <option value="South East, Trinidad"> South East, Trinidad </option>
                <option value="South West, Trinidad"> South West, Trinidad </option>
                <option value="Tobago"> Tobago </option>
                <option value="Other"> Other </option>               
                </Form.Select>
            </Form.Group>
              </Col>
              <Col md={12} lg={6}>
              <Form.Group className="mb-1" >
              <Form.Label className="form-labels fs-6">
                Enter Your Geographic Location 
                <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={otherLocTooltip}
              >
              <Link to="" style={{ color: 'black' }}>{< FiInfo/>}</Link>  
              </OverlayTrigger>
              </Form.Label>
                            
              { (showOtherLoc) ?
              <>
              <Form.Control type="text" maxlength= "200" name = 'otherLocation' value={details.otherLocation} onChange={changeDetails} required />
              </> 
              : 
              <>
              <Form.Control type="text"  disabled />
              </>
            }
            </Form.Group> 
              </Col>
            </Row>

            <Row className='px-1'>
              <Col md={12} lg={6}>
              <Form.Group className="mb-1" >
                <Form.Label className="form-labels fs-6">
                  Age Group*
                </Form.Label>
                <Form.Select className="mb-3"  value={details.ageGroup} name = 'ageGroup'  onChange={changeDetails} required>
                <option value="1"> 13 - 17 </option>
                <option value="2"> 18 - 35 </option>
                <option value="3"> 36 - 45 </option>     
                <option value="4"> 46 - 55 </option>     
                <option value="5"> 56+ </option>     
                </Form.Select>
            </Form.Group>
              </Col>
              <Col md={12} lg={6}>
                
              </Col>
            </Row>

            <Row className="my-2 px-1">
              <Col sm={12} >
              <div className="text-center">
            
            <Button variant="primary" type="submit" >
              Continue Registration
            </Button>
            <Button variant="primary" type="submit"  className='mx-2'  onClick={() => navigate("/begin/")}>
              Cancel 
            </Button>

          </div>  
              </Col>
              
            </Row>

            
        </Form>
        

      </Row>
      <Row className="m-2">

      </Row>
      
    
  </>
  );
}

export default Register1;