import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate, Outlet } from 'react-router-dom';
import AuthContext from '../Components/AuthContext';
import{useEffect, useContext, useState} from 'react';
import AdminNavBar from './AdminNavBar';
import {getAdminData} from '../Components/IQfunctions';
import Footer from '../Components/Footer';


const Admin = () =>{
    const navigate = useNavigate();
    const { authoriseAdmin, authToken  }= useContext(AuthContext);
    const [firstName, setFirstName] = useState();

    useEffect( () => {
        getDetails();              
      }, []);
  
      const getDetails = async()=>{        
        const data = await getAdminData(authToken); 
        setFirstName(data.first_name);       
      }

    useEffect(() => {
    document.title = "Inve$tor Quest: Admin"
    }, []);

    useEffect(() => {
        authoriseAdmin();
        if(localStorage.getItem('qtnxvf') === null){
            navigate("/");
        }
        if(authToken === null) {
            navigate("/");
        }
      }, []);

    return(
    <>
    

    <AdminNavBar firstName={firstName}/>
    <Container  fliud>
        <Row>
        <Col sm={12} md={1} className="d-none d-md-block">  </Col>

      <Col sm={12} md={10} >

        <Outlet  />

      </Col>

        <Col sm={12} md={1} className="d-none d-md-block"> </Col>
        </Row>
    </Container>
    <Footer/>
    </>
    );
}

export default Admin;
