import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate,  useOutletContext } from 'react-router-dom';
import { BsCoin } from "react-icons/bs";
import Page from "../Components/Page";

const QuestDetails = () =>{

    const [gameData, setGameData] = useOutletContext();
    var CurrencyFormat = require('react-currency-format');


    return(<>
        <Page title='Quest Details'/>
    <Row className='border border-2 border-dark mb-3'>
    
        <Row className='my-3'> 
        <Col sm={1}></Col>

        <Col sm={10} >
        <h1 className='my-3 roboto-font fs-2 inv-color text-center '>Quest Details </h1>

        <p className='my-4 fs-5 justify-text'> {gameData.quest.description}</p>

        <Row>
        <Col className='fs-5' >
        <p className='quest-join-label fs-3'> Starting Value: <span className='currency fs-3 text-success'> 
                <CurrencyFormat value={parseFloat(gameData.quest.start_value)}  
                            fixedDecimalScale={2} decimalScale={2} displayType={'text'} 
                            thousandSeparator={true} prefix={'$'} 
                />
                </span>
            </p>             
        </Col>        
        </Row> 

        <Row className='my-3'> 

        <Col md={12} lg={6}>
            <h3 className='quest-join-label fs-3'> Stocks to be Traded: </h3>
            <Row>
                <Col sm={0} md={1}></Col>
                <Col sm={12} md={11}>
            
                { gameData.quest.stocks_and_mf.map((s, index)=>(
                    <div key={index} className='mb-1'>
                        {(s.type === 'STOCK') ?
                        <>
                        <BsCoin size={25} style={{ fill: '#ffc107' }} /> 
                        <span className='fs-5'> {s.registered_entity} </span>                
                        </>
                        :<></>
                    }
                    </div>           
                    
                ))}
            
                </Col>
            </Row>
        </Col>

    <Col md={12} lg={6}>

    <h3 className='quest-join-label fs-3'> Mutual Funds to be Traded: </h3>
        <Row>
            <Col sm={0} md={1}></Col>
            <Col sm={12} md={11}>
        
            { gameData.quest.stocks_and_mf.map((m, index)=>(
                <div key={index} className='mb-1'>
                    {(m.type === 'MUTUAL_FUND') ?
                    <>
                    <BsCoin size={25} style={{ fill: '#ffc107' }} /> 
                    <span className='fs-5'> {m.registered_entity} </span>                
                    </>
                    :<></>
                }
                </div>           
                
            ))}            
            </Col>
            </Row>        
        </Col>                
        </Row>

        <Row className='my-5'> 
            <Col sm={12} md={6}>
                <h3 className='quest-join-label fs-3'> Bonds to be Traded: </h3>
                <Row>
                    <Col sm={0} md={1}></Col>
                    <Col sm={12} md={11}>
                
                    { gameData.quest.bonds.map((b, index)=>(
                        <div key={index} className='mb-1'>
                            <BsCoin size={25} style={{ fill: '#ffc107' }} /> 
                            <span className='fs-5'> {b.registered_entity} </span>                
                        </div>           
                        
                    ))}
                
                    </Col>
                </Row>
            </Col>
        </Row>

        </Col>

        <Col sm={1}></Col>
        </Row>
    </Row>
    
    </>);
}

export default QuestDetails;