import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import {useNavigate, Link} from "react-router-dom";
import {  BsInfoSquare,  BsQuestionDiamond, BsCoin, BsBell } from "react-icons/bs";
import { FaSearchDollar} from "react-icons/fa";
import {  AiOutlineLineChart } from "react-icons/ai";


const QuestNav = ()=>{
    
    return (
        <>

        <Navbar  expand="lg" variant="dark" className="px-2 questNav-color">
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="container-fluid ">
                <Nav.Link as={Link} to="./" className="text-warning fw-bold">
                <img src={require('../assets/images/portfolio_icon2.png')} alt= "*" height="25px" width="auto" className="px-1" fluid/>
                    Portfolio
                </Nav.Link>

                <NavDropdown title={
                    <span className="text-warning my-auto">
                        <FaSearchDollar size={25} style={{ fill: '#ffc107' }} />
                        Market Research</span>
                }
                id="collasible-nav-dropdown" menuVariant="dark" className= "fw-bold text-warning" >
                <NavDropdown.Item as={Link} to="./marketSummary/" className="text-warning" >
                <AiOutlineLineChart size={25} style={{ fill: '#ffc107' }} />
                    Stock Market Summary
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="./alertSummary/" className="text-warning" >
                <BsBell size={25} style={{ fill: '#ffc107' }} />
                    News Alerts
                </NavDropdown.Item>
                
                </NavDropdown>


                <NavDropdown title={
                    <span className="text-warning my-auto">
                        <BsCoin size={25} style={{ fill: '#ffc107' }} />
                        Trade</span>
                }
                id="collasible-nav-dropdown" menuVariant="dark" className= "fw-bold text-warning" >
                <NavDropdown.Item as={Link} to="./buyStocks/" className="text-warning" >
                <img src={require('../assets/images/stock_quest.png')} alt= "*" height="25px" width="auto" className="px-1 " fluid/>
                    BUY Stocks
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="./sellStocks/" className="text-warning" >
                <img src={require('../assets/images/stock_quest.png')} alt= "*" height="25px" width="auto" className="px-1 " fluid/>
                    SELL Stocks
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="./buyMutualFunds/" className="text-warning" >
                <img src={require('../assets/images/mf_quest.png')} alt= "*" height="25px" width="auto" className="px-1" fluid/>
                    BUY Mutual Funds
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="./sellMutualFunds/" className="text-warning" >
                <img src={require('../assets/images/mf_quest.png')} alt= "*" height="25px" width="auto" className="px-1" fluid/>
                    SELL Mutual Funds
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="./buyBonds/" className="text-warning" >
                <img src={require('../assets/images/bond_quest.png')} alt= "*" height="25px" width="auto" className="px-1" fluid/>
                    BUY Bonds
                </NavDropdown.Item>

                </NavDropdown>

                <Nav.Link as={Link} to="./leaderboard/" className="text-warning fw-bold">
                <img src={require('../assets/images/leaderboard_icon2.png')} alt= "*" height="25px" width="auto" className="px-1" fluid/>
                    Leaderboard
                </Nav.Link>
                <Nav.Link as={Link} to="./bonusQuestion/" className= "text-warning fw-bold">
                    <BsQuestionDiamond size={25} style={{ fill: '#ffc107' }} />
                    Bonus Question
                </Nav.Link>
                <Nav.Link as={Link} to="./questDetails/" className= "text-warning fw-bold">
                    <BsInfoSquare size={25} style={{ fill: '#ffc107' }} />
                    Quest Details
                </Nav.Link>
                </Nav>
            </Navbar.Collapse>

        </Navbar>


        </>
    );
}

export default QuestNav;
