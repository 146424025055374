import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect} from "react";
import LoadingSpinner from '../Components/LoadingSpinner';
import QuestTable from './QuestTable';

const ManageQuest = ()=>{
    
    const[loaded, setLoaded] = useState(false);
    const [questData, setQuestData] = useState([{}]);
    const [query, setQuery] = useState("");

    useEffect(() => {
        getQuestDetails();
       }, []);

    const getQuestDetails = async ()=>{
        const response = await fetch(`/gameapi/getAllQuestsAdmin/`, { 
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
    });
        const data = await response.json();
        if(data.length !== 0){
            setQuestData(data);            
            setLoaded(true);
        }        
    }

    const search = (questData) =>{
        return questData.filter((q) =>
            q.name.toLowerCase().includes(query.toLowerCase()) ||
            q.start_date.toLowerCase().includes(query.toLowerCase()) ||
            q.end_date.toLowerCase().includes(query.toLowerCase()) ||
            q.status.toLowerCase().includes(query.toLowerCase()) 
        );
    }

    return (<>
    <Row>
    <h2 className="my-3 text-center form-heading"> All Quests </h2>
    {(loaded) ?
    <> 
        <Row>
            <Col>
            <Form.Group className="my-3"> 
            <Form.Label className="admin-form-labels fs-5" >
                Search for Quest: <span className='fs-6'> (Enter 1-12 to filter on month)</span>
              </Form.Label>                 
                <Form.Control type="text"  onChange={e=> setQuery(e.target.value)}/>              
            </Form.Group>
            </Col>
            
        </Row>
        <QuestTable questData={search(questData)}/>
    </>
    :
    <> 
        <div className="p-3 mb-3 text-center">
                <LoadingSpinner/>
        </div>
    </>
    }
    
    </Row>

        
    </>);
}

export default ManageQuest;