import { useState, useContext, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { ColoredLine } from '../myStyles/MyStyles';
import { useOutletContext,useNavigate } from 'react-router-dom';
import coins from '../assets/audio/coins.mp3';
import Modal from 'react-bootstrap/Modal';
import AuthContext from '../Components/AuthContext';
import { BsChevronDoubleRight } from "react-icons/bs";
import Page from "../Components/Page";

const Step4 = ()=>{

    const navigate = useNavigate(); 
    const [data, setData] = useOutletContext();  
    const { authToken }= useContext(AuthContext); 
    const audio = new Audio(coins);
    const [broker, setBroker] = useState("");
    const [profileMsg, setProfileMsg] = useState("");

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
      setShow(true);
      audio.play();
    }

    useEffect(() => {
      if(data.job_profile === 'Random') {
        setProfileMsg("Randomly Assigned")
        assignProfile();
      }
          
  }, []);

    useEffect(() => {
      if(data.broker === 'Elite') 
          setBroker('Elite Securities Limited');
      else if(data.broker === 'Capital')
          setBroker('360 Capital Market Brokers Limited'); 
      else
          setBroker('Butterfly Interactive Brokers Limited'); 
  }, []);

  const assignProfile = ()=>{
    let p = Math.floor(Math.random() * 17) + 1;
    switch (p){
      case 1: setData(original => ({...original, job_profile: "Teacher"})); break;
      case 2: setData(original => ({...original, job_profile: "Lawyer"}));  break;
      case 3: setData(original => ({...original, job_profile: "Accountant"}));  break;
      case 4: setData(original => ({...original, job_profile: "Doctor"}));  break;
      case 5: setData(original => ({...original, job_profile: "Carnival Promoter"}));  break;
      case 6: setData(original => ({...original, job_profile: "Financial Planner"}));  break;
      case 7: setData(original => ({...original, job_profile: "Maxi Driver"}));  break;
      case 8: setData(original => ({...original, job_profile: "Soca Artist / DJ"})); break;
      case 9: setData(original => ({...original, job_profile: "Aesthetician"})); break;
      case 10: setData(original => ({...original, job_profile: "Influencer"}));  break;
      case 11: setData(original => ({...original, job_profile: "Doubles Vendor"}));  break;
      case 12: setData(original => ({...original, job_profile: "Athlete"}));  break;
      case 13: setData(original => ({...original, job_profile: "Web Developer"}));  break;
      case 14: setData(original => ({...original, job_profile: "Tour Guide"}));  break;
      case 15: setData(original => ({...original, job_profile: "Farmer"}));  break;
      case 16: setData(original => ({...original, job_profile: "Clerical Assistant"}));  break;
      case 17: setData(original => ({...original, job_profile: "Social Media Manager"}));  break;   
  }

  }

  const joinMainQuest = async () =>{

        const response = await fetch('/gameapi/joinMainQuest/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization' : 'Bearer ' + String(authToken.access)
            }
        })
        const d1 = await response.json();
        handleShow();
    }

    const completed = async () =>{
      const response = await fetch('/api/updateNewPlayer/', {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + String(authToken.access)
        },
        body: JSON.stringify({ 
          u_status: 'active',
          broker: `${data.broker}`,
          profile: `${data.job_profile}`,
        })
    })
    const d = await response.json();
    joinMainQuest();
    }

    return (<>
    <Page title='Time To Play - Get Started'/>
<Row className='starting-header'>  
        
        <div className="p-1 mb-2 text-center" >
        <span className="text-light fs-1 roboto-font"> Inve$tor Quest TT 2.0</span>  
        
        <p className="text-light fs-3 roboto-font"> 
        Unlock The Investor In You! </p>
            
        </div>   

       
        <div>
        <span className="text-warning fs-5 roboto-font">   Time To Play </span>
        </div>

    </Row>

    <Row className='border border-2 border-dark mb-5'>
    <Col xs={1}></Col> 

    <Col xs={10} > 

    <Row className='my-3 text-center'>
    <h2 className='text-center trade-heading1 mt-3 mb-3'> Game Details </h2>
    
    </Row>

    <p className='ttsec-blue fs-4 roboto-font '> Your Details: 
    <span className='getting-started-c1'> This is set for the entire game. </span></p> 

    <Row className='my-3 fs-4 roboto-font px-4'>
     <p> <span> Broker: </span> {broker} </p>
     <p> <span> Virtual Career: </span> {data.job_profile} <span className='fs-6 text-primary'> {profileMsg} </span></p>     
    </Row>

    <p className='ttsec-blue fs-4 roboto-font '> Investor Quest: 
    <span className='getting-started-c1'> Some details about the main on-going game. </span></p> 

    <Row className='my-3 fs-5 roboto-font'>
     <p> <BsChevronDoubleRight size={20} style={{ fill: '#ffc107' }} /> 
     You can automatically play the main Investor Quest game. </p>
     <p> <BsChevronDoubleRight size={20} style={{ fill: '#ffc107' }} /> 
     You have been given <span className='currency text-success fs-3'> $25,000.00 </span> to start investing. 
     (You can earn $2000 extra by correctly answering the bonus question.) </p>
     <p> <BsChevronDoubleRight size={20} style={{ fill: '#ffc107' }} /> Once on the Home screen click the 
      <i> <span className='text-primary'>View Quest </span> </i> button to start playing. </p>
    </Row>

    <Row className='my-3 fs-3 text-center'>
     
     <p>
        <span className="inv-color fs-1 roboto-font"> Happy </span> 
        <span className="quest-color fs-1 roboto-font"> Trading! </span>
    </p>
     
    </Row>


    

    <Row className='my-3'>
      <Col className='text-end'>
        <Button variant="warning"  onClick={completed}>  Complete Getting Started   </Button>
      </Col>
    
    </Row>
    
    </Col>
    


    <Col xs={1}></Col>  
    </Row>
    
    

    <Modal show={show} onHide={handleClose} backdrop='static'  keyboard={false}>
          
          <Modal.Header closeButton className='bg-primary'>
            <Modal.Title className='text-light roboto-font fs-3 text-center  '> 
              Getting Started: Completed
            </Modal.Title>
          </Modal.Header>
          

          <Modal.Body> 
          <div className='text-center my-3'>
            <p className='roboto-font fs-3  '> 
            Congratulations on completing these important steps. </p>
            <p className='roboto-font fs-3  mt-2 text-primary'> 
            It's now time to play. </p>
            
          </div>
          </Modal.Body>

          <Modal.Footer className='bg-primary'>
          <Button variant="warning" onClick={() => navigate("/game/")}> Done </Button>
          </Modal.Footer>
      </Modal>

    
    </>);
}

export default Step4;