import Row from 'react-bootstrap/Row';
import SecurityTable from './SecurityTable';
import Page from "../Components/Page";


const Stocks= ()=>{ 

    return (<>
    <Page title='Stocks'/>
    <Row className='border border-2 border-dark mb-3'>
    <h2 className='roboto-font ttsec-blue text-center mt-4'>
            Stock Data
        </h2>
        <p className='fs-6 text-center text-danger mb-4'>
            Click on the ticker for each company to get more details.
        </p>
        <SecurityTable sec_type={"STOCK"} />
    </Row>
    </>);
}

export default Stocks;