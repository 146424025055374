import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from "react-ga4";

const Page = ({ title, ga = true }) => {
    const appName = 'Inve$tor Quest';
    let pageTitle = appName;
    console.log('Setting the page title...', title);
    const location = useLocation();
    useEffect(() => {
        if(title) {
            pageTitle = `${title} | ${pageTitle}`;
        }
        document.title = pageTitle;
        if(ga){
            ReactGA.send({ hitType: "pageview", page: location.pathname, title: title ?? appName });
        }
    }, [location, pageTitle]);
    return null;
};

export default Page;