import ReactPlayer from 'react-player/youtube';
const YouTubeVideo = ({videoURL})=>{
    return (<>
    <div className='scale-react-player '>
        <ReactPlayer url={videoURL} width="100%" height='100%'/>
    </div>  

    </>);
}

export default YouTubeVideo;