import { useState, useEffect } from 'react';
import { useParams, useNavigate} from 'react-router-dom';
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import Button from 'react-bootstrap/Button';
import { DateTime } from "luxon";
import LoadingSpinner from '../Components/LoadingSpinner';
import {getApplicableAgeGroupsDescriptionForAdmin} from '../utils/quest';

const PublishedQuest = ()=>{
    const navigate = useNavigate();
    const{questId} = useParams();
    const [loadPage, setLoadPage] = useState(false); 
    const [quest, setQuest] = useState({});

    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    var CurrencyFormat = require('react-currency-format');

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);

    useEffect(() => { 
        getQuestDetails();       
    },[]);

    const getQuestDetails = async () =>{
        const response = await fetch('/gameapi/getQuestById/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                questId: `${questId}`,
             })
        });
        const data = await response.json();
        data.applicableAgeGroupsDescription = getApplicableAgeGroupsDescriptionForAdmin(data.applicable_age_groups);
        setQuest(data);
        setLoadPage(true);
    }

    const activate = async () =>{
        handleClose();
        const response = await fetch('/gameapi/activateQuest/', {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                qid: `${questId}`,
             })
        });
        const data = await response.json();
        if(data.message === "ERROR_ACTIVATING_QUEST"){
            alert('Something went wrong! Try again in a while.');
        }
        else{
            handleShow1();
        }
    }

    
    return(<>
    <Row >
        <Col md={12} lg={1}>
        </Col>
        <Col md={12} lg={10}>
        <Row className="p-1  my-1">             
            <h2 className="mb-3 text-center form-heading"> Published Quest Details </h2>
            <h5 className="mb-3 text-center text-secondary"> (This would appear under the Coming Soon section)</h5>            
            </Row> 

            <Row className='my-2'>
            {(loadPage) ?
            <>
                <Table bordered>
                    <tbody>
                        <tr>
                            <td>
                            
                            <Button variant="primary"   className='mx-2'  
                            onClick={handleShow}>                
                                Activate Quest
                            </Button>
                            <span className='text-danger f2-6'>
                                (Only use this button on or after the start date)</span>
                            </td>
                        </tr>
                    </tbody>

                </Table>
                <Table bordered border-1>
                    <thead className='bg-dark'>
                        <tr>
                            <th  className='fs-5 text-white p-2' colSpan={2}>Quest Details:</th>
                            
                        </tr>
                    </thead>
                    <tbody className='px-1'>
                        <tr>
                            <td width="20%" className='fs-5 text-primary'> Quest Name</td>
                            <td className='fs-5'> {quest.name} </td>
                        </tr>
                        <tr>
                            <td className='fs-5 text-primary'> Start Date</td>
                            <td className='fs-5'> 
                            {
                            DateTime.fromISO(quest.start_date).toLocaleString(DateTime. DATE_HUGE)
                            }
                            </td>
                        </tr>
                        <tr>
                            <td className='fs-5 text-primary'> End Date</td>
                            <td className='fs-5'> 
                            {
                            DateTime.fromISO(quest.end_date).toLocaleString(DateTime. DATE_HUGE)
                            }
                            </td>
                        </tr>
                        <tr>
                            <td className='fs-5 text-primary'> Registration End Date</td>
                            <td className='fs-5'>
                                { !quest.registration_end_date
                                    ? 'None Specified'
                                    : DateTime.fromISO(quest.registration_end_date).toLocaleString(DateTime. DATE_HUGE)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td className='fs-5 text-primary'> Description</td>
                            <td className='fs-5'> {quest.description} </td>
                        </tr>
                        <tr>
                            <td className='fs-5 text-primary'> Start Value </td>
                            <td className='fs-5'> 
                            <CurrencyFormat value={parseFloat(quest.start_value)}  
                            fixedDecimalScale={2} decimalScale={2} displayType={'text'} 
                            thousandSeparator={true} prefix={'$'} 
                            />
                            </td>
                        </tr>
                        <tr>
                            <td className='fs-5 text-primary'> Age Restriction </td>
                            <td className='fs-5'>{quest.applicableAgeGroupsDescription}</td>
                        </tr>
                        <tr>
                            <td className='fs-5 text-primary'> Bonus Question</td>
                            <td className='fs-5'> {quest.bonus_question} </td>
                        </tr>
                        <tr>
                            <td className='fs-5 text-primary'> Answer A</td>
                            <td className='fs-5'> {quest.choice_A} </td>
                        </tr>
                        <tr>
                            <td className='fs-5 text-primary'> Answer B</td>
                            <td className='fs-5'> {quest.choice_B} </td>
                        </tr>
                        <tr>
                            <td className='fs-5 text-primary'> Answer c</td>
                            <td className='fs-5'> {quest.choice_C} </td>
                        </tr>
                        <tr>
                            <td className='fs-5 text-primary'> Answer D</td>
                            <td className='fs-5'> {quest.choice_D} </td>
                        </tr>
                        <tr>
                            <td className='fs-5 text-primary'> Answer </td>
                            <td className='fs-5'> {quest.answer} </td>
                        </tr>
                        <tr>
                            <td className='fs-5 text-primary'> Bonus Amount</td>
                            <td className='fs-5'> 
                            <CurrencyFormat value={parseFloat(quest.bonus_amount)}  
                            fixedDecimalScale={2} decimalScale={2} displayType={'text'} 
                            thousandSeparator={true} prefix={'$'} 
                            />
                            </td>
                        </tr>
                    </tbody>
                </Table>

                <Table className='my-4'>
                    <thead className='bg-dark'>
                        <tr>
                            <th className='fs-5 text-white p-2' > Stocks Added to Quest:</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(quest.stocks_and_mf.length == 0)?
                        <>
                        <tr> <td className='text-center fs-4 text-danger'> 
                            No STOCKS have been added to this quest. 
                        </td>
                        
                        </tr>
                        </>
                        :
                        <>
                        {quest.stocks_and_mf.map((s)=>( 
                            <tr key={s.id} className='mx-5'> 
                            {
                                (s.type === "STOCK") ?
                                <>
                                <td> 
                                <Image  src={require(`../assets/logos/${s.image}`)}  rounded width="50px" height="auto" />
                                <span className='fs-5  mx-4'>{s.registered_entity} </span>
                             </td>
                                </>
                                :
                                <></>
                            }                              
                             
                             
                            </tr>
                        ))}
                        </>
                        }
                        
                    </tbody>
                </Table>

                <Table className='my-4'>
                    <thead className='bg-dark'>
                        <tr>
                            <th className='fs-5 text-white p-2' > Mutual Funds Added to Quest:</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(quest.stocks_and_mf.length == 0)?
                        <>
                        <tr> <td className='text-center fs-4 text-danger'> 
                            No MUTUAL FUNDS have been added to this quest. 
                        </td>
                        
                        </tr>
                        </>
                        :
                        <>
                        {quest.stocks_and_mf.map((s)=>( 
                            <tr key={s.id} className='mx-5'> 
                            {
                                (s.type === "MUTUAL_FUND") ?
                                <>
                                <td> 
                                <Image  src={require(`../assets/logos/${s.image}`)}  rounded width="50px" height="auto" />
                                <span className='fs-5  mx-4'>{s.registered_entity} </span>
                             </td>
                                </>
                                :
                                <></>
                            }                              
                             
                             
                            </tr>
                        ))}
                        </>
                        }
                        
                    </tbody>
                </Table>

                <Table className='my-4'>
                    <thead className='bg-dark'>
                        <tr>
                            <th className='fs-5 text-white p-2' > Bonds Added to Quest:</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(quest.bonds == 0)?
                        <>
                        <tr> <td className='text-center fs-4 text-danger'> 
                            No BONDS have been added to this quest. 
                        </td>
                        
                        </tr>
                        </>
                        :
                        <>
                        {quest.bonds.map((b)=>( 
                            <tr key={b.id} className='mx-5'> 
                            <td> 
                                <Image  src={require(`../assets/logos/${b.image}`)}  rounded width="50px" height="auto" />
                                <span className='fs-5  mx-4'>{b.registered_entity} </span>
                             </td>
                            </tr>
                        ))}
                        </>
                        }
                        
                    </tbody>
                </Table>

            
            </>
            :
            <>
            <div className="p-3 mb-3 text-center">
                <LoadingSpinner/>
            </div>
            </>
            }
           
            
        </Row>
        </Col>
        

    <Col  md={12} lg={1}>
    </Col>

    </Row>

    <Modal show={show} onHide={handleClose} backdrop='static' centered>  
        <Modal.Header closeButton className='bg-dark py-1'>
            <Modal.Title className='text-light fs-3 text-center  '> 
              Activate Published Quest
            </Modal.Title>
          </Modal.Header>      
        <Modal.Body className='bg-light'> 
            <p className='fs-4 '> Are you sure it's time to make this quest ACTIVE? </p> 
                    
        </Modal.Body>
        <Modal.Footer className='bg-dark py-1'>
            <Button variant="warning" onClick={activate}> Confirm </Button> 
            <Button variant="warning" onClick={handleClose}> Cancel </Button> 
        </Modal.Footer>
    </Modal> 

    <Modal show={show1} onHide={handleClose1} backdrop='static' centered>  
        <Modal.Header closeButton className='bg-dark py-1'>
            <Modal.Title className='text-light fs-3 text-center  '> 
              Activate Published Quest
            </Modal.Title>
          </Modal.Header>      
        <Modal.Body className='bg-light'> 
            <p className='fs-4 '> Quest Activated. </p> 
                    
        </Modal.Body>
        <Modal.Footer className='bg-dark py-1'>
            <Button variant="warning" onClick={() => navigate('/admin/manageQuest/')}> Continue </Button>             
        </Modal.Footer>
    </Modal>

   

    </>);
}

export default PublishedQuest;