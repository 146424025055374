import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { useParams, useNavigate, Link } from 'react-router-dom';
import Footer from '../Components/Footer';
import {useState, useEffect } from 'react';
import LoadingSpinner from '../Components/LoadingSpinner';
import ResetNavBar from './ResetNavBar';
import { FiEyeOff, FiEye } from "react-icons/fi";
import Form from 'react-bootstrap/Form';
import icon from '../assets/images/favicon.ico';
import Page from "../Components/Page";


const UpdatePassword = () =>{

    const navigate = useNavigate();
    const [loadPage, setLoadPage] = useState(false);
    const [result, setResult] = useState("");
    const [p1, setP1] = useState("");
    const [p2, setP2] = useState("");
    const [showPassword, setShowPassword] = useState(false);//user can toggle seeing password
    const [acceptPassword, setAcceptPassword] = useState(false); //true: password is valid
    const [passwordMessage, setPasswordMessage] = useState("");//if passwords don't match
    const [validPasswordMessage, setValidPasswordMessage] = useState(""); //if password is invalid

    const{resetId} = useParams(); //primary key
    const{userId} = useParams(); //identifier

    useEffect(() => {
        const favicon = document.getElementById('favicon');
        favicon.setAttribute('href', icon);
      }, []);

      useEffect(() => {
        document.body.style.backgroundColor = "white";
      }, []);

    useEffect(() => { //Accessing page 2 before page 1
        isLinkValid();
    },[]);

    useEffect(() => { //message if passwords don't match
        if(p1 === "" || p2 === ""){
            setPasswordMessage("");
        }
        else{
            if(p1 === p2){
                setPasswordMessage("");
            }
            if(p1 !== p2){
                setPasswordMessage("*Passwords don't match");
            }
        }
      },[p1, p2]);

      useEffect(() => { //message if password doesn't meet the requirements

        if(p1.length === 0){
            setValidPasswordMessage("");
            setAcceptPassword(false);
        }
        else{
            //1 lowercase, 1 uppercase, 1 digit:
            let expression = new RegExp ("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)");
            let accept = expression.test(p1);
            let space = new RegExp ("^(?=.*\\s)"); //if the password has spaces
            let hasSpace = space.test(p1);
            if(p1.length < 6 || !accept){
                setValidPasswordMessage("*Password does not meet the requirements.");
                setAcceptPassword(false);
            }
            else if(hasSpace){
                setValidPasswordMessage("*Spaces not allowed.");
                setAcceptPassword(false);
            }
            else{
                setValidPasswordMessage("");
                setAcceptPassword(true);
            }
        }

      },[p1]);

    const togglePassword = ()=>{
        setShowPassword(!showPassword);
    }

    const changePassword1= (e)=> {
        setP1(e.target.value);
    }

    const changePassword2= (e)=> {
        setP2(e.target.value);
    }

    const isLinkValid= async () =>{
        const response = await fetch('/api/checkResetData/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                resetId: `${resetId}`,
                identifier: `${userId}`,
             })
        });
        const data = await response.json();
        if(data.message === "LINK_AVAILABLE"){
            setResult("success");
        }
        else{
            setResult("fail");
        }
        setLoadPage(true);
    }

    const updatePass = async(event)=>{
        event.preventDefault();
        if(p1 === p2 && acceptPassword){
            updateUserPassword();
        }
        
    }

    const updateUserPassword = async() =>{
        const response = await fetch('/api/changePassword/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                resetId: `${resetId}`,
                identifier: `${userId}`,
                password: `${p1}`,
             })
        });
        const data = await response.json();
        if(data.message === "PASSWORD_UPDATED"){
            navigate("/comfirmPasswordUpdate/complete/");
        }
        else{
            navigate("/comfirmPasswordUpdate/err/");
        }        
    }

    return (<>
        <Page title='New Password - Password Reset'/>
        <ResetNavBar />
        { (!loadPage) ?
            <> 
              <div className="p-3 mb-3 text-center"> 
                <LoadingSpinner/>  
              </div>
            </>
            :
            <>
             <Container fluid>

             <Row className='mt-4'>
            <Col sm={12} md={2} className="d-none d-md-block"> </Col>
            <Col sm={12} md={8} >  {/* Main section */}

            <Row className="align-items-center homepage-header-bg border border-1 border-secondary "> {/* header row*/}
                <Col xs={12} sm={4} className = " text-center  my-3">
                    
                <Image  src={require('../assets/logos/IQlogo.png')}   width="70%" height="auto" />
                </Col>
                <Col xs={12} sm={8}>                
                <p className='fs-3 roboto-font text-light text-center my-2'> Resetting Your Password</p>
                </Col>
            </Row> {/* close header row*/}
            

            <Row className='border border-2 border-dark mb-5'> {/* page body row*/}
            <Col sm={12} md={1} className="d-none d-md-block"> </Col>
            <Col sm={12} md={10} className="d-none d-md-block"> 

            { (result === "success") ?
                <> 
                    <h5 className='my-4 fs-4 currency'> Enter your new password below:</h5>

                    <Form className='p-1 mb-5' onSubmit={updatePass} >


                    <Form.Group className="mb-3" >
                <Form.Label className="form-labels fs-5">
                    Password:
                    <span className="extra-info fs-6"> (Minimum 6 characters, at least 1 lowercase, 1 uppercase & 1 digit required) </span>
                    <Link className="px-1"to=""  style={{ color: 'black' }} onClick={() => togglePassword()}>
                            {showPassword ?  ( <FiEyeOff /> )  : ( <FiEye/> )}
                    </Link>
                </Form.Label>
                <Form.Control type={showPassword ? "text" : "password"}  value={p1}  name = 'p1' onChange={changePassword1} required>
                </Form.Control>

                <Form.Text className="text-danger">
                    {validPasswordMessage}
                </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" >
                <Form.Label className="form-labels fs-5"> Confirm Password:</Form.Label>
                <Form.Control type={showPassword ? "text" : "password"}  value={p2}  name = 'p2' onChange={changePassword2} required/>
                <Form.Text className="text-danger">
                    {passwordMessage}
                </Form.Text>
            </Form.Group>            

            

             <Button variant="primary"  type='submit'>
                Update Password
              </Button>            
            <Button variant="primary"  className='mx-2' onClick={() => navigate("/")}>
                Cancel
            </Button>

                </Form>
                
            </>
            :
            <> 
                <h5 className='my-4  fs-5 currency text-primary'>
                    This reset link has already been used or the link has expired.</h5>
                <h6 className='my-4 text-dark'> Click the Reset Password button to get a new reset link. </h6>

                <div className="text-end mb-3 ">
                <Button variant="warning" className='mx-2' onClick={() => navigate("/")}>
                    Home
                </Button>
                <Button variant="warning"  onClick={() => navigate("/reset/")}>
                    Reset Password
                </Button>
                </div>
            </>

        }
            
            </Col>
            <Col sm={12} md={1} className="d-none d-md-block"> </Col>
            </Row>
            </Col>
            <Col sm={12} md={2} className="d-none d-md-block"> </Col>
        </Row>

            
    </Container>
            </>
        }
        <Footer />
    </>);
}

export default UpdatePassword;