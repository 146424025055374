import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { useNavigate, Link } from 'react-router-dom';
import Footer from '../Components/Footer';
import { useEffect } from 'react';
import Page from "./Page";


const PageNotFound = () =>{
    const navigate = useNavigate();

    useEffect(() => {
        document.body.style.backgroundColor = "white";
      }, []);

    return(
    <>

<Page title='PAge Not Found'/>
<Container fluid>

<Row className=''>
        <Col className='bg-dark'>
        <Link to={'/'} style={{ textDecoration: 'none' }}> 
        <h4 className='m-2 text-light'>Inve$tor Quest TT 2.0</h4>
        </Link>
        </Col>
    </Row>       
    
           
</Container>

    
    <Container>

    <Row className = "my-3 text-center " >
        <Col >
            <Image src={require('../assets/logos/IQlogo.png')} alt="test"  width="25%" height="auto" />
        </Col>
        
      </Row>
        <Row  className='my-1 text-center'>
            <Col>
                <h3 className = "my-5  text-danger"> The requested page does not exist. </h3>
                <Button variant="outline-dark"  onClick={() => navigate(-1)}>
                       Go Back
                </Button>
            
            </Col>
        </Row>

    </Container>
    
    

    <Footer />
    </>
    );
}

export default PageNotFound;