import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useState, useEffect} from "react";
import {   BsBell } from "react-icons/bs";
import LoadingSpinner from '../Components/LoadingSpinner';
import Table from 'react-bootstrap/Table';
import Image from 'react-bootstrap/Image';
import { DateTime } from "luxon";
import Page from "../Components/Page";

const NewsAlerts = ()=>{

  const[showPage, setShowPage] = useState(false);
  const [newsAlerts, setNewsAlerts] = useState([]);

  useEffect(() => { 
    someNewsAlerts();
  },[]);

  const someNewsAlerts = async ()=>{
    const response = await fetch('/gameapi/someAlerts/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        status: 'SHOW',
        number: '25'
      })
  });
  const data = await response.json();
  if(! ('message' in data)){   
    for(let i=0; i<data.length; i++)  {
      let im = 'Alert0.png';
      if (i != 0){
          if(i%4 == 0)
            im = 'Alert4.png';
          else if (i%4 == 1)
            im = 'Alert1.png';
          else if (i%4 == 2)
            im = 'Alert2.png';
          else
            im = 'Alert3.png';
      }
    let al = {
        alert_date: data[i].alert_date,
        heading: data[i].heading,
        details: data[i].details,
        image: im
      }
      setNewsAlerts(original => [...original, al])
    }
    setShowPage(true);
  }
    
  }

    return (<>
        <Page title='News Alerts'/>
        <Row className='home-header'>  
        
        <div className="p-1 mb-2 text-center" >
        <span className="inv-color fs-1 roboto-font"> Inve$tor </span> 
        <span className="quest-color fs-1 roboto-font"> Quest TT 2.0 <BsBell size={30} style={{ fill: 'white' }} /> 
        </span>
        
        <p className="text-light fs-3 roboto-font"> 
        Unlock The Investor In You! </p>
            
        </div>   

    </Row>

    <Row className='border border-2 border-dark mb-5'>
    <Col xs={1}></Col>   

    <Col xs={10} >
    <Row className='my-4 border border-1 p-2 mx-1 border-danger'>
        <Col xs={12}>       
        <div className='fs-5  roboto-font justify-text'> 
        As important events happen in the Investor Quest world, News Alerts are posted. Since these 
        events can have a impact on the performance of stocks and mutual funds you'll need to use 
        the information to decide if to buy or sell the available securities in order to  
        increase the overall value of your portfolio and earn a higher raking on the leaderboard.
        </div>  
        </Col>
        </Row>


    { (showPage) ?
    <>
    
    <Row>

     {(newsAlerts.length == 0)?
     <p className='text-center text-primary fs-5 currency my-4'> 
     There are no alerts to display at this time.
     </p>
    :
    <>
    { newsAlerts.map((a, index)=>(
        <Table key={index} bordered className='my-3' responsive='lg'>
          <thead className='border  border-secondary border-1'>
            {(index==0)?
              <tr className='bg-alerts text-warning roboto-font fs-5 mt-3'>
                <th colSpan={2}>Latest News Alert - {DateTime.fromISO(a.alert_date).toLocaleString(DateTime.DATE_MED)}</th>
              </tr>
              :
              <tr className='ttsec-blue-bg text-light roboto-font fs-5 '>
                <th colSpan={2}>News Alert - {DateTime.fromISO(a.alert_date).toLocaleString(DateTime.DATE_MED)} </th>
              </tr>
          }
              
          </thead>
          <tbody>
            <tr className=' home-header'>
              <td width='30%' className='text-center border  border-secondary border-1' >
                <Image  src={require(`../assets/images/${a.image}`)}  roundedCircle width="70%" height="auto" fluid/>
              </td>
              <td className='bg-light'>
                <p className='currency fs-4 fw-bold my-2'> {a.heading}</p>
                <p className='currency fs-6 justify-text'> {a.details}</p>
              </td>
            </tr>
          </tbody>

        </Table>
    ))}
    </>
    }   
      
    
    
    </Row>
    </>
    :
    <>
        <div className="p-3 mb-3 text-center">
            <LoadingSpinner/>
        </div>
    </>

    }


    </Col>    

    <Col xs={1}></Col>    
    
</Row>
      
   
    </>);
}

export default NewsAlerts;