import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import LoadingSpinner from '../Components/LoadingSpinner';
import { useState, useEffect } from "react";
import Page from "../Components/Page";




const AllBonds = () =>{

    const [bondData, setBondData] = useState([{}]);
    const [loaded, setLoaded] = useState(false);
    const [showMore, setShowMore] = useState(false);
    const [buttonLabel, setButtonLabel] = useState("Show More");

    useEffect(() => {
        getAllBonds();
      }, []);

      useEffect(() => {
        if(showMore)
            setButtonLabel("Show Less");
        else
            setButtonLabel("Show More");
      }, [showMore]);

      const showDetails = ()=>{
        setShowMore(!showMore);        
      }

      
     
      const getAllBonds = async ()=>{
        const response = await fetch('/gameapi/allBonds/', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        });
        const data = await response.json();
        if(data.length !== 0){
            setBondData(data);
            setLoaded(true);
        }        
    }    

    return(<>
    <Page title='Bonds'/>
<Row className='border border-2 border-dark mb-3'>
<h1 className='roboto-font ttsec-blue text-center my-3'>
            Bond Data
        </h1>
        

        { (loaded) ?
        <>
            { bondData.map((b, index)=>( 
                <>
                <Row id={b.id}>
                 <Col sm={12} md={4} className="stock-header-img" >
                 <Image  src={require(`../assets/logos/${b.image}`)}  rounded width="200px" height="auto" className="text-right"/>
             </Col>
             <Col sm={12} md={8} className="m-auto px-1 ">
             <h2 className="mb-3  stock-name"> {b.registered_entity}</h2>
             <p className="stock-tagline"> {b.tagline} </p>
             </Col>
             </Row>

            <Row className="my-2">
            <Col className='my-1 stock-desc'>
             <div dangerouslySetInnerHTML={{ __html: `${b.description1}`  }} />  
            
                { (showMore) ?
                    <> <div dangerouslySetInnerHTML={{ __html: `${b.description2}`  }} /> </>  : <> </>

                }
           
            
            </Col>
            </Row>
            <Row>
                <Col sm={12} md={12} style={{ display: "flex", justifyContent: "end" }} >
                    <Button variant='outline-info' size="sm" onClick={showDetails}>
                        {buttonLabel}
                    </Button>
                </Col>

            </Row>
            <Row className='mb-3 '>
            
            <Col sm={12} md={8} className="px-1">
            <h3 className='stock-heading mb-3'>Details ({b.ticker}):</h3>
            <Table responsive="sm" className='mb-5 mt-3 mx-5' hover >        
            <tbody className='stock-details-table'>
                <tr>
                    <td className='fw-bold' style={{ color:`${b.color}` }}> Bond Type: </td>
                    <td className='text-success fw-bold' > {b.sector}  </td>
                    <td> </td>
                </tr>
                <tr>
                    <td className='fw-bold' style={{ color:`${b.color}` }}> Cost Per Bond: </td>
                    <td className='text-success fw-bold'> ${b.cost}  </td>
                    <td> </td>
                </tr>
                <tr>
                    <td className='fw-bold' style={{ color:`${b.color}` }}> Option 1: </td>
                    <td className='fw-bold'> Years: {b.option1Y}  </td>
                    <td className='fw-bold'> Interest Rate: {b.option1I}%  </td>
                </tr>
                <tr>
                    <td className='fw-bold' style={{ color:`${b.color}` }}> Option 2: </td>
                    <td className='fw-bold'> Years: {b.option2Y}  </td>
                    <td className='fw-bold'> Interest Rate: {b.option2I}%  </td>
                </tr>
                <tr>
                    <td className='fw-bold' style={{ color:`${b.color}` }}> Option 3: </td>
                    <td className='fw-bold'> Years: {b.option3Y}  </td>
                    <td className='fw-bold'> Interest Rate: {b.option3I}%  </td>
                </tr>
                </tbody>
                </Table>
            </Col>
            <div className='broker-separator p-1 my-3'>
                {b.ticker} - COMPLETED
            </div>
            </Row>

            
            </>          
                 
            ))
            }
         </> :
            <>
            <Row >
                <div className='text-center'>
                    <LoadingSpinner />
                </div>
                
            </Row>
     </>
            }
    
    </Row>
    
    </>);
}

export default AllBonds;