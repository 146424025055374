import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Image from 'react-bootstrap/Image';
import { useState, useEffect } from "react";
import StockMFgraph from '../stockComponents/StockMFgraph';


const StockExchange = ()=>{

    const [stockData, setStockData] = useState([{}]);
    const [loaded, setLoaded] = useState(false);

    const [sID, setSID] = useState(0);
    const [color, setColor] = useState('');
    const [name, setName] = useState('');


const [show, setShow] = useState(false); 
    const handleClose = () => {
      setShow(false);      
    }
    const handleShow = (id, c, n) => {
        setSID(id);
        setColor(c);
        setName(n);
        setShow(true);
    }


    var CurrencyFormat = require('react-currency-format');

    useEffect(() => {
        getAllStocksMF();
      }, []);

  
    const getAllStocksMF = async ()=>{
        const response = await fetch('/gameapi/allStocksAndMF/', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        });
        const data = await response.json();
        if(data.length !== 0){
            setStockData(data);            
            setLoaded(true);                      
        }        
    }
///////////////////////////////


    return(<>
    <Row>
        <h2 className='roboto-font ttsec-blue text-center my-4'> Stock & Mutual Fund Price Details </h2>
        {(loaded) ?
        <>
        
        <Table className='my-4' responsive>
            <thead className='text-secondary roboto-font bg-dark text-light fs-5'>
                <th ></th>
                <th className='p-2'>Company</th>
                <th className='p-2'>Sector & Industry</th>
                <th className='text-end p-2 pe-4'>Today's value</th>
                <th className='text-end p-2 pe-4'>Yesterday's Value</th>
                <th></th>
            </thead>
            <tbody className='currency fs-5'>
            { stockData.map((s, index)=>(           
              <tr key={s.id} className='my-5'>                  
                <td className='roboto-font'>
                <Image  src={require(`../assets/logos/${s.image}`)}  rounded width="45px" height="auto" />
                </td>
                <td className='roboto-font fs-6'> <span className='text-primary'> {s.ticker} </span>  {s.registered_entity} <span className='text-danger'> ({s.type}) </span> </td> 
                <td className='fs-6'> [{s.sector}] [{s.industry}] </td> 
                
                { (parseFloat(s.current_value) > parseFloat(s.previous_value))?
                <td className='currency fs-5 text-end text-success pe-4'> 
                    <CurrencyFormat value={parseFloat(s.current_value)}  
                                    fixedDecimalScale={2} decimalScale={2} displayType={'text'} 
                                    thousandSeparator={true} prefix={'$'} 
                />
                </td> 
                :
                <td className='currency fs-5 text-end text-danger pe-4'> 
                    <CurrencyFormat value={parseFloat(s.current_value)}  
                                    fixedDecimalScale={2} decimalScale={2} displayType={'text'} 
                                    thousandSeparator={true} prefix={'$'} 
                />
                </td> 
                }

                <td className='currency fs-5 text-end pe-4'>
                <CurrencyFormat value={parseFloat(s.previous_value)}  
                                    fixedDecimalScale={2} decimalScale={2} displayType={'text'} 
                                    thousandSeparator={true} prefix={'$'} 
                />
                </td>
                <td width='15%'>
                <Button onClick={()=>handleShow(s.id, s.color, s.registered_entity)} size='sm' >
                    Price History
                </Button>
                </td>
                

                </tr>
                ))
             }

            </tbody>
        </Table>

        
        
        </>
        :
        <></>
        }

    </Row>

    <Modal show={show} onHide={handleClose} size='lg' centered>  
        <Modal.Header closeButton className='bg-dark py-1'>
            <Modal.Title className='text-light fs-3 text-center  '> 
              {name}
            </Modal.Title>
          </Modal.Header>      
        <Modal.Body className='bg-light'> 
        <StockMFgraph id = {sID} color={color}/> 
                    
        </Modal.Body>
        <Modal.Footer className='bg-dark py-1'>
            
            <Button variant="warning" onClick={handleClose}> Done </Button> 
        </Modal.Footer>
    </Modal>

    </>);

}

export default StockExchange;