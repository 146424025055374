import { useState, useEffect } from 'react';
import { useParams, useNavigate} from 'react-router-dom';
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { DateTime } from "luxon";
import LoadingSpinner from '../Components/LoadingSpinner';
import Alert from 'react-bootstrap/Alert';

const UpdateQuestDetails = ()=>{
    const navigate = useNavigate();
    const{questId} = useParams();

    const [today, setToday] = useState();
    const [longStartDate, setLongStartDate] = useState('');
    const [longRegistrationEndDate, setLongRegistrationEndDate] = useState('');
    const [longEndDate, setLongEndDate] = useState('');
    const [minEndDate, setMinEndDate] = useState();
    const [loadPage, setLoadPage] = useState(false);   
    const [quest, setQuest] = useState({});
    const [show, setShow] = useState(false);
    const [resMessage, setResMessage] = useState();
    const [selectedApplicableAgeGroups, setSelectedApplicableAgeGroups] = useState([]);
   

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [details,setDetails]=useState({
        name:'',
        start_date:'1999-01-01',
        end_date:'1999-01-01',
        registration_end_date:'',
        desc:'',
        start_value:'',
        age_res:'',
        bonus_ques:'',
        a:'',
        b:'',
        c:'',
        d:'',
        answer:'A',
        bonus_amt:''
    });

    useEffect(() => { 
        getQuestDetails();       
    },[]);

    useEffect(() => {        
        setLongStartDate(DateTime.fromISO(details.start_date).toLocaleString(DateTime.DATE_HUGE));  
        setLongRegistrationEndDate(DateTime.fromISO(details.registration_end_date).toLocaleString(DateTime.DATE_HUGE));
        setLongEndDate(DateTime.fromISO(details.end_date).toLocaleString(DateTime.DATE_HUGE));
        setMinEndDate(details.start_date)
    }, [details.start_date, details.end_date, details.registration_end_date]);

    

    const getQuestDetails = async () =>{
        const response = await fetch('/gameapi/getQuestById/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                questId: `${questId}`,
             })
        });
        const data = await response.json();
        setDetails(details => ({...details, name:data.name}));
        setDetails(details => ({...details, start_date:data.start_date}));
        setDetails(details => ({...details, end_date:data.end_date}));
        setDetails(details => ({...details, registration_end_date:data.registration_end_date}));
        setDetails(details => ({...details, desc:data.description}));
        setDetails(details => ({...details, start_value:data.start_value}));
        setDetails(details => ({...details, applicable_age_groups:data.applicable_age_groups}));
        setDetails(details => ({...details, bonus_ques:data.bonus_question}));
        setDetails(details => ({...details, a:data.choice_A}));
        setDetails(details => ({...details, b:data.choice_B}));
        setDetails(details => ({...details, c:data.choice_C}));
        setDetails(details => ({...details, d:data.choice_D}));
        setDetails(details => ({...details, answer:data.answer}));
        setDetails(details => ({...details, bonus_amt:data.bonus_amount}));
        setSelectedApplicableAgeGroups(data.applicable_age_groups.split(','));
        setLoadPage(true);
    }

   

    function changeDetails(e){    
        setDetails({...details,[e.target.name]:e.target.value});  
    }

    const updateQuest =  async (e) =>{
        e.preventDefault();    
        const response = await fetch('/gameapi/updateQuestDetails/', {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            qid: `${questId}`,
            name: `${details.name}`, 
            start_date: `${details.start_date}`,
            registration_end_date: details.registration_end_date ? details.registration_end_date : null,
            end_date: `${details.end_date}`,
            description: `${details.desc}`,
            start_value: `${details.start_value}`,
            applicable_age_groups: `${details.applicable_age_groups}`,
            bonus_question: `${details.bonus_ques}`,
            choice_A: `${details.a}`,
            choice_B: `${details.b}`,
            choice_C: `${details.c}`,
            choice_D: `${details.d}`,
            answer: `${details.answer}`,
            bonus_amount: `${details.bonus_amt}`,
           })
      });
      const data = await response.json();
      setResMessage(data.message);
      handleShow();
    }

    function applicableAgeGroupsChanged(e, ageGroupId){
        let _selectedApplicableAgeGroups = selectedApplicableAgeGroups;
        if(e.target.checked === true){
            _selectedApplicableAgeGroups.push(ageGroupId);
        } else {
            _selectedApplicableAgeGroups = _selectedApplicableAgeGroups.filter(saag => saag.toString() !== ageGroupId.toString())
        }
        setSelectedApplicableAgeGroups(_selectedApplicableAgeGroups);
        setDetails({...details, applicable_age_groups:_selectedApplicableAgeGroups.join(',')});
    }
    function isApplicableAgeGroupsSelected(ageGroupId){
        return selectedApplicableAgeGroups.some(selectedApplicableAgeGroup => selectedApplicableAgeGroup.toString() === ageGroupId.toString());
    }
    
  


    return(<>
    <Row >
        <Col md={12} lg={2}>
        </Col>
        <Col md={12} lg={8}>
        <Row className="p-1 border border-2 border-primary rounded mb-5"> 
            <Row classname = "my-1">
            <h2 className="my-3 text-center form-heading"> Update Quest Details </h2>
            
        
            <Form className=' mb-3' onSubmit={updateQuest} > 

            <Form.Group className="mb-1" as={Row}>
              <Form.Label className="admin-form-labels fs-6" column sm={3}>
                Quest Name 
              </Form.Label>
              <Col sm={9}>
              <Form.Control className="mb-3" type="text" maxlength= "200" name = 'name' value={details.name} onChange={changeDetails} required />
            </Col>
              
              </Form.Group>

              <Form.Group className="mb-3" as={Row}>
              <Form.Label className="admin-form-labels fs-6" column sm={3}>
                Start Date
              </Form.Label>
              <Col sm={9}>
              <Form.Control type="Date"  value={details.start_date} name = 'start_date' onChange={changeDetails}  min={today} required />
              <Form.Text className="text-dark">
                  {longStartDate}
              </Form.Text> 
              </Col>
          </Form.Group>

          <Form.Group className="mb-3" as={Row}>
              <Form.Label className="admin-form-labels fs-6" column sm={3}>
                End Date 
              </Form.Label>
              <Col sm={9}>
              <Form.Control type="Date"  value={details.end_date} name = 'end_date' onChange={changeDetails}  min={minEndDate} required />
              <Form.Text className="text-dark">
                  {longEndDate}
              </Form.Text> 
              </Col>
          </Form.Group>

            <Form.Group className="mb-3" as={Row}>
                <Form.Label className="admin-form-labels fs-6" column sm={3}>
                    Registration End Date
                </Form.Label>
                <Col sm={9}>
                    <Form.Control type="Date"  value={details.registration_end_date} name = 'registration_end_date' onChange={changeDetails}  min={today} required />
                    <Form.Text className="text-dark">
                        {details.registration_end_date && longRegistrationEndDate}
                    </Form.Text>
                </Col>
            </Form.Group>

          <Form.Group className="mb-3" as={Row}>
                <Form.Label className="admin-form-labels fs-6" column sm={3}>
                  Description  
                </Form.Label>
                <Col sm={9}>
                <Form.Control as="textarea" rows={5}  value={details.desc}  name = 'desc' onChange={changeDetails} required/>
                </Col>
          </Form.Group>

          <Form.Group className="mb-1" as={Row}>
              <Form.Label className="admin-form-labels fs-6" column sm={3}>
                Start Value
              </Form.Label>
              <Col sm={9}>
              <Form.Control className="mb-3" type="number" min='1' name = 'start_value' value={details.start_value} onChange={changeDetails} required />
              </Col>
              </Form.Group>

          <Form.Group className="mb-1" as={Row}>
            <Form.Label className="admin-form-labels fs-6" column sm={3}>
                Age Restriction
            </Form.Label>
            <Col sm={9}>
                <Row className='bg-white'>
                    <Col sm={6}>
                        <Form.Check checked={isApplicableAgeGroupsSelected(1)} type='checkbox' id='age-group1' label="13 - 17"
                                    onChange={e => applicableAgeGroupsChanged(e, 1)}/>
                    </Col>
                    <Col sm={6}>
                        <Form.Check checked={isApplicableAgeGroupsSelected(2)} type='checkbox' id='age-group2' label="18 - 35"
                                    onChange={e => applicableAgeGroupsChanged(e, 2)}/>
                    </Col>
                    <Col sm={6}>
                        <Form.Check checked={isApplicableAgeGroupsSelected(3)} type='checkbox' id='age-group3' label="36 - 45"
                                    onChange={e => applicableAgeGroupsChanged(e, 3)}/>
                    </Col>
                    <Col sm={6}>
                        <Form.Check checked={isApplicableAgeGroupsSelected(4)} type='checkbox' id='age-group4' label="46 - 55"
                                    onChange={e => applicableAgeGroupsChanged(e, 4)}/>
                    </Col>
                    <Col sm={6}>
                        <Form.Check checked={isApplicableAgeGroupsSelected(5)} type='checkbox' id='age-group5' label="56+"
                                    onChange={e => applicableAgeGroupsChanged(e, 5)}/>
                    </Col>
                </Row>
                <Alert variant='info'>
                    You can leave all items unchecked to apply to all age groups
                </Alert>
            </Col>
        </Form.Group>

              <Form.Group className="mb-3" as={Row}>
                <Form.Label className="admin-form-labels fs-6" column sm={3}>
                  Bonus Question
                </Form.Label>
                <Col sm={9}>
                <Form.Control as="textarea" rows={3}  value={details.bonus_ques}  name = 'bonus_ques' onChange={changeDetails} required/>
                </Col>
            </Form.Group>

            <Form.Group className="mb-3" as={Row}>
              <Form.Label className="admin-form-labels fs-6" column sm={3}>
                Answer A </Form.Label>
              <Col sm={9}>
              <Form.Control type="text" maxlength= "200" value={details.a} name = 'a' onChange={changeDetails}   required />
            </Col>
            </Form.Group>

            <Form.Group className="mb-3" as={Row}>
              <Form.Label className="admin-form-labels fs-6" column sm={3}>
                Answer B
              </Form.Label>
              <Col sm={9}>
              <Form.Control type="text" maxlength= "200" value={details.b} name = 'b' onChange={changeDetails}   required />
            </Col>
            </Form.Group>

            <Form.Group className="mb-3" as={Row}>
              <Form.Label className="admin-form-labels fs-6" column sm={3}>
                Answer C 
              </Form.Label>
              <Col sm={9}>
              <Form.Control type="text" maxlength= "200" value={details.c} name = 'c' onChange={changeDetails}   required />
            </Col>
            </Form.Group>

            <Form.Group className="mb-3" as={Row}>
              <Form.Label className="admin-form-labels fs-6" column sm={3}>
                Answer D 
              </Form.Label>
              <Col sm={9}>
              <Form.Control type="text" maxlength= "200" value={details.d} name = 'd' onChange={changeDetails}   required />
            </Col>
            </Form.Group>

            <Form.Group className="mb-1"as={Row} >
            <Form.Label className="admin-form-labels fs-6" column sm={3}>
                Correct Answer
            </Form.Label>
            <Col sm={9}>
            <Form.Select className="mb-3"  value={details.answer} name = 'answer'  onChange={changeDetails} required>
            <option value="A"> Answer A </option>              
            <option value="B"> Answer B </option>
            <option value="C"> Answer C </option>
            <option value="D"> Answer D </option>                               
            </Form.Select>
            </Col>
        </Form.Group>

        <Form.Group className="mb-1" as={Row}>
              <Form.Label className="admin-form-labels fs-6" column sm={3}>
                Bonus Amount
              </Form.Label>
              <Col sm={9}>
              <Form.Control className="mb-3" type="number" min='1' name = 'bonus_amt' value={details.bonus_amt} onChange={changeDetails} required />
              </Col>
              </Form.Group>

              <div className="text-center">
            
            <Button variant="primary" type="submit" >
              Update Quest Details
            </Button>

            <Button variant="primary" className='mx-2'  onClick={() => navigate(`/admin/unpublishedQuest/${questId}`)}>
              Cancel 
            </Button>

          </div> 
             </Form>

            </Row>
        </Row>
        </Col>

        <Col  md={12} lg={2}>
        </Col>
    
    </Row>

    <Modal show={show} onHide={handleClose} backdrop='static' centered>  
        <Modal.Header closeButton className='bg-dark py-1'>
            <Modal.Title className='text-light fs-3 text-center  '> 
              Update Quest
            </Modal.Title>
          </Modal.Header>      
        <Modal.Body className='bg-light'> 
          {(resMessage === "QUEST_UPDATED") ?
            <> <p className='fs-4 '> Quest updated. </p> </>  
            :
            <> <p className='fs-4 '> Unable to update quest, please try again. </p> </>
        }
            
                    
        </Modal.Body>
        <Modal.Footer className='bg-dark py-1'>
            <Button variant="warning" onClick={() => navigate(`/admin/unpublishedQuest/${questId}`)}> Continue </Button> 
        </Modal.Footer>
    </Modal> 

    </>);
}

export default UpdateQuestDetails;