import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import {Link} from "react-router-dom";




const ResetNavBar = ()=>{
    return (<>
        <Navbar bg="dark" variant="dark" expand="lg" className="px-5 mb-4">
      
      <Navbar.Brand as={Link} to="/" className="roboto-font"> Inve$tor Quest TT 2.0</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="container-fluid ">
          
        <Nav.Item className="mx-3 my-2">
          <span className="text-info "> Reset Your Password </span>        
        </Nav.Item>

          <Nav.Item className="ms-auto px-1">
        
        </Nav.Item>

              
            

        
        </Nav>
      </Navbar.Collapse>
    
  </Navbar>
    
    </>);
    
}

export default ResetNavBar;