import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { useParams, useNavigate} from 'react-router-dom';
import Footer from '../Components/Footer';
import ResetNavBar from './ResetNavBar';
import AuthContext from '../Components/AuthContext';
import { useContext, useEffect} from 'react';
import Page from "../Components/Page";





const ResetResult = ()=>{
    const navigate = useNavigate();
    const{result} = useParams(); //primary key    
    const {logout }= useContext(AuthContext)

    useEffect(() => {
        document.body.style.backgroundColor = "white"
      }, []);
    
    useEffect(() => { 
        if(result === "complete"){
            logout();
        }
    },[]);

    return(<>
        <Page title='Complete - Password Reset'/>
        <ResetNavBar />

<Container>

<Row className='mt-4'>
    <Col sm={12} md={2} className="d-none d-md-block"> </Col>
    <Col sm={12} md={8} >  {/* Main section */}

    <Row className="align-items-center homepage-header-bg border border-1 border-secondary "> {/* header row*/}
        <Col xs={12} sm={4} className = " text-center  my-3">
            
        <Image  src={require('../assets/logos/IQlogo.png')}   width="70%" height="auto" />
        </Col>
        <Col xs={12} sm={8}>                
        <p className='fs-3 roboto-font text-light text-center my-2'> Password Reset</p>
        </Col>
    </Row> {/* close header row*/}
    

    <Row className='border border-2 border-dark mb-5'> {/* page body row*/}
    <Col sm={12} md={1} className="d-none d-md-block"> </Col>
    <Col sm={12} md={10} >
    { (result === "complete") ?
<>
<p className= 'text-center fs-3 my-4 currency'>Your password has been reset. </p>  
       
    
    <div className='text-end mb-3 mx-2' >
        <Button variant='warning'  onClick={() => navigate("/login/")}>
            Login
        </Button>
    </div>
</> 
:
<>
<p className= 'text-center fs-4 my-4 currency'> There was an error resetting your password.
 </p>  
    
    <div className='text-end mb-3 mx-3' >
        <Button variant='warning' size='sm' onClick={() => navigate("/reset/")}>
            Try Again
        </Button>
    </div>

</>
}
    
                
    </Col>
    <Col sm={12} md={1} className="d-none d-md-block"> </Col>
    </Row>
    
    </Col>
    <Col sm={12} md={2} className="d-none d-md-block"> </Col>
</Row>



</Container>


<Footer />
    
    </>);
}

export default ResetResult;