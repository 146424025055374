import { useContext, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate, Outlet } from 'react-router-dom';
import AuthContext from '../Components/AuthContext';
import NewPlayerNavBar from './NewPlayerNavBar';
import Footer from '../Components/Footer';

const GettingStarted = () => {
    const navigate = useNavigate();
    const { authToken }= useContext(AuthContext);
    const [data, setData] = useState({
        broker:'',
        job_profile:''
    });
  
   

    
    return(
    <>

        <NewPlayerNavBar />
        <Container  fliud>
        <Row>
        <Col sm={12} md={1} className="d-none d-md-block">  </Col>

         <Col sm={12} md={10} >  

            <Outlet  context={[data, setData]}/>

        </Col>

        <Col sm={12} md={1} className="d-none d-md-block"> </Col>
        </Row>
    </Container> 
    <Footer />
        


    
    </>);
}

export default GettingStarted;