import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Outlet} from "react-router-dom";
import { useState, useEffect } from 'react';
import RegNavBar from '../Components/RegNavBar';
import Footer from '../Components/Footer';


const Register = () =>{

  const [details,setDetails]=useState({
    fName:'',
    lName:'',
    pName:'',
    gender:'NONE_ENTERED',
    playerType:'Student',
    ageGroup:'1',
    schOrg:'',
    loc:'North East, Trinidad',
    otherLocation:'',
    email1:'',
    email2:'',
    p1:'',
    p2:''
});

    useEffect(() => {
      document.body.style.backgroundColor = "white";
    }, []);



    return(
    <>
    <RegNavBar/>

    <Container>
        <Row >
        <Col sm={12} md={1} className="d-none d-md-block">   </Col>

      <Col sm={12} md={10}>

      <Outlet context={[details, setDetails]} />

      </Col>

        <Col sm={12} md={1} className="d-none d-md-block"> </Col>
        </Row>
    </Container>
    <Footer />
    </>
    );
}

export default Register;
