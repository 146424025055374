import { useNavigate, useOutletContext, Link , useParams} from 'react-router-dom';
import {useState, useEffect} from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { DateTime } from "luxon";

const UpdateNewsAlertsDetails = ()=>{
    const navigate = useNavigate();

    const {alertId} = useParams();
    
    const [show, setShow] = useState(false); 
    const handleClose = () => {
      setShow(false);      
    }
    const handleShow = () => setShow(true);

    

    const [details,setDetails]=useState({
      heading:'',
      details:'',
    });

    useEffect(() => {
      getAlert();
    }, []);




    function changeDetails(e){    
        setDetails({...details,[e.target.name]:e.target.value});  
    }

    const getAlert = async()=>{   
      const response = await fetch('/gameapi/getAlertById/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          aid: `${alertId}`,
         })       
    });
    const data = await response.json();
    if(! ('message' in data))  { 
      setDetails(details => ({...details, heading:data.heading}));
      setDetails(details => ({...details, details:data.details}));   
    }  
    else{alert(data.message)}
  }

   
  const updateAlert =  async (e) =>{
      e.preventDefault();    
      const response = await fetch('/gameapi/updateAlert/', {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          heading: `${details.heading}`,
          details: `${details.details}`,
          aid: `${alertId}`,
         })
    });
    const data = await response.json();
    if(data.message === 'ALERT_UPDATED'){      
      handleShow();
    }
        
  }
 


    return (
        <>
           
        <Row >
        <Col md={12} lg={2}>
        </Col>
        <Col md={12} lg={8}>
        <Row className="p-1 border border-2 border-secondary rounded mb-5"> 
            <Row classname = "my-1">
            <h2 className="my-3 text-center form-heading"> Update News Alert </h2>
            <p className="text-danger text-center fs-6 mb-3"> Only the heading and details can be updated. </p>

            <Form className=' my-3' onSubmit={updateAlert} > 

            <Form.Group className="mb-1" as={Row}>
              <Form.Label className="admin-form-labels fs-6" column sm={4}>
                Alert Heading 
              </Form.Label>
              <Col sm={8}>
              <Form.Control className="mb-3" type="text" maxlength= "200" name = 'heading' value={details.heading} onChange={changeDetails} required />
            </Col>
              
              </Form.Group>

                   

          <Form.Group className="mb-3" as={Row}>
                <Form.Label className="admin-form-labels fs-6" column sm={4}>
                  Details 
                </Form.Label>
                <Col sm={8}>
                <Form.Control as="textarea" rows={8}  value={details.details}  name = 'details' onChange={changeDetails} required/>
                </Col>
          </Form.Group>

          
              <div className="text-end">
            
            <Button variant="primary" type="submit" >
              Update News Alert
            </Button>

            <Button variant="primary" className='mx-2'  onClick={() => navigate("/admin/upcomingAlerts/")}>
              Cancel 
            </Button>

          </div> 
             </Form>

            </Row>
        </Row>
        </Col>

        <Col  md={12} lg={2}>
        </Col>
    
    </Row>

    
      <Modal show={show} onHide={handleClose} backdrop='static' keyboard={false} centered>  
        <Modal.Header closeButton className='bg-dark py-1'>
            <Modal.Title className='text-light fs-3 text-center  '> 
              Update News Alert
            </Modal.Title>
          </Modal.Header>      
        <Modal.Body className='bg-light'> 
            <p className='fs-4 '> Alert Successfully Updated.</p> 
                    
        </Modal.Body>
        <Modal.Footer className='bg-dark py-1'>
            
            <Button variant="warning" onClick={() => navigate("/admin/upcomingAlerts/")}> Done </Button> 
        </Modal.Footer>
    </Modal>
    </>
    );
}

export default UpdateNewsAlertsDetails;