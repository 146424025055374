import { useState,  useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { useOutletContext, useNavigate } from 'react-router-dom';
import Page from "../Components/Page";


const Step2 = ()=>{
    const navigate = useNavigate();
    const [data, setData] = useOutletContext();
    const [yourAnswer, setYourAnswer] = useState("");
    const [broker, setBroker] = useState("");

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () =>  setShow1(true);

    useEffect(() => {
        setData(original => ({...original, broker: yourAnswer}));  
        if(yourAnswer === 'Elite') 
            setBroker('Elite Securities Limited');
        else if(yourAnswer === 'Capital')
            setBroker('360 Capital Market Brokers Limited'); 
        else
            setBroker('Butterfly Interactive Brokers Limited'); 
    }, [yourAnswer]);

      const handleChange = (e) => {
        e.persist();
        setYourAnswer(e.target.value);
      };

      const handleSubmit = (event)=>{
        event.preventDefault();
        if(data.broker === '')
            handleShow();
        else
            handleShow1();
        
      }

     

    return (<>
    <Page title='Select a Broker-Dealer - Get Started'/>
<Row className='starting-header'>  
        
        <div className="p-1 mb-2 text-center" >
        <span className="text-light fs-1 roboto-font"> Inve$tor Quest TT 2.0</span>  
        
        <p className="text-light fs-3 roboto-font"> 
        Unlock The Investor In You! </p>
            
        </div>   

        <div>
        <span className="text-warning fs-5 roboto-font">   Selecting A Broker-Dealer </span>
        </div>

    </Row>

    <Row className='border border-2 border-dark mb-5'>
    <Col xs={1}></Col> 

    <Col xs={10} > 

    <Row className='my-3 text-center'>
    <h2 className='text-center trade-heading1 mb-4'> Broker-Dealers</h2>  
    <p className='justify-text roboto-font fs-5 text-primary'>
    In order to trade in the Investor Quest TT world you must have a Broker-Dealer to 
    facilitate your trading activities. There are three (3) fictitious Broker-Dealers from 
    which you must choose one (1). Each Broker has associated fees that will be used 
    throughout the game and will be deducted for each trading activity.
    </p>
    <p className='fs-5 currency justify-text border border-1'>
    <span className='fw-bold'> A broker-dealer: </span> A person or financial institution 
    registered to conduct transactions on behalf of an investor. They act as intermediaries 
    between buyers and sellers of securities(stocks, bonds & mutual funds).
    </p>
    </Row> 

    <Row className='my-3 '>
    <p className='bg-dark p-1 mb-3'>
        <span className="text-light fs-4 roboto-font"> Inve$tor Quest Broker-Dealers: </span> 
        <span className="quest-color fs-4 roboto-font"> Select One </span> 
    </p>
    <Form  onSubmit={handleSubmit} >
    <Form.Group controlId="yourAnswer" required>
     <Table>
     
        <tr>
            <td width='8%' className='p-2'> 
            
            <Form.Check
                value="Elite"
                type="radio"
                id="Elite"
                onChange={handleChange}
                checked={yourAnswer === "Elite"}
                className='my-2 fs-4 border border-1 border-info text-center'
                />
            
            </td>
            <td > 
            <Row >
                 <Col sm={12} md={4} className="stock-header-img" >
                 <Image  src={require('../assets/logos/Elite.png')}  rounded width="150px" height="auto" className="text-right"/>
                </Col>

                <Col sm={12} md={8} className="m-auto px-1 ">
                <h2 className="mb-3 stock-name elite-color"> Elite Securities Limited </h2>             
                </Col>
            </Row>

            <Row className="my-2">
                <Col className='my-1 stock-desc roboto-font fs-6'>
                <div className='mb-3'>
                Elite Securities Limited has been pivotal in leading the investment market in 
                Trinidad and Tobago and the wider Caribbean since we opened our doors in 
                October 1990. We offer a variety of services to suit your needs and will 
                find a product to for your specific needs. Our Services include: 

                </div> 
                <div className='px-4'> 1.	Asset and Wealth Management. </div>
                <div className='px-4'> 2.	Investments in Securities offered on the capital markets.  </div>
                <div className='px-4'> 3.	Brokerage, and </div>
                <div className='px-4'> 4.	Financial Planning and advisory; </div>
                <div className='px-4 mb-3'> 5.	Equities.  </div>

                <div className='mb-3'>
                As a team of financial advisors with a combined experience of over 60 years, 
                you know that you can trust us to provide excellent unparalleled service.                
                </div>

                <div className='mb-4'>
                Come and speak with us to learn more and let us assist you in building 
                long term wealth by investing wisely.                
                </div>

                <div className='fs-4 text-primary roboto-font '> FEES:</div> 
                <div className='fs-5 px-4'> Broker Dealer Fee: 
                <span className='text-danger'> 1.50% of the total value of all trades. </span> </div> 
                <div className='fs-5 px-4 mb-4'> Stock Exchange Fee: 
                <span className='text-danger'> 0.14% of the total value of all trades. </span> </div> 

                </Col>
            </Row>
            </td>
            </tr>

            <tr className='broker-separator '>
                <td colspan={2} className='p-1 my-3'>360 Capital:</td>
            </tr>

            <tr>
            <td className='p-2'> 
            
            <Form.Check
                value="Capital"
                type="radio"
                id="Capital"
                onChange={handleChange}
                checked={yourAnswer === "Capital"}
                className='my-2 fs-4 border border-1 border-info text-center'
                />
           
            
            
            </td>
        

        
            <td > 
            <Row className='mt-3'>
                 <Col sm={12} md={4} className="stock-header-img" >
                 <Image  src={require('../assets/logos/360Capital.png')}  rounded width="150px" height="auto" className="text-right"/>
                </Col>

                <Col sm={12} md={8} className="m-auto px-1 ">
                <h2 className="mb-3 stock-name inv-color"> 360<sup>o</sup> Capital Market Brokers Limited </h2>             
                </Col>
            </Row>

            <Row className="my-2">
                <Col className='my-1 stock-desc roboto-font fs-6'>
                <div className='mb-3'>
                Looking for investment grade opportunities to invest in? Look no further. 
                360<sup>o</sup> is now offering you a range of products and services for wise 
                financial planning and investing. 
                </div> 
                <div className='mb-3'>
                Need funds to assist with your working capital needs? We provide 
                Invoice Discounting services, Structuring services and Initial Public Offering 
                services to help you maintain the business that you have worked so tirelessly to build. 
                </div>
                <div className='mb-3'>
                We are a wholly owned subsidiary of 360<sup>o</sup> Bank (Caribbean) Limited. 
                We currently service markets within the CARICOM region including St. Lucia, 
                Barbados, Jamaica and Grenada. At 360<sup>o</sup> Capital 
                Market Brokers, we have pooled a team of financial, legal and risk experts to be 
                at your service and provide WOW service- you win when we win!  
                </div>

                <div className='mb-2 fw-bold fs-5'>   Our products include: </div>
                <Row>
                    <Col>
                    <div className='fw-bold text-success'> Structured Finance </div>
                    <div className='px-4'> Project Finance Advisory </div>
                    <div className='px-4'> Syndication and Participation </div>
                    <div className='px-4'> Project Bonds </div>
                    <div className='px-4 mb-2'> Receivables Financing </div>
                    </Col>

                    <Col>
                    <div className='fw-bold text-success'> Capital Markets </div>
                    <div className='px-4'> Corporate Bonds </div>
                    <div className='px-4'> Sovereign Bonds </div>
                    <div className='px-4'> High Yield Debt </div>
                    <div className='px-4'> Securitization </div>
                    <div className='px-4 mb-2'> IPOs and Secondary Offerings</div>
                    </Col>
                </Row>

                <Row className='mt-2'>
                    <Col>
                    <div className='fw-bold text-success'> Investment Services </div>
                    <div className='px-4'> Portfolio Management & Advisory Services</div>
                    <div className='px-4'> US/TT Dollar Investments </div>
                    <div className='px-4 mb-4'> Mutual Funds </div>
                    </Col>
                    
                </Row>         

                
                <div className='fs-4 text-primary  '> FEES:</div> 
                <div className='fs-5 px-4'> Broker Dealer Fee: 
                <span className='text-danger'> 1.25% of the total value of all trades. </span> </div> 
                <div className='fs-5 px-4'> Stock Exchange Fee: 
                <span className='text-danger'> 0.14% of the total value of all trades. </span> </div> 

                </Col>
            </Row>
            </td>

        </tr>

        <tr className='broker-separator '>
                <td colspan={2} className='p-1 my-3'>Butterfly:</td>
        </tr>

        <tr>
            <td className='p-2'> 
            
            <Form.Check
                value="Butterfly"
                type="radio"
                id="Butterfly"
                onChange={handleChange}
                checked={yourAnswer === "Butterfly"}
                className='my-2 fs-4 border border-1 border-info text-center'
                />
            
            </td>
        

        
            <td > 
            <Row className='mt-3'>
                 <Col sm={12} md={4} className="stock-header-img" >
                 <Image  src={require('../assets/logos/Butterfly.png')}  rounded width="150px" height="auto" className="text-right"/>
                </Col>

                <Col sm={12} md={8} className="m-auto px-1 ">
                <h2 className="mb-3 stock-name text-success"> Butterfly Interactive Brokers Limited  </h2>             
                </Col>
            </Row>

            <Row className="my-2">
                <Col className='my-1 stock-desc roboto-font fs-6'>
                <div className='mb-3'>
                BIBL, formerly Caterpillar Brokers Limited, is one of the longest standing broker 
                dealers in Trinidad and Tobago. In 2015, we decided to focus solely on providing 
                financial advice in relation to investments and act as broker dealers for the sale 
                and issue of securities in mutual funds hence the rebranded name. 
                </div> 
                <div className='mb-3'>
                We offer both TTD and USD Mutual and Income Funds, investment in equities and 
                bonds, both issued by private limited liability companies, public companies 
                and the Government of the Republic of Trinidad and Tobago.  
                </div>
                <div className='mb-3'>
                We are currently seeking to expand the range of services provided and the 
                jurisdictions in which we operate. We hope to reach the Caribbean market 
                before the end of 2018 and the international market by the end of year 2020. 
                </div>

                <div className='mb-4'>
                We currently have a diverse set of clients, including individual, institutional 
                and corporate investors. Our financial experts are ready and available to speak 
                to you as you seek to invest for your future, your children's future and their 
                children's future. Contact a representative today. 
                </div>                       

                
                <div className='fs-4 text-primary  '> FEES:</div> 
                <div className='fs-5 px-4'> Broker Dealer Fee: 
                <span className='text-danger'> 1% of the total value of all trades. </span> </div> 
                <div className='fs-5 px-4'> Stock Exchange Fee: 
                <span className='text-danger'> 0.14% of the total value of all trades. </span> </div> 

                </Col>
            </Row>
            </td>

        </tr>


        
        
        
    

     </Table>
     </Form.Group>
        

        <Row className='my-3'>
        <Col className='text-end'>
            <Button variant="warning" type="submit">  
                Select Broker
            </Button>
        </Col>
      </Row> 
     </Form>
    </Row>

        
    </Col>
    


    <Col xs={1}></Col>  
    </Row>

    <Modal show={show} onHide={handleClose} centered>  
        <Modal.Header closeButton className='bg-dark py-1'>
            <Modal.Title className='text-light fs-3 text-center  '> 
              Selecting A Broker
            </Modal.Title>
          </Modal.Header>      
        <Modal.Body className='bg-light'> 
            <p className='fs-4 '> <span className='text-primary'> You didn't select a broker. </span>
            Click on the radio button associated with a Broker to select it.  </p> 
                    
        </Modal.Body>
        <Modal.Footer className='bg-dark py-1'>
            <Button variant="warning" onClick={handleClose}> Continue </Button>             
        </Modal.Footer>
    </Modal>

    <Modal show={show1} onHide={handleClose1} centered>  
        <Modal.Header closeButton className='bg-dark py-1'>
            <Modal.Title className='text-light fs-3 text-center  '> 
            Selecting A Broker
            </Modal.Title>
          </Modal.Header>      
        <Modal.Body className='bg-light'> 
            <p className='fs-5 '> <span className='text-primary'>You selected {broker}. </span>
             Click confirm to continue.</p> 
                    
        </Modal.Body>
        <Modal.Footer className='bg-dark py-1'>
            <Button variant="warning" onClick={() => navigate("/start/profile")}> Confirm </Button>    
            <Button variant="warning" onClick={handleClose1}> Cancel </Button>          
        </Modal.Footer>
    </Modal>


        
    </>)
}

export default Step2;