import { useNavigate } from 'react-router-dom';
import {useState, useEffect} from 'react';
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import LoadingSpinner from '../Components/LoadingSpinner';
import Modal from 'react-bootstrap/Modal';

const SelectSorMF = ({secType, questId})=>{
    const navigate = useNavigate();
    //stockData can also store mutual fund data
    const [stockData, setStockData] = useState([{}]); 
    const [loaded, setLoaded] = useState(false);
    
    const [selectedStocks, setSelectedStocks] = useState([]);
    
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);

    useEffect(() => {
        getAll();
      }, []);

      
    const getAll = async ()=>{
        const response = await fetch('/gameapi/allStocksOrMF/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                type: `${secType}`,
             })
        });
        const data = await response.json();
        if(data.length !== 0){
            setStockData(data);
            setLoaded(true);
        }        
    }

  
    const updateSelectedItem = (e)=>{ 
        if(e.target.checked) //add
            setSelectedStocks(selectedStocks => [...selectedStocks, e.target.id])
        else{ //remove
            setSelectedStocks((current) =>
                current.filter((v) => {
                return v !== e.target.id;
            }))
        }       
    }

    const addToQuest = async(e) =>{
        e.preventDefault();
        if(selectedStocks.length <= 0){
            handleShow();
        }
        else{
            var selectedIds = JSON.stringify({selectedIds: `${selectedStocks}`})
            selectedIds = selectedIds.substring(1, selectedIds.length)
            var jsonStr = `{"qid": "${questId}"` + "," + selectedIds;
            
            const response = await fetch('/gameapi/addSecToQuest/', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: jsonStr
            });
            const data = await response.json();
            if(data.message === "ERROR_ADDING_STOCKS_OR_MF"){
                alert('Something went wrong! Try adding it again.');
            }
            else{
              handleShow1();
            }   
            
            
        }
    }

    return (<>
            

            {(loaded) ?
            <>
            <Form className=' mb-3' onSubmit={addToQuest} > 
            <Table responsive="sm" >
                <thead>    <tr>
                <th></th>
                <th className=' text-danger'>Company</th>
                <th className='text-danger'>Sector & Industry</th>
                 <th className='text-center text-danger'>Add To Quest?</th>
                </tr>  </thead>
                <tbody>
                
            { stockData.map((s, index)=>(         
                
                <tr key={s.id}> 
                    <td> 
                    <Image  src={require(`../assets/logos/${s.image}`)}  rounded width="50px" height="auto" />
                    </td>
                    <td className='fw-bold'>
                        {s.registered_entity}
                    </td>
                    <td>
                        {s.sector}, {s.industry}
                    </td>
                    <td>                    
                   <div className="text-center">
                   <Form.Check
                        name={s.id}
                        type='checkbox'
                        id={s.id}
                        onChange={updateSelectedItem}                   
                    />
                </div>

                    </td>
                </tr> 
            ))} 
            </tbody>
            </Table>
            <div className="text-center">
            
            <Button variant="primary" type="submit" >
              Add to Quest
            </Button>
            

          </div> 
            </Form>
            </>
            :
            <>
            <div className="p-3 mb-3 text-center">
                <LoadingSpinner/>
            </div>
            </>
            }

    <Modal show={show} onHide={handleClose} backdrop='static' centered>  
        <Modal.Header closeButton className='bg-dark py-1'>
            <Modal.Title className='text-light fs-3 text-center  '> 
              Adding {secType} to Quest
            </Modal.Title>
          </Modal.Header>      
        <Modal.Body className='bg-light'> 
            <p className='fs-4 '> You must select at least 1 {secType}</p> 
                    
        </Modal.Body>
        <Modal.Footer className='bg-dark py-1'>
            <Button variant="warning" onClick={handleClose}> Close </Button> 
        </Modal.Footer>
    </Modal> 

    <Modal show={show1} onHide={handleClose1} backdrop='static' centered>  
        <Modal.Header closeButton className='bg-dark py-1'>
            <Modal.Title className='text-light fs-3 text-center  '> 
             Adding {secType} to Quest
            </Modal.Title>
          </Modal.Header>      
        <Modal.Body className='bg-light'> 
            <p className='fs-4 '> {secType} sucessfully added.</p> 
                    
        </Modal.Body>
        <Modal.Footer className='bg-dark py-1'>
            {(secType === 'STOCK') ?
            <Button variant="warning" 
                onClick={() => navigate(`/admin/addMF/${questId}`)}
            > 
                Continue </Button> 
            :
            <Button variant="warning" 
                onClick={() => navigate(`/admin/addBonds/${questId}`)}
            > 
                Continue </Button>
            }
            
        </Modal.Footer>
    </Modal>
    
       <Row>

        </Row>     
     
    </>);
}

export default SelectSorMF;
