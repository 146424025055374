import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SelectSorMF from './SelectSorMF';
import { useParams} from 'react-router-dom';


const AddStocksToQuest = ()=>{

    const{questId} = useParams();

    return (<>

    <Row >
        <Col md={12} lg={1}>
        </Col>
        <Col md={12} lg={10}>
        
        <Row className="p-1 border border-2 border-primary rounded mb-5">             
            <h2 className="my-4 text-center form-heading"> Add Stocks to Quest (Step 2)   </h2>           

           <SelectSorMF secType={'STOCK'} questId={questId}/>

        </Row>       

        </Col>

        <Col  md={12} lg={1}>
        </Col>
    
    </Row>
    
    </>);
}

export default AddStocksToQuest;