import { useParams} from 'react-router-dom';
import HistoryByType from './HistoryByType';
import Page from "../Components/Page";

const TransactionHistory = ()=>{  
    const {type} = useParams();

    return(<>
     <Page title='Transaction History'/>
     <HistoryByType sec_type={`${type}`} />        
    
    </>);
}

export default TransactionHistory;