import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useParams, useNavigate, useOutletContext, } from 'react-router-dom';
import {  useState, useContext, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import LoadingSpinner from '../Components/LoadingSpinner';
import { DateTime } from "luxon";
import congrats from '../assets/audio/congrats.mp3';
import Page from "../Components/Page";

const BondsOwnedDetails = ()=>{
    const navigate = useNavigate();

    var CurrencyFormat = require('react-currency-format');

    const {BondOId} = useParams();
    const [gameData, setGameData] = useOutletContext();
    const [bondOwned, setBondOwned] = useState({});
    const [bondDetails, setBondDetails] = useState({});
    const [showDetails, setShowDetails] = useState(false);
    const [cal, setCal] = useState(false);
    const [iPayed, setIPayed] = useState(0);
    const [sellValue, setSellValue] = useState(0);
    const [nPayLeft, setNPayLeft] = useState(0);
    const [availableCash, setAvailableCash] = useState(0);
    const [exchFee, setExchFee] = useState(0);
    const [brFee, setBrFee] = useState(0);
    const [brokerName, setBrokerName] = useState("");
    const [brokerFeeSell, setBrokerFeeSell] = useState(0);
    const [exchangeSell, setExchangeSell] = useState(0);
    const [cashSpending, setCashSpending] = useState(0);
    const [totalFeeSell, setTotalFeeSell] = useState(0);

    const [bondSelling, setBondSelling] = useState([]);
    const [sellLoaded, setSellLoaded] = useState(false);
    const [showSellButton, setShowSellButton] = useState(true);

    const [msg, setMsg] = useState("");

    const audio2 = new Audio(congrats);

    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
    } 
    
    const handleShow = async () => {
        const response = await fetch('/gameapi/checkIfBondSold/', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
              qpID: `${gameData.id}`,
              boID : `${BondOId}`
             })
        });
        const data = await response.json(); 
        if (data.message === 'SOLD'){
            alert("Your data is out of sync, unable to sell bond.");
            navigate(`/game/`);
            return;
        }
        setShow(true);
    }

    
    const [show2, setShow2] = useState(false);
    const handleClose2 = () => {
        setShow2(false);
        navigate(`/game/quest/${gameData.quest.id}`);
    }
    
    const handleShow2 = () => {
        setShow2(true);
        audio2.play();
    }

    const [show4, setShow4] = useState(false);
    const handleClose4 = () => setShow4(false);
    const handleShow4 = () => setShow4(true);

    useEffect(() => {
        getBondsOwned();
        getAvailableCash();
        getBrokerRates();
        getAllPendingSellBonds();
      }, []);

      useEffect(() => {
        if(cal)
            calculatePayments();
      }, [cal]);

      useEffect(() => {
        if(sellLoaded)
            bondAlreadySold();
      }, [sellLoaded]);

      const getAvailableCash = ()=>{
        let spend = parseFloat(gameData.cash_value) - parseFloat(gameData.fees_owed);
        setAvailableCash(spend.toFixed(2));
    }

    const getBrokerRates = ()=>{
        if (gameData.player.broker_tag === 'Elite'){
            setBrokerName('Elite Securities Limited');
            setExchFee(0.14);
            setBrFee(1.5);
        }
        else if (gameData.player.broker_tag === 'Capital'){
            setBrokerName('360 Capital Market Brokers Limited');
            setExchFee(0.14);
            setBrFee(1.25);
        }
        else{
            setBrokerName('Butterfly Interactive Brokers Limited');
            setExchFee(0.14);
            setBrFee(1.0);
        }
    }

    const findBond = (bid)=>{
        const searchIndex = gameData.quest.bonds.findIndex((b) => b.id == bid);
        return gameData.quest.bonds[searchIndex];
    }

    const getBondsOwned = async () =>{
        const response = await fetch('/gameapi/getOneBondOwned/', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
              bid: `${BondOId}`,
             })
        });
        const data = await response.json();                  
        if(! ('message' in data)){ 
               let b = findBond(data.bond);
               setBondDetails(b);
               setBondOwned(data);    
               setCal(true);
        } 
        else{
            setMsg("Something went wrong, try again later");
        }
        
        setShowDetails(true);
      }

      const getAllPendingSellBonds = async () =>{  //24th may
        const response = await fetch('/gameapi/getAllPendingBondsSell/', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
              qpID: `${gameData.id}`,
             })
        });
        const data = await response.json(); 
        if(! ('message' in data))  {      
           setBondSelling(data);
           setSellLoaded(true)  ;
        }       
      }

      const bondAlreadySold = ()=>{
        const searchIndex = bondSelling.findIndex((b) => b.bonds_owned == BondOId);
        if(searchIndex >= 0)
            setShowSellButton(false);
      }

      const calculatePayments = ()=>{
        let coupVal = parseFloat(bondOwned.coupon_value);
        let p = parseInt(bondOwned.number_coupons_paid) * coupVal;
        setIPayed(p);

        let nRem = parseInt(bondOwned.total_number_coupons) - parseInt(bondOwned.number_coupons_paid);
        setNPayLeft(nRem);
        let r = 1 + (parseFloat(bondOwned.intrest_rate) / 100);

        let sum = 0;
        for(let i=1; i<=nRem; i++){
            sum = sum + (coupVal/Math.pow(r, i));
        }

        let s2 = parseFloat(bondOwned.principal) / Math.pow(r, nRem);
        sum = sum + s2;
        setSellValue(sum);

        let bFee = ((brFee/100) * sum);
        let eFee = ((exchFee/100) * sum);
        let fees = (bFee + eFee);
        setTotalFeeSell (fees);
        setBrokerFeeSell(bFee);
        setExchangeSell(eFee);

        let spend = parseFloat(gameData.cash_value) - (fees + parseFloat(gameData.fees_owed));
        setCashSpending(spend);
      }

      const executeTrade = async () =>{
        const response = await fetch('/gameapi/tradeStatus/', {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        });
        const data = await response.json(); 
        if(data.message === 'YES')
            sellBond();
        else{
            handleClose();
            handleShow4();
        }
            

    }

      const sellBond = async ()=>{
        handleClose();
        const response = await fetch('/gameapi/addPendingBondsSell/', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                bondId: `${BondOId}`,
                sellValue: `${sellValue}`,
                fees: `${totalFeeSell}`,
             })
        });
        const data = await response.json(); 
        if(data.message === 'PENDING_BONDS_SELL_ADDED'){
            let tFee = parseFloat(gameData.fees_owed) + parseFloat(totalFeeSell);
            setGameData(original => ({...original, fees_owed: tFee}));
            handleShow2();
        }
        else
            alert('Unable to complete transaction, try agina later');          
        
      }


    return(<>

    <Row className='border border-2 border-dark mb-3'>

        <h1 className='mt-3 mb-5 quest-play-sectionheadings text-center '>Bond Details </h1>
        {(showDetails) ?
        <>

            <Page title={`${bondDetails.registered_entity} Details - Bonds Owned`} />
        <Row className='mb-2'> 
            <Col xs={1}></Col>   
            <Col xs={10} > {/* main section*/}


        <Row> {/* body row*/}
        <Table responsive="md" bordered>
            <thead>
                <tr>
                    <th colSpan={2} className='roboto-font fs-5 ttsec-blue'> 
                        Details - (Issue ID: B-{bondOwned.id})
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr >
                    <td width='30%' className='roboto-font fs-5'>
                        Company
                    </td>
                    <td className='currency fs-5 px-5'>
                    <Image  src={require(`../assets/logos/${bondDetails.image}`)}   width="60px" height="auto" /> 
                    {bondDetails.registered_entity}
                    </td>
                </tr>

                <tr >
                    <td  className='roboto-font fs-5'>
                        Issue Date
                    </td>
                    <td className='currency fs-5 px-5'> 
                    {DateTime.fromISO(bondOwned.date_bought).toLocaleString(DateTime.DATE_HUGE)}                   
                    </td>
                </tr>

                <tr >
                    <td  className='roboto-font fs-5'>
                        Maturity Date
                    </td>
                    <td className='currency fs-5 px-5'> 
                    {DateTime.fromISO(bondOwned.maturity_date).toLocaleString(DateTime.DATE_HUGE)}                   
                    </td>
                </tr>
                
                <tr >
                    <td  className='roboto-font fs-5'>
                        Purchase Details
                    </td>
                    <td className='currency fs-5 px-5'>                    
                    {bondOwned.quantity} bonds bought (<CurrencyFormat 
                            value={parseFloat(bondDetails.cost)}  
                            fixedDecimalScale={2} decimalScale={2} displayType={'text'} 
                            thousandSeparator={true} prefix={'$'} 
                        /> each) for {bondOwned.number_years} years 
                    <span className='text-secondary fs-6'> ({bondOwned.number_years} months in Investor Quest) </span> at an interest rate of {parseFloat(bondOwned.intrest_rate).toFixed(1)}%
                    </td>
                </tr>

                <tr >
                    <td  className='roboto-font fs-5'>
                        Principal
                    </td>
                    <td className='currency fs-5 px-5'>  
                    <CurrencyFormat value={parseFloat(bondOwned.principal)}  
                            fixedDecimalScale={2} decimalScale={2} displayType={'text'} 
                            thousandSeparator={true} prefix={'$'} 
                    />                  
                     
                    </td>
                </tr>

                <tr >
                    <td  className='roboto-font fs-5'>
                        Coupon Value*
                    </td>
                    <td className='currency fs-5 px-5'>                    
                     <CurrencyFormat value={parseFloat(bondOwned.coupon_value)}  
                            fixedDecimalScale={2} decimalScale={2} displayType={'text'} 
                            thousandSeparator={true} prefix={'$'} 
                    /> 
                    </td>
                </tr>

                <tr >
                    <td  className='roboto-font fs-5'>
                        Interest Paid
                    </td>
                    <td className='currency fs-5 px-5'> 
                    <CurrencyFormat value={parseFloat(iPayed)}  
                            fixedDecimalScale={2} decimalScale={2} displayType={'text'} 
                            thousandSeparator={true} prefix={'$'} 
                    /> <span className='text-secondary'> (Number of payments made so far: {bondOwned.number_coupons_paid} )</span>
                    
                    </td>
                </tr>

                <tr >
                    <td  className='roboto-font fs-5'>
                        Number Payments Left
                    </td>
                    <td className='currency fs-5 px-5'>                    
                     {nPayLeft}
                    </td>
                </tr>

                <tr >
                    <td  className='roboto-font fs-5'>
                        Next Payment Date
                    </td>
                    <td className='currency fs-5 px-5'>  
                    {(nPayLeft == 0) ?
                    <span>-- </span>
                    :
                    <span>
                      {DateTime.fromISO(bondOwned.next_payment_date).toLocaleString(DateTime.DATE_HUGE)}                     
                    </span>
                    }                  
                    
                    </td>
                </tr>

                <tr >
                    <td  className='roboto-font fs-5'>
                        Selling Price**
                    </td>
                    <td className='currency fs-5 px-5'> 
                    <CurrencyFormat value={parseFloat(sellValue)}  
                            fixedDecimalScale={2} decimalScale={2} displayType={'text'} 
                            thousandSeparator={true} prefix={'$'} 
                    />                   
                    </td>
                </tr>     

                <tr >
                    <td  colSpan={2} className='roboto-font fs-5 text-end'>
                    { (showSellButton) ?
                    <Button variant='primary' size='sm' onClick={handleShow}>
                        Sell Bonds
                    </Button>
                    :
                        <p className='fs-6 text-primary'>This Bond has already been sold.</p>

                    }
                        
                    </td>
                    
                </tr>           

            </tbody>
        </Table>

        <Row>
        <p className='text-danger mb-2'> *Coupons / dividens are paid every 2 weeks from the issue date. </p>
            <p className='text-danger'> **You would be paid the amount currently shown & all future 
            coupon payments would be cancelled. </p>
        </Row>

        </Row> {/* close body row*/}
            
            </Col> {/* end main section*/}
            <Col xs={1}></Col> 
        </Row>

    

        
        </>
        :
        <div className="p-3 mb-3 text-center"> 
            <LoadingSpinner/>  
        </div>

        }

     </Row>  

     <Modal show={show} onHide={handleClose} centered>  
        <Modal.Header closeButton className='bg-dark py-1'>
            <Modal.Title className='text-light fs-3 text-center  '> 
              Selling Bonds
            </Modal.Title>
          </Modal.Header>      
        <Modal.Body className='bg-light currency '> 
        <p className='fs-4 bg-primary  fw-bold px-2'> Issue ID: B-{bondOwned.id} </p>
            <Row>
                <Col xs={8}>
                <p className='fs-4 px-2'> Cash Earned:  </p>
                </Col>
                <Col xs={4}>
                <p className='fs-4 text-end px-2'> 
                <CurrencyFormat value={parseFloat(sellValue)}  
                            fixedDecimalScale={2} decimalScale={2} displayType={'text'} 
                            thousandSeparator={true} prefix={'$'} 
                    />
                 </p>
                </Col>
            </Row>

            <Row>
                <Col xs={8}>
                <p className='fs-4 px-2 text-primary'> Broker Fee:  </p>
                </Col>
                <Col xs={4}>
                <p className='fs-4 text-end px-2 text-primary'> 
                <CurrencyFormat value={parseFloat(brokerFeeSell)}  
                            fixedDecimalScale={2} decimalScale={2} displayType={'text'} 
                            thousandSeparator={true} prefix={'$'} 
                    /> 
                 </p>
                </Col>
            </Row>

            <Row>
                <Col xs={8}>
                <p className='fs-4 px-2 text-primary'> Stock Exchange Fees:  </p>
                </Col>
                <Col xs={4}>
                <p className='fs-4 text-end px-2 text-primary'> 
                <CurrencyFormat value={parseFloat(exchangeSell)}  
                            fixedDecimalScale={2} decimalScale={2} displayType={'text'} 
                            thousandSeparator={true} prefix={'$'} 
                    /> 
                 </p>
                </Col>
            </Row>

            <Row>
                <Col xs={8}>
                <p className='fs-4 px-2 text-primary fw-bold'> Total Fees:  </p>
                </Col>
                <Col xs={4}>
                <p className='fs-4 text-end px-2 text-primary fw-bold'> 
                <CurrencyFormat value={parseFloat(totalFeeSell)}  
                            fixedDecimalScale={2} decimalScale={2} displayType={'text'} 
                            thousandSeparator={true} prefix={'$'} 
                    /> 
                 </p>
                </Col>
            </Row>
           
             
                    
        </Modal.Body>
        <Modal.Footer className='bg-dark py-1'>
        
        {(cashSpending >= 0)?
            <Button variant="warning" onClick={executeTrade}> Confirm Sale </Button> 
            :
            <p className='fs-6 text-danger'> *You do not have enough cash to complete this trade.</p>
        }            
            <Button variant="warning" onClick={handleClose}> Cancel </Button>             
        </Modal.Footer>
    </Modal> 

   

    <Modal show={show2} onHide={handleClose2} centered backdrop='static' keyboard={false}>  
        <Modal.Header closeButton className='bg-dark py-1'>
            <Modal.Title className='text-light fs-3 text-center  '> 
              Trading
            </Modal.Title>
          </Modal.Header>      
        <Modal.Body className='bg-light'> 
            <p className='fs-5 text-primary'> Your trade has been successfully posted. It would be executed 
             within 24 hours. </p> 
                    
        </Modal.Body>
        <Modal.Footer className='bg-dark py-1'>
            <Button variant="warning" onClick={handleClose2}> Continue </Button>             
        </Modal.Footer>
    </Modal>

    <Modal show={show4} onHide={handleClose4} centered backdrop='static' keyboard={false}>  
        <Modal.Header closeButton className='bg-dark py-1'>
            <Modal.Title className='text-light fs-3 text-center  '> 
              Trading
            </Modal.Title>
          </Modal.Header>      
        <Modal.Body className='bg-light'> 
            <p className='fs-5 text-primary'> Trading is currently closed while the system is being
             updated. Try back again in an hour.
            </p> 
                    
        </Modal.Body>
        <Modal.Footer className='bg-dark py-1'>
            <Button variant="warning" onClick={handleClose4}> Continue </Button>             
        </Modal.Footer>
    </Modal>

        
    
    </>);
}

export default BondsOwnedDetails;