import { BarChart, Bar,  XAxis, Cell, YAxis, ResponsiveContainer, } from 'recharts';
import {  useState,  useEffect } from 'react';

const PortfolioBarChart = ({chartData})=>{
    const [maxValue, setMaxValue] = useState(0);
    const [showGraph, setShowGraph] = useState(false);

    const colors = ['#0088FE', '#FF8C00', '#556B2F', '#4B0082', 'red', 'pink'];

    const getPath = (x, y, width, height) => {
        return `M${x},${y + height}C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3}
        ${x + width / 2}, ${y}
        C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${x + width}, ${y + height}
        Z`;
    };

    const TriangleBar = (props) => {
        const { fill, x, y, width, height } = props;
    
        return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
    };

    useEffect(() => { 
        findHighest();       
    },[]);

    const findHighest = ()=>{
        let high = -1;
        for(let i=0; i<chartData.length; i++){
            var v = parseInt(chartData[i].value)
            if(chartData[i].value > high)
                high = v;
        }
        setMaxValue(high);
        setShowGraph(true);
    }

    function formatYAxis(value) {
        return '$' + value;
    }
  


return (<>
    
    {(showGraph) ?
    <>
    <ResponsiveContainer width="99%" height={250}>
    <BarChart
      width={700}
      height={300}
      data={chartData}
      margin={{
        top: 20,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      
      <XAxis dataKey="name" 
        tick={{ fill: 'black' }}
      />
      <YAxis domain={[dataMin =>(0),dataMax =>(maxValue)]} 
        tickFormatter={formatYAxis}
        tick={{ fill: 'black' }}
      />
      <Bar dataKey="value" fill="#8884d8" shape={<TriangleBar />} label={{ position: 'top' }}>
        {chartData.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={colors[index % 20]} />
        ))}
      </Bar>
    </BarChart>
    </ResponsiveContainer>
    </>
    : <></>
    }
    
    
    </>);
}

export default PortfolioBarChart;