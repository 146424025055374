import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import { BsChevronDoubleRight} from "react-icons/bs";

const HistoryButton = ({type})=>{
    const navigate = useNavigate();

    return (<>
        <Button size="sm" variant="warning" onClick={() => navigate(`../history/${type}`)}>
            View History <BsChevronDoubleRight/>
        </Button>
    </>);
}

export default HistoryButton;