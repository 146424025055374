import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Navbar from "react-bootstrap/Navbar";
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom'
import {useEffect, useState} from 'react';
import Footer from '../Components/Footer';
import {  BsChevronDoubleRight } from "react-icons/bs";
import icon from '../assets/images/favicon.ico';
import Page from "../Components/Page";



const HomePage = () =>{
    const navigate = useNavigate();

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    useEffect(() => {
      const favicon = document.getElementById('favicon');
      favicon.setAttribute('href', icon);
    }, []);

    useEffect(() => {
      document.body.style.backgroundColor = "#075b8b"
    }, []);



    return (
    <>
        <Page title='Home'/>
<Navbar bg="dark" variant="dark" expand="sm" className="px-5 mb-4">      
      <Navbar.Brand > 
       <span className="inv-color roboto-font">Inve$tor</span> <span className="quest-color roboto-font">Quest TT 2.0</span> 
      </Navbar.Brand>
    </Navbar>

   <Container fluid className='homepage-header-bg' >
      <Row className=''>
      
      <Col >  {/* Main section */}

        <Row className="align-items-center homepage-header-bg mb-4"> {/* header row*/}
          <Col xs={12} lg={6} className = " text-center  my-3">
            
          <Image  src={require('../assets/logos/IQlogo.png')}   width="70%" height="auto" />
          </Col>
          <Col xs={12} lg={6}>
          <p className='fs-1 roboto-font text-light text-center my-2 me-5'> Welcome to Inve$tor Quest TT 2.0
          <div className=" fs-4 roboto-font "> A Stock Market Simulation Game Designed in Trinidad and Tobago   </div> 
            </p>
          <div className='text-center my-5 me-5'>
          <Button variant="warning" size="lg"  onClick={() => navigate("/begin/")} > 
            ENTER <BsChevronDoubleRight/>
          </Button>
          </div>
          </Col>
      </Row> {/* close header row*/}     


        </Col> {/* End Main section */}
         
      </Row>
     <div
       className="modal show"
       style={{ display: 'block', position: 'initial' }}
     >
       <Modal show={show} onHide={handleClose} centered size="xl">
         <Modal.Header closeButton>
         </Modal.Header>
         <Modal.Body>

           <div className="mx-auto my-auto">
           <Image className="banner" src={require('../assets/images/java-mini-quest-2024.png')} width="100%" height="auto" />
           </div>

         </Modal.Body>
       </Modal>
     </div>
    </Container>
    <Footer />
    </>
    );
}

export default HomePage;
