import {  XAxis, YAxis, CartesianGrid, 
    Tooltip, Legend, ResponsiveContainer, AreaChart, Area} from 'recharts';
import { useState, useEffect } from 'react';
import LoadingSpinner from '../Components/LoadingSpinner';
import { DateTime } from "luxon";

const StockMFgraph = ({id, color})=>{
    const [historyData, setHistoryData] = useState([{}]);
    
    const [high, setHigh] = useState(0);
   
    const [loaded, setLoaded] = useState(false);
    const [initialRender, setInitialRender] = useState(true);
    const [renderGraph, setRenderGraph] = useState(false);
    const [daysOnGraph, setDaysOnGraph] = useState(200);

    

    useEffect(() => {
        getHistory();
      }, []);

      useEffect(() => {
        if(initialRender){
            setInitialRender(false);
          } else{
            getHighAndLow();
          }        
      }, [loaded]);

      
                  
      const getHistory = async ()=>{
        const response = await fetch(`/gameapi/getHistory/${id}/`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json'},
            body: JSON.stringify({
                days: `${daysOnGraph}`,
             })
        });
        const data = await response.json();
        if(! ('message' in data)){
            setHistoryData(data);
            setLoaded(true);
        }        
    }      

    const getHighAndLow = ()=>{
        for(let j=0; j<historyData.length; j++){
            historyData[j].value = parseFloat(historyData[j].value);
        }
        var newHist = [...historyData];
        setHistoryData(newHist);
        let hi = historyData[0].value;
        for(let i=0; i<historyData.length; i++){
            if(historyData[i].value > hi)
                hi = historyData[i].value; 
        }        
        //hi = hi + 10.0;
        setHigh(hi);
        setRenderGraph(true);
    }

    function formatYAxis(value) {
        return '$' + (Math.round(value * 100) / 100).toFixed(2);
      }

      function formatXAxis(value) {
        return  DateTime.fromISO(value).toLocaleString(DateTime.DATE_MED);
      }

      function dateFormatter(value) {
        return  DateTime.fromISO(value).toLocaleString(DateTime.DATE_MED);
      }

      function numberFormatter(value) {
        return '$' + (Math.round(value * 100) / 100).toFixed(2);
      }



    return(<>
        { (renderGraph) ?
            <> 
            <ResponsiveContainer width="99%" height={300}>
                <AreaChart width={730} height={250} data={historyData}
                margin={{ top: 10, right: 20, left: 20, bottom: 10 }}>
                <defs>
                    <linearGradient id="colorPrice" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor={color} stopOpacity={0.8}/>
                        <stop offset="95%" stopColor={color} stopOpacity={0}/>
                    </linearGradient>
                </defs>
                <XAxis dataKey="date_of_change" tick={{ fill: 'black' }} 
                    tickFormatter={formatXAxis}
                    angle={-10} textAnchor="end"
                />
                <YAxis domain={[dataMin =>(0),dataMax =>(high)]} 
                    tickFormatter={formatYAxis}
                    tick={{ fill: 'black' }}
                />
                
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip labelFormatter={dateFormatter} formatter={numberFormatter}/>
                <Legend />
                <Area type="monotone" dataKey="value" name="Share Price"         
                    stroke={color} fillOpacity={1} fill="url(#colorPrice)"
                    dot = {{stroke:'white'}}
                />

                </AreaChart>
            </ResponsiveContainer>
            </>
            : <>   
            
            <LoadingSpinner /> 
            
            </>
        }
 


</>);
}

export default StockMFgraph;