import { useState,  useEffect } from 'react';
import { BsEnvelopeFill, BsFacebook, BsInstagram, BsYoutube } from "react-icons/bs";


const Footer = ()=>{

    const [year, setYear]= useState("");

    useEffect(() => {
        var d = new Date();
        var y = d.getFullYear();
        setYear(y);
      }, []);


    return(<>
    <footer className='bg-dark '>
    <p className='text-light text-center pt-1'>  Inve$tor Quest TT 2.0 &#169;
       {(year === 2023) ?
       <span> {year} </span>
       :
       <span> 2023 - {year} </span>
        }
        &#160;
       | &#160; All Rights Reserved. &#160; | &#160; 
       <a href='https://www.facebook.com/investucateTT/' target="_blank" rel="noopener noreferrer" className='px-2'> 
      <BsFacebook size={20} style={{ fill: '#F8F9FA' }} />  
      </a>
      <a href='https://www.instagram.com/investucatett/?igshid=z8r3u3u0d06d' target="_blank" rel="noopener noreferrer" className='px-2'> 
      <BsInstagram size={20} style={{ fill: '#F8F9FA' }} />  
      </a>

      <a href='https://www.youtube.com/channel/UCKSmOaLAQCm3WM4K1mCdoWw' target="_blank" rel="noopener noreferrer" className='px-2'> 
      <BsYoutube size={20} style={{ fill: '#F8F9FA' }} />  
      </a> &#160;
       | &#160; <BsEnvelopeFill size={20} style={{ fill: '#F8F9FA' }} />  ccei@ttsec.org.tt
       </p> 
    </footer>
    </>)
}

export default Footer;