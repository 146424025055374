import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate, Link  } from 'react-router-dom';
import { useState, useContext, useEffect } from 'react';
import AuthContext from '../Components/AuthContext';
import jwt_decode from "jwt-decode";
import LoginNavBar from '../Components/LoginNavBar';
import Footer from '../Components/Footer';
import { FiEyeOff, FiEye, FiInfo } from "react-icons/fi";
import LoadingSpinner from "../Components/LoadingSpinner"
import Page from "../Components/Page";

const Login = () =>{
    const navigate = useNavigate();
    const [username, setUserName] = useState();
    const [password, setPassword] = useState();
    const [errorMsg, setErrorMsg] = useState('');
    const { setAuthToken, setRefreshToken, setRole, setUserid }= useContext(AuthContext)
    const [showPassword, setShowPassword] = useState(false);//user can toggle seeing password
    const [showSpinner, setShowSpinner] = useState(false);//new 13th June

    useEffect(() => {
      document.body.style.backgroundColor = "white";
    }, []);

    const handleNameChange = (event)=>{
        setUserName(event.target.value);
    }
    const handlePasswordChange = (event)=>{
        setPassword(event.target.value);
    }

    const userLogin = async(e) =>{
        e.preventDefault();
        setErrorMsg("");
        setShowSpinner(true); //new 13th June
        const response = await fetch('/log/token/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ username: `${username}`, password: `${password}` })
        })
        const data = await response.json()

        if(response.status === 200){            
            const user = jwt_decode(data.access)
            setAuthToken(data)
            setRole(user.r)
            setUserid(user.user_id)
            //localStorage.setItem('authToken', JSON.stringify(data))
            //New code: Set the user reference in local storage
            await getUserRef(data)
            await setRefreshToken(data)
            //End New code
            if(user.r === 'player'){
                navigate('/game/')
            }
            else if(user.r === 'admin') {
                navigate('/admin/')
            } 
            else{
                setErrorMsg("An error occured - unable to login")
                //get image from assests folder - just an extra line
            }               
        }
        else{
            setErrorMsg("*Invalid Login");
            setShowSpinner(false);//new 13th June
        }             
    }

    const getUserRef = async (tokens)=>{
        //get user refrence string and set it local storage
        //authentication is required
        const res = await fetch('/api/userRef/', {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization' : 'Bearer ' + String(tokens.access)
            }
        })
        const d = await res.json() 
        //##### add additional characters to user reference String #####//
        const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ' +
                           '0123456789*+-!$%^?~&'
        const user_r = d.user_ref
        //if d.user_ref is error or unavailable navigate to error page <--> ADD THIS IN
        let s1 = user_r.slice(0,10)
        let s2 = user_r.slice(10 - user_r.length)

        let randomStr = '';
        const charactersLength = characters.length;
        for ( let i = 0; i<5; i++ ) { //5 random characters
            randomStr += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        let en_user_ref = s1 + randomStr + s2
        //end add characters

        localStorage.setItem('qtnxvf', en_user_ref)
    }

    const togglePassword = ()=>{
        setShowPassword(!showPassword);
      }

    return(
    <>
    <Page title='Login'/>
    <LoginNavBar/>

   <Container > {/* Resizeable login */}
      <Row>
      <Col sm={12} md={1} className="d-none d-md-block">  
      
      </Col>
      
      <Col sm={12} md={10}>

        <Row className="p-3 border border-2 border-warning rounded">
        <Col sm={12} md={5} className="m-auto d-none d-md-block">
            <img src={require('../assets/logos/IQlogo.png')} alt= "Login" height="100%" width="100%" fluid/>
          </Col>

         <Col sm={12} md={7} className="m-auto px-3 ">

            <h2 className="mb-3 text-center form-heading"> Sign In </h2>
            
            { ( errorMsg !== "")  ?
                (<p className='text-danger'> { errorMsg } </p>)  :
                (<>
                {(showSpinner)?
                <div className='text-center'>
                    <LoadingSpinner />
                </div>
                
                :
                <></>
                 }
                </>)
                    }

            <Form className='p-1' onSubmit={userLogin}>

            <Form.Group className="mb-4" controlId="formBasicEmail">
                <Form.Label className="form-labels">Email Address:</Form.Label>
                <Form.Control type="email"  onChange={handleNameChange}  required />
            </Form.Group>

            
            <Form.Group className="mb-2" controlId="formBasicPassword">
                <Form.Label className="form-labels">
                    Password: <Link className="px-1"to=""  style={{ color: 'black' }} onClick={() => togglePassword()}>
                            {showPassword ?  ( <FiEyeOff /> )  : ( <FiEye/> )}
                 </Link>
                </Form.Label>
                <Form.Control type={showPassword ? "text" : "password"}   onChange={handlePasswordChange} required />
            </Form.Group>

            <div className="text-center">
            <p > 
              <span className="forgot-pw" > Forgot Your Password?  </span>
              <span className="forgot-pw" > 
                <Link to={`/reset/`} style={{  color: 'black' }}>
                    Click Here</Link> 
            </span>
              
              
              
              
              </p>
              
              <Button variant="primary" type="submit" >
                Login
                </Button>
                <Button variant="primary" type="submit" className='mx-2' onClick={() => navigate("/begin/")}>
                Cancel
                </Button>
            </div>      
          </Form>
         </Col>

        </Row>
      </Col>

      <Col sm={12} md={1} className="d-none d-md-block">  
      
      </Col>

       </Row> 
    </Container>
    <Footer />
    </>
    );
}

export default Login;