import { useNavigate, useOutletContext, Link } from 'react-router-dom';
import {useState, useEffect} from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { FiEyeOff, FiEye } from "react-icons/fi";

const CreateNewAdmin = ()=>{
    const navigate = useNavigate();

    const [details,setDetails]=useState({
        fName:'',
        lName:'',
        email1:'',
        p1:''
    });

    const [show, setShow] = useState(false); 
    const handleClose = () => {
      setShow(false);      
    }
    const handleShow = () => setShow(true);

    const [show1, setShow1] = useState(false); 
    const handleClose1 = () => {
      setShow1(false);      
    }
    const handleShow1 = () => setShow1(true);

    const [showPassword, setShowPassword] = useState(false);//user can toggle seeing password

    const togglePassword = ()=>{
        setShowPassword(!showPassword);
      }

    function changeDetails(e){
        setDetails({...details,[e.target.name]:e.target.value});
    }

    const checkEmail = async () => {
        const response = await fetch('/api/playerEmailAvailable/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email: `${details.email1}` })
        });
        const data = await response.json();
        return data.message;
    }

    const createAdminAccout  = async (e)=>{
        e.preventDefault();
        
        const available = await checkEmail();
        if(available === "YES"){
            const response = await fetch('/api/createAdmin/', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    first_name: `${details.fName}`,
                    last_name: `${details.lName}`,
                    email: `${details.email1}`,
                    password: `${details.p1}`,
                 })
            });
            const data = await response.json();
            if(data.message === 'CREATED'){
                handleShow();
            }
            else{
                alert("Error creating Admin Account");
            }
        }
        else{
            handleShow1();
        }
        
    }


    return (<>
    <Row >
        <Col md={12} lg={2}>
        </Col>
        <Col md={12} lg={8}>
        <Row className="p-1 border border-2 border-secondary rounded mb-5"> 
            <Row classname = "my-1">
            <h2 className="my-3 text-center form-heading"> Create New Admin Account  </h2>
            <p className="text-danger fs-6 "> * All Fields Required </p>

            <Form className='p-3' onSubmit={createAdminAccout}>
            
            <Form.Group className="mb-3" >
                <Form.Label className='roboto-font fs-5 ttsec-blue'>First Name</Form.Label>
                <Form.Control type="text"  name = 'fName' value={details.fName} onChange={changeDetails} required/>
            </Form.Group>

            <Form.Group className="mb-3" >
                <Form.Label className='roboto-font fs-5 ttsec-blue'>Last Name</Form.Label>
                <Form.Control type="text" value={details.lName}  name = 'lName' onChange={changeDetails} required/>
            </Form.Group>

            <Form.Group className="mb-3" >
                <Form.Label className='roboto-font fs-5 ttsec-blue'>Email</Form.Label>
                <Form.Control type="email"  value={details.email1}  name = 'email1' onChange={changeDetails} required/>
            </Form.Group>

            <Form.Group className="mb-3" >
                <Form.Label className='roboto-font fs-5 ttsec-blue'>Password
                <Link className="px-1"to=""  style={{ color: 'black' }} onClick={() => togglePassword()}>
                            {showPassword ?  ( <FiEyeOff /> )  : ( <FiEye/> )}
                 </Link></Form.Label>
                <Form.Control type={showPassword ? "text" : "password"}  value={details.p1}  name = 'p1' onChange={changeDetails} required/>
            </Form.Group>

            

            <div className="text-center">
            
            <Button variant="primary" type="submit" >
              Create Account
            </Button>

            <Button variant="primary" className='mx-2'  onClick={() => navigate("/admin/")}>
              Cancel 
            </Button>

          </div> 

        </Form>
            </Row>
        </Row>
        </Col>

        <Col  md={12} lg={2}>
        </Col>
    
    </Row>

    <Modal show={show} onHide={handleClose} backdrop='static' keyboard={false} centered>  
        <Modal.Header closeButton className='bg-dark py-1'>
            <Modal.Title className='text-light fs-3 text-center  '> 
              Creating New Admin User
            </Modal.Title>
          </Modal.Header>      
        <Modal.Body className='bg-light'> 
            <p className='fs-4 '> User Successfully Created.</p> 
                    
        </Modal.Body>
        <Modal.Footer className='bg-dark py-1'>
            <Button variant="warning" onClick={() => navigate('/admin/viewAdminUsers/')}> Done </Button> 
        </Modal.Footer>
    </Modal>

    <Modal show={show1} onHide={handleClose1} centered>  
        <Modal.Header closeButton className='bg-dark py-1'>
            <Modal.Title className='text-light fs-3 text-center  '> 
              Creating New Admin User
            </Modal.Title>
          </Modal.Header>      
        <Modal.Body className='bg-light'> 
            <p className='fs-4 '> This email is already registered with Investor Quest.</p> 
                    
        </Modal.Body>
        <Modal.Footer className='bg-dark py-1'>
            <Button variant="warning" onClick={handleClose1}> Continue </Button> 
        </Modal.Footer>
    </Modal>
    
    </>);

}

export default CreateNewAdmin;