import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useParams, useNavigate } from 'react-router-dom';
import { useState, useEffect } from "react";
import LoadingSpinner from '../Components/LoadingSpinner';
import StockHeader from './StockHeader';
import StockMFgraph from './StockMFgraph';
import { FaLongArrowAltDown, FaLongArrowAltUp, FaMinus } from "react-icons/fa";
import Table from 'react-bootstrap/Table';
import Page from "../Components/Page";

const StockDetails= ()=>{

    const [stockData, setStockData] = useState({});
    const [loaded, setLoaded] = useState(false);
    const [percent, setPercent] = useState();
    const [change, setChange] = useState();
    const [value, setValue] = useState();
    
    var CurrencyFormat = require('react-currency-format');

    const{stockId} = useParams();

    useEffect(() => {
        getStockDetails();
      }, []);

      useEffect(() => {
        percentageChange();
      }, [loaded]);

    const getStockDetails = async ()=>{
        const response = await fetch(`/gameapi/getStock/${stockId}/`, { 
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
    });
        const data = await response.json();
        if(data.length !== 0){
            setStockData(data);            
            setLoaded(true);
        }        
    }

    const percentageChange = ()=>{
        var c = stockData.current_value-stockData.previous_value;
        setChange((Math.round(c* 100) / 100).toFixed(2));

        var v =parseFloat((Math.round(stockData.value* 100) / 100).toFixed(2))
        v = v.toLocaleString("en-US");
        setValue(v);

        var p = ((stockData.current_value-stockData.previous_value) 
        /stockData.previous_value) * 100;
        setPercent((Math.round(p* 100) / 100).toFixed(3));
    }

    const navigate = useNavigate();
    return (<>

    {(! loaded) ?
        <>   <LoadingSpinner />     </>
        :
        <>
        <Page title={`${stockData.registered_entity} - Stock Details`}/>
        <Row className='border border-2 border-dark mb-3'>
         <StockHeader name={stockData.registered_entity} image={stockData.image}
                         tagline= {stockData.tagline} />

        <Row className='my-2'>
            <Col className='my-1 stock-desc'>
                 
            <div dangerouslySetInnerHTML={{ __html: `${stockData.description}`  }} />   
            
            
            
            </Col>
        </Row>

        <Row>
            
            <Col sm={12} md={9} className="m-auto px-3">
            <h2 className='stock-heading mb-3'>Details ({stockData.ticker}):</h2>
            <Table responsive="sm" className='my-3 mx-5 roboto-font' >        
            <tbody className='stock-details-table'>
                <tr>
                    <td>Security Type: </td>
                    <td> 
                        {(stockData.type === "STOCK") ?
                        <>Stock</>
                        :
                        <>Mutual Fund</>
                        }
                    </td>
                </tr>
                <tr>
                    <td> Industry & Sector: </td>
                    <td className='currency fs-4 '> {stockData.industry}, {stockData.sector} </td>
                </tr>
                <tr>
                    <td> Today's Value: </td>
                    <td className='currency fs-4 text-primary'> ${stockData.current_value}</td>
                </tr>
                <tr>
                    <td> Yesterday's Value: </td>
                    <td className='currency fs-4'> ${stockData.previous_value}</td>
                </tr>

                <tr>
                    <td> Change: </td>
                    <td> 
                        {(change < 0) ?
                        <>
                        <span className= 'text-end text-danger currency fs-4'>                        
                        - <CurrencyFormat value={Math.abs(parseFloat(change))}  
                            fixedDecimalScale={2} decimalScale={2} displayType={'text'} 
                            thousandSeparator={true} prefix={'$'} 
                        />
                        </span>
                        
                        </>
                        :
                        
                          
                            <span className= 'text-end text-success currency fs-4'>                        
                         + <CurrencyFormat value={parseFloat(change)}  
                            fixedDecimalScale={2} decimalScale={2} displayType={'text'} 
                            thousandSeparator={true} prefix={'$'} 
                        />
                        </span>
                            
                           
                            }
                       
                </td>
                </tr>
                <tr>
                    <td> Percentage Change: </td>
                    <td> 
                        {(percent > 0) ?
                    <> 
                        <span className='text-success'> {percent} %
                        <FaLongArrowAltUp size={25} style={{ fill: 'green' }} /> </span>
                    </>:
                    <> 
                        {(percent < 0) ?
                            <> 
                            <span className='text-danger'>{percent} %
                            <FaLongArrowAltDown size={25} style={{ fill: 'red' }} /></span>
                            </>
                            :
                            <> 
                            <span className='text-dark'>{percent} %
                            <FaMinus size={25} style={{ fill: 'black' }} /></span>
                            </>

                        }
                       
                    </>
                }
                </td>

                </tr>

                <tr>
                    <td> Volume: </td>
                    <td> {stockData.volume}</td>
                </tr>
                <tr>
                    <td> Value: </td>
                    <td className='currency fs-4'> ${value}</td>
                </tr>
            </tbody>
            </Table>
                
                
            </Col>
            <Col sm={12} md={3} className="m-auto d-none d-md-block">
            </Col>
        </Row>

        <Row>
            <Col className='my-3'>
                <h2 className='stock-heading my-3' > Stock Price History: </h2>
                <StockMFgraph id = {stockId} color={stockData.color}/>
            </Col>
        </Row>     
        </Row>   
    </>
    }
     
   
    </>);
}

export default StockDetails;