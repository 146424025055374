import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { useNavigate } from 'react-router-dom'
import AuthContext from '../Components/AuthContext';
import { useState, useContext, useEffect } from 'react';
import Footer from '../Components/Footer';
import { BsChevronDoubleDown, BsChevronDoubleUp, BsChevronDoubleRight } from "react-icons/bs";
import { FaLongArrowAltDown, FaLongArrowAltUp} from "react-icons/fa";
import startJourney from '../assets/audio/start-your-journey.mp3';
import Logout from '../Components/Logout';
import Page from "../Components/Page";

const HomeDetails = () =>{
    const navigate = useNavigate();
    const [loginL, setLoginL] = useState();
    const [show, setShow] = useState(false);
    const { role }= useContext(AuthContext);

    const audio = new Audio(startJourney);

    const [showMoreSE, setShowMoreSE] = useState(false);
    const [buttonLabelSE, setButtonLabelSE] = useState(1);

    useEffect(() => {
      if(showMoreSE)
          setButtonLabelSE(0);
      else
          setButtonLabelSE(1);
    }, [showMoreSE]);

    useEffect(() => {
        document.body.style.backgroundColor = "white";
        audio.play();
      }, []);

    const showDetailsSE = ()=>{
      setShowMoreSE(!showMoreSE);        
    }

    useEffect(()=> {
        loginLink();
      }, [role]);

      

    const loginLink = ()=>{
        if(role === 'player'){
            setLoginL('/game');
        }
        else if(role === 'admin'){
            setLoginL('/admin');
        }
        else{
            setLoginL('/login');
        }
    }



    return (
    <>
    <Page title='About Inve$tor Quest'/>
<Navbar bg="dark" variant="dark" expand="sm" className="px-5 mb-3">      
      <Navbar.Brand > 
       <span className="inv-color roboto-font">Inve$tor</span> <span className="quest-color roboto-font">Quest TT 2.0</span> 
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="container-fluid ">       

          <Nav.Item className="ms-auto px-1">
          <span className="text-info"> { (role === null) ? "Login or Sign Up to get started." 
          : "Welcome Back"  }  </span>
         
           

            <Button variant="warning" size="sm" className="text-right mx-3" onClick={() => navigate(`${loginL}`)}>
                    { (role === null) ? "Login" : "Continue"  }
             </Button>

             { (role === null) ?
                    <Button variant="warning" size="sm" className="text-right" onClick={() => navigate("/register/")} > Sign Up </Button> :
                    <Logout />
                    }
         
    </Nav.Item>            
            

        
        </Nav>
      </Navbar.Collapse>
    
  </Navbar>



    <Container fliud >
      <Row className='mt-4'>
      <Col sm={12} md={1} className="d-none d-md-block"> </Col>
      <Col sm={12} md={10} >  {/* Main section */}

        <Row className="align-items-center homepage-header-bg border border-1 border-secondary "> {/* header row*/}
          <Col xs={12} sm={4} className = " text-center  my-3">
            
          <Image  src={require('../assets/logos/IQlogo.png')}   width="70%" height="auto" />
          </Col>
          <Col xs={12} sm={8}>
          <p className='fs-1 roboto-font text-light text-center my-2'> Welcome to Inve$tor Quest TT 2.0
          <div className=" fs-4 roboto-font "> A Stock Market Simulation Game Designed in Trinidad and Tobago   </div> 
            </p>
          </Col>
      </Row> {/* close header row*/}

      <Row className='border border-2 border-dark mb-5'> {/* page body row*/}

      <Row> {/* section 1 */}
      <Col className='mx-3' >
      <p className='fs-1 roboto-font inv-color text-center my-2 fw-bold'> Inve$tor             
        <span className="quest-color "> Quest TT 2.0 </span> 
      </p>

      <div className='justify-text currency fs-5 justify-text'>
      This investing game provides users with a practical understanding and exposure to 
      buying and selling (trading) on a stock market. Investor Quest TT 2.0 utilises a 
      fictitious virtual stock market that mimics what actually occurs on any 
      real-world stock market. It allows players  to participate in the on-going game 
      (Investor Quest) as well as join and play additional mini-quests that are generated.

      <p className='ttsec-blue'> 
     Players must manage their investment portfolios comprising a combination of  stocks, 
     bonds and mutual funds and discover how their investments can result in capital losses 
     or gains based on the risks or decisions taken during game play.
      </p>
      </div>

      
      </Col>
      </Row> {/* end section 1 */}

      <Row className='my-3'> {/* getting started section */}
      <Col className='mx-3'>
        <h2 className='mt-5 mb-3 homepage-heading2 text-light homepage-separator-bg px-3 py-1'>How To Play 
         <span className='fs-4 text-warning'> (Main Steps)</span></h2>
        <div className='fs-5 currency mb-3 justify-text'>

        <BsChevronDoubleRight size={20} style={{ fill: '#ffc107' }} />  
         <span className='text-primary roboto-font'>STEP 1: Sign Up & Login </span> 
         <p>
         You will be required to Sign Up to set up your very own IQTT 2.0 account with your 
         personalised username (Your username only would be seen by other players in the game). 
         </p>

         <BsChevronDoubleRight size={20} style={{ fill: '#ffc107' }} />  
         <span className='text-primary roboto-font'>STEP 2: Select Your Broker-Dealer </span> 
         <p>
         This is done only once when you log in for the first time. 
         The Broker-Dealer you select would be used for all transactions throughout the game.
         </p>

         <BsChevronDoubleRight size={20} style={{ fill: '#ffc107' }} />  
         <span className='text-primary roboto-font'>STEP 3: Start Trading  </span> 
         <p>
         Start by building your portfolio in the main Investor Quest game  - make smart decisions as
         you trade in the securities listed . Each player is given $25,000.00
         (virtual currency ) and can earn an additional $2000.00 by answering the bonus
         question.
         </p>

        </div>
        

      </Col> 
      </Row>  {/* end getting started section */}

      <Row> {/* twin island hub section */}
      <Col className='mx-3'>
      <h2 className='mt-5 mb-3 homepage-heading2 text-light homepage-separator-bg px-3 py-1'> 
      <span className='homepage-twinIsland-heading'> Twin Island Stock Exchange  </span>
      (<span className="inv-color roboto-font fs-4">Inve$tor</span> <span className="quest-color roboto-font fs-4"> Quest TT 2.0</span> Simulated Stock Exchange) </h2>
      
      <div className='fs-5 currency mb-3 justify-text'>
      This simulated stock exchange comprises fictitious companies registered to trade in
      Investor Quest TT 2.0. Players can buy or sell the securities listed to grow their
      portfolio. Share prices in the Twin Island Stock Exchange are quoted in $ which
      represents Investor Quest TT 2.0 virtual currency and has the same exchange rate as TT dollars.
      </div>

      <h4 className='mt-4 mb-3 currency text-success'>Twin Island Stock Exchange Sample Stock Listings:</h4>
      
      <div className='px-5 mb-3'>
      <Table responsive="md"  striped> {/* 24 May */}
        <thead className='text-secondary'>
          <tr>
            <th></th>
            <th>Ticker</th>
            <th className='text-center'>Name</th>
            <th className=' text-end'>Today's Value</th>
            <th className=' text-end'>Yesterday Close</th>
            <th className=' text-end'>% Change</th>
            <th></th>
          </tr>
        </thead>
        <tbody>

          <tr>
            <td>
              <Image  src={require('../assets/logos/Buccoo.png')}  rounded width="50px" height="auto" />
            </td>
            <td>BRW </td> 
            <td className='fw-bold'>Buccoo Reef Waters</td>
            <td className='currency text-end'>$287.10</td>
            <td className='currency text-end'>$286.05</td>
            <td className='text-success text-end'>0.4 %</td>
            <td><FaLongArrowAltUp size={25} style={{ fill: 'green' }} /></td>
          </tr>

          <tr >
            <td>
              <Image  src={require('../assets/logos/Hemp.png')}  rounded width="50px" height="auto" />
            </td>
            <td>HER </td> 
            <td className='fw-bold'>Hemp Revolution Inc.</td>
            <td className='currency text-end'>$42.57</td>
            <td className='currency text-end'>$43.56</td>
            <td className='text-danger text-end'>-2.3 %</td>
            <td><FaLongArrowAltDown size={25} style={{ fill: 'red' }} /></td>
          </tr>

          <tr>
            <td>
              <Image  src={require('../assets/logos/Tabanca.png')}  rounded width="50px" height="auto" />
            </td>
            <td>TSI </td> 
            <td className='fw-bold'>Tabanca Spirits</td>
            <td className='currency text-end'>$24.62</td>
            <td className='currency text-end'>$23.46</td>
            <td className='text-success text-end'>4.9 %</td>
            <td><FaLongArrowAltUp size={25} style={{ fill: 'green' }} /></td>
          </tr>
        </tbody>
        </Table>
        <div className='text-center fs-6 text-primary currency'>
          <i>Sample Data: This does not reflect the current stock prices </i>
        </div>

      </div> {/* end table div */}

      { (showMoreSE) ?
      <div className='my-4 currency'>
        <h4 className='my-3 text-success'> In our Twin Island Stock Exchange you can trade in: </h4>
        <ul className='roboto-font my-2'>
          <li className='my-2'> <span className='fw-bold'> STOCKS </span> - Fifteen (15) Registered Companies.</li>
          <li className='my-2'> <span className='fw-bold'> MUTUAL FUNDS </span> - Five (5) Registered Companies</li>
          <li className='my-2'> <span className='fw-bold'> BONDS </span> - Five (5) Government and Corporate Bonds.</li>
        </ul>

        <span className='roboto-font mt-4 mb-2'>
        Look out for <span className='fw-bold'> News Alerts </span> which give information about activities in
        the Investor Quest World. These activities may  lead to price fluctuations in the
        Twin Island Stock Exchange, so be a wise investor and make the right decisions when
        trading. Remember, all investments include some level of risk.
        </span>
      </div>
      :
      <></>
        
      }

    <Row className='text-end mb-4'>

    <div>
      { (showMoreSE) ?
        <Button variant='outline-primary'  onClick={showDetailsSE} >
        <BsChevronDoubleUp /> See Less
        </Button>
        :
        <>
        <Button variant='outline-primary'  onClick={showDetailsSE}>
        <BsChevronDoubleDown  />  See More
        </Button>
        </>
          
        }
    </div>

    </Row> 
    </Col>
    </Row> {/* end twin island hub section */}

    <Row className='mb-4'> {/* Partnership section */}
        <Col className='mx-3'>
        <h2 className='mt-5 mb-3 homepage-heading2 text-light homepage-separator-bg px-3 py-1'>Partnerships
         </h2>
         <div className='fs-5 currency mb-3 justify-text'>
          <p>
          <span className='fs-5 roboto-font inv-color text-center my-2 fw-bold'> Inve$tor             
          <span className="quest-color "> Quest TT </span> 
      </span>
          was first developed by the <span className='roboto-font ttsec-blue'> Trinidad and Tobago Securities and Exchange 
          Commission</span> in 2019 following consultation with the 
          <span className='fw-bold'> Securities Dealers Association of 
          Trinidad and Tobago (SDATT)</span>  and the 
          <span className='fw-bold'> Trinidad and Tobago Stock Exchange Limited (TTSE).
          </span>
          </p>
          <p>
          In 2022/23, the game underwent a complete upgrade with improvements to the entire 
          application - bringing it in line with modern platforms of its kind.  
          This was achieved through a partnership with the 
          <span className='fw-bold'> University of Trinidad and Tobago (UTT) </span> 
          to develop a version 2.0 of the game.

          </p>
          <p>We hope you have fun and enjoy trading in our virtual investing game!</p>
         </div>
        </Col>
    </Row> {/* End partnership section */}
      

      </Row> {/* end page body row*/}


        </Col> {/* End Main section */}
        <Col sm={12} md={1} className="d-none d-md-block"> </Col>
      </Row>


      
    </Container>
    <Footer />
    </>
    );
}

export default HomeDetails;
