import { useNavigate } from 'react-router-dom';
import {useState, useEffect} from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { DateTime } from "luxon";

const CreateNewQuest = ()=>{
    const navigate = useNavigate();

    const [today, setToday] = useState();
    const [longStartDate, setLongStartDate] = useState('');
    const [showStartDate, setShowStartDate] = useState(false);
    const [longEndDate, setLongEndDate] = useState('');
    const [showEndDate, setShowEndDate] = useState(false);
    const [longRegistrationEndDate, setLongRegistrationEndDate] = useState('');
    const [showRegistrationEndDate, setShowRegistrationEndDate] = useState(false);
    const [minEndDate, setMinEndDate] = useState();
    const [questId, setQuestId] = useState();
    const [show, setShow] = useState(false);  
    

    const handleClose = () => {
      setShow(false);      
    }
    const handleShow = () => setShow(true);

    

    const [details,setDetails]=useState({
        name:'',
        start_date:'',
        registration_end_date: null,
        end_date:'',
        desc:'',
        start_value:'',
        age_res:'',
        applicable_age_groups:'',
        bonus_ques:'',
        a:'',
        b:'',
        c:'',
        d:'',
        answer:'A',
        bonus_amt:''
    });

    useEffect(() => {
        var t = new Date(); 
        var dd = t.getDate();
        var mm = t.getMonth() + 1;
        var yyyy = t.getFullYear();
        if (mm.toString().length === 1)
            mm = '0' + mm;
        if (dd.toString().length === 1)
            dd = '0' + dd;
        var dateT = yyyy + "-" + mm + "-" + dd;
        setToday(dateT);
        setMinEndDate(dateT);

    }, []);

    useEffect(() => {
        if(showStartDate){
            setLongStartDate(DateTime.fromISO(details.start_date).toLocaleString(DateTime.DATE_HUGE));
        }
        if(showEndDate){
            setLongEndDate(DateTime.fromISO(details.end_date).toLocaleString(DateTime.DATE_HUGE));
        }
        if(showRegistrationEndDate){
            setLongRegistrationEndDate(DateTime.fromISO(details.registration_end_date).toLocaleString(DateTime.DATE_HUGE));
        }

    }, [details.start_date, details.end_date, details.registration_end_date]);

    useEffect(() => {
        if(details.start_date !== '')
            setMinEndDate(details.start_date)
    }, [details.start_date]);


    function changeDetails(e){
        setDetails({...details,[e.target.name]:e.target.value});  
        if(e.target.name === 'start_date'){
            setShowStartDate(true);
        }
        if(e.target.name === 'end_date'){
            setShowEndDate(true);
        }
        if(e.target.name === 'registration_end_date'){
            setShowRegistrationEndDate(true);
        }

    }

    const [selectedApplicableAgeGroups, setSelectedApplicableAgeGroups] = useState([]);
    function applicableAgeGroupsChanged(e, ageGroupId){
        let _selectedApplicableAgeGroups = selectedApplicableAgeGroups;
        if(e.target.checked === true){
            _selectedApplicableAgeGroups.push(ageGroupId);
        } else {
            _selectedApplicableAgeGroups = _selectedApplicableAgeGroups.filter(saag => saag !== ageGroupId)
        }
        setSelectedApplicableAgeGroups(_selectedApplicableAgeGroups);
        setDetails({...details, applicable_age_groups:_selectedApplicableAgeGroups.join(',')});
    }

   
    const addQuest =  async (e) =>{
      e.preventDefault();    
          const response = await fetch('/gameapi/addNewQuest/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          name: `${details.name}`, 
          start_date: `${details.start_date}`,
          registration_end_date: details.registration_end_date ? details.registration_end_date : null,
          end_date: `${details.end_date}`,
          description: `${details.desc}`,
          start_value: `${details.start_value}`,
          applicable_age_groups: `${details.applicable_age_groups}`,
          bonus_question: `${details.bonus_ques}`,
          choice_A: `${details.a}`,
          choice_B: `${details.b}`,
          choice_C: `${details.c}`,
          choice_D: `${details.d}`,
          answer: `${details.answer}`,
          bonus_amount: `${details.bonus_amt}`,
         })
    });
    const data = await response.json();
    if(data.message === "ERROR_ADDING_SHORT_QUEST"){
        alert('Something went wrong! Try creating the quest again.');
    }
    else{
      setQuestId(data.message);
      handleShow();
    }
  }
 


    return (
        <>
           
        <Row >
        <Col md={12} lg={2}>
        </Col>
        <Col md={12} lg={8}>
        <Row className="p-1 border border-2 border-primary rounded mb-5"> 
            <Row classname = "my-1">
            <h2 className="my-3 text-center form-heading"> Create A New Quest (Step 1) </h2>
            <p className="text-danger fs-6 "> * All Fields Required </p>

            <Form className=' mb-3' onSubmit={addQuest} > 

            <Form.Group className="mb-1" as={Row}>
              <Form.Label className="admin-form-labels fs-6" column sm={3}>
                Quest Name 
              </Form.Label>
              <Col sm={9}>
              <Form.Control className="mb-3" type="text" maxlength= "200" name = 'name' value={details.name} onChange={changeDetails} required />
            </Col>
              
              </Form.Group>

            <Form.Group className="mb-3" as={Row}>
                <Form.Label className="admin-form-labels fs-6" column sm={3}>
                    Start Date
                </Form.Label>
                <Col sm={9}>
                    <Form.Control type="Date" value={details.start_date} name='start_date' onChange={changeDetails}
                                  min={today} required/>
                    <Form.Text className="text-dark">
                        {longStartDate}
                    </Form.Text>
                </Col>
            </Form.Group>

          <Form.Group className="mb-3" as={Row}>
              <Form.Label className="admin-form-labels fs-6" column sm={3}>
                End Date 
              </Form.Label>
              <Col sm={9}>
              <Form.Control type="Date"  value={details.end_date} name = 'end_date' onChange={changeDetails}  min={minEndDate} required />
              <Form.Text className="text-dark">
                  {longEndDate}
              </Form.Text> 
              </Col>
          </Form.Group>

            <Form.Group className="mb-3" as={Row}>
                <Form.Label className="admin-form-labels fs-6" column sm={3}>
                    Registration End Date
                </Form.Label>
                <Col sm={9}>
                    <Form.Control type="Date" value={details.registration_end_date} name='registration_end_date' onChange={changeDetails}
                                  min={today}/>
                    <Form.Text className="text-dark">
                        {longRegistrationEndDate}
                    </Form.Text>
                </Col>
            </Form.Group>

          <Form.Group className="mb-3" as={Row}>
                <Form.Label className="admin-form-labels fs-6" column sm={3}>
                  Description  
                </Form.Label>
                <Col sm={9}>
                <Form.Control as="textarea" rows={5}  value={details.desc}  name = 'desc' onChange={changeDetails} required/>
                </Col>
          </Form.Group>

          <Form.Group className="mb-1" as={Row}>
              <Form.Label className="admin-form-labels fs-6" column sm={3}>
                Start Value
              </Form.Label>
              <Col sm={9}>
              <Form.Control className="mb-3" type="number" min='1' name = 'start_value' value={details.start_value} onChange={changeDetails} required />
              </Col>
              </Form.Group>

          <Form.Group className="mb-1" as={Row}>
            <Form.Label className="admin-form-labels fs-6" column sm={3}>
                Age Restriction
            </Form.Label>
            <Col sm={9}>

                <Row className='bg-white'>
                    <Col sm={6}>
                        <Form.Check type='checkbox' id='age-group1' label="13 - 17"
                                    onChange={e => applicableAgeGroupsChanged(e, 1)}/>
                    </Col>
                    <Col sm={6}>
                        <Form.Check type='checkbox' id='age-group2' label="18 - 35"
                                    onChange={e => applicableAgeGroupsChanged(e, 2)}/>
                    </Col>
                    <Col sm={6}>
                        <Form.Check type='checkbox' id='age-group3' label="36 - 45"
                                    onChange={e => applicableAgeGroupsChanged(e, 3)}/>
                    </Col>
                    <Col sm={6}>
                        <Form.Check type='checkbox' id='age-group4' label="46 - 55"
                                    onChange={e => applicableAgeGroupsChanged(e, 4)}/>
                    </Col>
                    <Col sm={6}>
                        <Form.Check type='checkbox' id='age-group5' label="56+"
                                    onChange={e => applicableAgeGroupsChanged(e, 5)}/>
                    </Col>
                </Row>
                <Alert variant='info'>
                    You can leave all items unchecked to apply to all age groups
                </Alert>
            </Col>
        </Form.Group>

              <Form.Group className="mb-3" as={Row}>
                <Form.Label className="admin-form-labels fs-6" column sm={3}>
                  Bonus Question
                </Form.Label>
                <Col sm={9}>
                <Form.Control as="textarea" rows={3}  value={details.bonus_ques}  name = 'bonus_ques' onChange={changeDetails} required/>
                </Col>
            </Form.Group>

            <Form.Group className="mb-3" as={Row}>
              <Form.Label className="admin-form-labels fs-6" column sm={3}>
                Answer A </Form.Label>
              <Col sm={9}>
              <Form.Control type="text" maxlength= "200" value={details.a} name = 'a' onChange={changeDetails}   required />
            </Col>
            </Form.Group>

            <Form.Group className="mb-3" as={Row}>
              <Form.Label className="admin-form-labels fs-6" column sm={3}>
                Answer B
              </Form.Label>
              <Col sm={9}>
              <Form.Control type="text" maxlength= "200" value={details.b} name = 'b' onChange={changeDetails}   required />
            </Col>
            </Form.Group>

            <Form.Group className="mb-3" as={Row}>
              <Form.Label className="admin-form-labels fs-6" column sm={3}>
                Answer C 
              </Form.Label>
              <Col sm={9}>
              <Form.Control type="text" maxlength= "200" value={details.c} name = 'c' onChange={changeDetails}   required />
            </Col>
            </Form.Group>

            <Form.Group className="mb-3" as={Row}>
              <Form.Label className="admin-form-labels fs-6" column sm={3}>
                Answer D 
              </Form.Label>
              <Col sm={9}>
              <Form.Control type="text" maxlength= "200" value={details.d} name = 'd' onChange={changeDetails}   required />
            </Col>
            </Form.Group>

            <Form.Group className="mb-1"as={Row} >
            <Form.Label className="admin-form-labels fs-6" column sm={3}>
                Correct Answer
            </Form.Label>
            <Col sm={9}>
            <Form.Select className="mb-3"  value={details.answer} name = 'answer'  onChange={changeDetails} required>
            <option value="A"> Answer A </option>              
            <option value="B"> Answer B </option>
            <option value="C"> Answer C </option>
            <option value="D"> Answer D </option>                               
            </Form.Select>
            </Col>
        </Form.Group>

        <Form.Group className="mb-1" as={Row}>
              <Form.Label className="admin-form-labels fs-6" column sm={3}>
                Bonus Amount
              </Form.Label>
              <Col sm={9}>
              <Form.Control className="mb-3" type="number" min='1' name = 'bonus_amt' value={details.bonus_amt} onChange={changeDetails} required />
              </Col>
              </Form.Group>

              <div className="text-center">
            
            <Button variant="primary" type="submit" >
              Create Quest
            </Button>

            <Button variant="primary" className='mx-2'  onClick={() => navigate("/admin/")}>
              Cancel 
            </Button>

          </div> 
             </Form>

            </Row>
        </Row>
        </Col>

        <Col  md={12} lg={2}>
        </Col>
    
    </Row>

    
      <Modal show={show} onHide={handleClose} backdrop='static' centered>  
        <Modal.Header closeButton className='bg-dark py-1'>
            <Modal.Title className='text-light fs-3 text-center  '> 
              Creating New Quest
            </Modal.Title>
          </Modal.Header>      
        <Modal.Body className='bg-light'> 
            <p className='fs-4 '> Quest Successfully Created.</p> 
                    
        </Modal.Body>
        <Modal.Footer className='bg-dark py-1'>
            <Button variant="warning" onClick={() => navigate(`/admin/addStocks/${questId}`)}> Continue </Button> 
        </Modal.Footer>
    </Modal>
    </>
    );
}

export default CreateNewQuest;