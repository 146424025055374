import Table from 'react-bootstrap/Table';
import { DateTime } from "luxon";
import Image from 'react-bootstrap/Image';

const HistoryTable = ({history})=>{
    
    var CurrencyFormat = require('react-currency-format');
    return(<>

<Table responsive='lg'>
            <thead>
                <tr className='text-primary fs-5 currency '>
                    <th>Date </th>
                    <th>Action</th>
                    <th>Company </th>                    
                    <th className='text-center'>Quantity</th>
                    <th className='text-end'>Share Price</th>
                    <th className='text-end'>Total</th>
                </tr>
            </thead>
            <tbody>
            { history.map((h, index)=>(
                <tr key={index} className='fs-6 roboto-font'> 
                <td>
                {DateTime.fromISO(h.date_bought).toLocaleString(DateTime.DATE_MED)} 
                </td>
                {(h.action==='BUY')?
                <td className='text-success'>{h.action}  </td>
                :
                <td>{h.action}  </td>
                }
                
                <td>
                <Image  src={require(`../assets/logos/${h.image}`)}   width="40px" height="auto" /> {h.name} </td>
                <td className='text-center'>{h.quantity} </td>
                <td className='fs-5 currency text-end'>
                <CurrencyFormat value={parseFloat(h.cost)}  
                    fixedDecimalScale={2} decimalScale={2} displayType={'text'} 
                    thousandSeparator={true} prefix={'$'} 
                />
                </td>
                <td className='fs-5 currency text-end'>
                <CurrencyFormat value={parseFloat(h.total)}  
                    fixedDecimalScale={2} decimalScale={2} displayType={'text'} 
                    thousandSeparator={true} prefix={'$'} 
                />
                </td>
            </tr>
        ))}

            </tbody>
        </Table>
    
    </>);
}

export default HistoryTable;