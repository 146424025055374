import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { useParams, useNavigate, Link } from 'react-router-dom';
import Footer from '../Components/Footer';
import LoadingSpinner from '../Components/LoadingSpinner';
import {useState, useEffect } from 'react';
import icon from '../assets/images/favicon.ico';
import Page from "../Components/Page";


const ActivateAccount = ()=>{
    const navigate = useNavigate();
    const [loadPage, setLoadPage] = useState(false);
    const [result, setResult] = useState("");

    const{regId} = useParams(); 
    const{userId} = useParams(); 

    useEffect(() => {
        const favicon = document.getElementById('favicon');
        favicon.setAttribute('href', icon);
      }, []);

      useEffect(() => {
        document.body.style.backgroundColor = "white"
      }, []);

    useEffect(() => { 
        activateAccount();
    },[]);

    const activateAccount = async () =>{
        const response = await fetch('/api/activateAccount/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                userId: `${userId}`,
                identifier: `${regId}`,
             })
        });
        const data = await response.json();
        if(data.message === "ACCOUNT_ACTIVATED"){
            setResult("success");
        }
        else{
            setResult("fail");
        }
        setLoadPage(true);
    }

    return  (<>
        <Page title='Activate Account'/>
<Container fluid>

<Row className=''>
        <Col className='bg-dark'>
        <Link to={'/'} style={{ textDecoration: 'none' }}> 
        <span className="inv-color roboto-font py-5 fs-4 ps-4">Inve$tor</span> <span className="quest-color roboto-font py-5 fs-4">Quest TT 2.0</span>
        </Link>
        </Col>
    </Row>       
    
           
</Container>


{ (loadPage) ? 
    <> 
    <Container >   

    <Row className='my-5'>
        <Col md={2} sm={12}> </Col>
        <Col md={8} sm={12} className="p-3 "> 
        

        <Row className="align-items-center homepage-header-bg border border-1 border-secondary "> {/* header row*/}
            <Col xs={12} sm={4} className = " text-center  my-3">
                
            <Image  src={require('../assets/logos/IQlogo.png')}   width="70%" height="auto" />
            </Col>
            <Col xs={12} sm={8}>
            <p className='fs-3 roboto-font text-light text-center my-2'> Account Activation
            
                </p>
            </Col>
        </Row> {/* close header row*/}

        <Row className='border border-2 border-dark mb-5'> {/* page body row*/}
        <Col>
            { (result === "success") ?
            <> 
                <p className= 'text-center fs-4 my-4 currency'>Your account has been activated.
                Login in to get started </p>  
           
    

                <p className='text-center my-4 '>
                    <span className="inv-color fs-1 roboto-font"> Happy </span> 
                    <span className="quest-color fs-1 roboto-font"> Trading! </span>
                </p>

                <div className="mx-3  mb-3 text-end">
                <Button variant="warning"  onClick={() => navigate("/login/")}>
                    Login 
                </Button>
                </div>
            </>
        :
        <> 
            <h4 className='my-3 currency fs-4 text-dark text-center'>This account has already been activated,</h4>
            <h4 className='my-3 currency fs-4 text-dark text-center'>or the link has expired.</h4>

            <div className="mx-3  mb-3 text-end" >
                <Button variant="warning"  onClick={() => navigate("/")}>
                Home
            </Button>
            </div>
        </>

    }
     </Col>     
    </Row>
        

        
        
        </Col>
        <Col md={2} sm={12}> </Col>

    </Row>
</Container>
    
    
    
    </>
    :
    <>
    <LoadingSpinner />
    </>
}

<Footer />
    </>);
}

export default ActivateAccount;