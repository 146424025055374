import { useNavigate, useOutletContext } from 'react-router-dom';
import {useState, useEffect} from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from "react-bootstrap/Modal";
import coins from '../assets/audio/coins.mp3';
import congrats from '../assets/audio/congrats.mp3';
import Page from "../Components/Page";

const BuyBonds = ()=>{
    const navigate = useNavigate();
    var CurrencyFormat = require('react-currency-format');
    const [gameData, setGameData] = useOutletContext();
    const [availableCash, setAvailableCash] = useState(0);
    const [bonds, setBonds] = useState([{}]);
    const [buyId, setBuyId] = useState(-1);
    const [buyQty, setBuyQty] = useState(1);
    const [years, setYears] = useState(0);
    const [yearsID, setYearsID] = useState(0);
    const [iRate, setIRate] = useState();
    const [options, setOptions] = useState([{}]);
    const [selectedBond, setSelectedBond] = useState({});
    const [brokerFeeBuy, setBrokerFeeBuy] = useState(0);
    const [exchangeBuy, setExchangeBuy] = useState(0);
    const [feeBuy, setFeeBuy] = useState(0);
    const [cashSpending, setCashSpending] = useState(0);

    const [bondCounter, setBondCounter] = useState(0);
    const [yearCounter, setYearCounter] = useState(0);

    const [costBonds, setCostBonds] = useState(0);

    const [numberCoupons, setNumberCoupons] = useState(0);
    const [couponValue, setCouponValue] = useState(0);
    const [totalPayments, setTotalPayments] = useState(0);

    const audio = new Audio(coins);
    const audio2 = new Audio(congrats);

    const [show1, setShow1] = useState(false);
    const handleClose1 = () => {
        setShow1(false);
        navigate(`/game/quest/${gameData.quest.id}`);
    }    
    const handleShow1 = () => {
        setShow1(true);
        audio.play();
    }

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => {
        setShow2(false);
        navigate(`/game/quest/${gameData.quest.id}`);
    }
    
    const handleShow2 = () => {
        setShow2(true);
        audio2.play();
    }
    
    const [show4, setShow4] = useState(false);
    const handleClose4 = () => setShow4(false);
    const handleShow4 = () => setShow4(true);

    

    useEffect(() => { 
        getAvailableCash();
        loadBonds();
      },[]);

      useEffect(() => { 
        if(bondCounter > 0)
            loadBondYIR();
      },[bondCounter]);

      useEffect(() => { 
        if(yearCounter > 0)
            loadSelectedYear();
      },[yearCounter]);

      useEffect(() => { 
        if(bondCounter > 0)
            calculateCosts();
      },[buyQty, years, bondCounter]);

      const loadBonds = ()=>{        
        gameData.quest.bonds.map((b, index) => {
            setBonds(original => [...original, b])
        });         
    }

    const calculateCosts = ()=>{
        if(years != 0){
            let cost = (parseFloat(selectedBond.cost) * parseInt(buyQty));
            setCostBonds(cost.toFixed(2));
            let bFee = (0.0125 * cost);
            let eFee = (0.0014 * cost);
            let fees = (cost + bFee + eFee);
            setBrokerFeeBuy(bFee.toFixed(2));
            setExchangeBuy(eFee.toFixed(2));
            setFeeBuy(fees.toFixed(2));

            let spend = parseFloat(gameData.cash_value) - (fees + parseFloat(gameData.fees_owed));
            setCashSpending(spend.toFixed(2));

            let nC = parseInt(years) * 2;
            let coupV = (parseFloat(iRate)/2) /100 * cost;
            let totalPay = nC * coupV
            setNumberCoupons(nC);
            setCouponValue(coupV.toFixed(2));
            setTotalPayments(totalPay.toFixed(2));

        }
        else{
            resetValues();
        }
        
    }

    const resetValues = ()=>{
            setNumberCoupons(0);
            setCouponValue(0); 
            setTotalPayments(0);           
            setBrokerFeeBuy(0);
            setExchangeBuy(0);
            setFeeBuy(0);
            setCostBonds(0);
            setCashSpending(0);
    }

    const loadBondYIR = ()=>{  
        let b = findBond(buyId) ; 
        if (b !== undefined) {
        setSelectedBond(b);
        let op = [
                {id: 1, y: b.option1Y, iR: b.option1I}, 
                {id: 2, y: b.option2Y, iR: b.option2I},
                {id: 3, y: b.option3Y, iR: b.option3I}
                ];
        
        setOptions(op);  
        setYears(op[0].y);
        setIRate(op[0].iR);
        }
        else{
            setOptions([]);            
            setYears(0);
            setBuyQty(1); 
            setIRate(0);
            
        }
        
    }

    const clearForm = () =>{
        setOptions([]);            
        setYears(0);
        setBuyQty(1); 
        setIRate(0);
    }

    const loadSelectedYear = ()=>{
        let opt = findYearDetails(yearsID); 
        setYears(opt.y); 
        setIRate(opt.iR);        
    }
    

    const getAvailableCash = ()=>{
        let spend = parseFloat(gameData.cash_value) - parseFloat(gameData.fees_owed);
        setAvailableCash(spend.toFixed(2));
    }


    const findBond = (bid)=>{
        const searchIndex = gameData.quest.bonds.findIndex((b) => b.id == bid);        
        return gameData.quest.bonds[searchIndex];
    }

    const findYearDetails= (bid)=>{
        const searchIndex = options.findIndex((b) => b.id == bid);        
        return options[searchIndex];
    }

    const changeBuyId = (e)=>{
        setBuyId(e.target.value); 
        setBondCounter(bondCounter+1)       
    }

    const changeBuyQty = (e)=>{
        setBuyQty(e.target.value);  
    }

    const changeYears = (e)=>{
        setYearsID(e.target.value);
        setYearCounter(yearCounter+1);
    }

    const a = ()=>{
        alert("Implement BUY Bonds");
    }

    const executeTrade = async () =>{
        const response = await fetch('/gameapi/tradeStatus/', {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        });
        const data = await response.json(); 
        if(data.message === 'YES'){
            const response = await fetch('/gameapi/amountCashAvailable/', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                  qpID: `${gameData.id}`,
                 })
            });
            const data = await response.json();
            let cash_amt = parseFloat(data.cash_avail)
            if (cash_amt < feeBuy){
                alert("Your data is out of sync, unable to complete trade.");
                navigate(`/game/`);
                return;
            }
            
            buyBonds();
        }
        
        else
            handleShow4();

    }

    const buyBonds= async () =>{
        let tFee = parseFloat(gameData.fees_owed) + parseFloat(feeBuy);
        setGameData(original => ({...original, fees_owed: tFee}));
        const response = await fetch('/gameapi/addPendingBondsBuy/', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                qpID: `${gameData.id}`,
                bondId: `${buyId}`,
                quantity: `${buyQty}`,
                costBonds: `${costBonds}`,
                nYears: `${years}`,
                rate: `${iRate}`,
                couponV: `${couponValue}`,
                nCoup: `${numberCoupons}`,
                fees: `${feeBuy}`,
             })
        });
        const data = await response.json(); 
        if(data.message === 'PENDING_BONDS_BUY_ADDED'){
            if(gameData.active === 'NO'){
                handleShow1();
                setGameData(original => ({...original, active: 'YES'}))
            }
            else
                handleShow2();
        }
        else
            alert('Unable to complete transaction, try agina later');        
        
      }

    return(<>
    <Page title='Bonds Trading - Buy'/>
    <Row className='border border-2 border-dark mb-3'>
    <Row className='my-3'> 
        <Col xs={1} ></Col>

        <Col xs={10} >
        <h1 className='my-3 trading-main-header text-center '>Bonds Trading 
        <span className='roboto-font fs-3 inv-color'> (Buy) </span> 
        </h1>
        <Table bordered className='mt-3 mb-5 px-5 bg-dark'>
            <tbody>
            <tr >
                <td className='fs-4 currency text-info'>
                    Available Cash: <CurrencyFormat value={parseFloat(availableCash)}  
                            fixedDecimalScale={2} decimalScale={2} displayType={'text'} 
                            thousandSeparator={true} prefix={'$'} 
                        />
                    
                    <span className='fs-5 text-light'> (Cash Value - Pending Fees) </span></td>
            </tr>
            </tbody>            
        </Table>

        <h3 className='my-3 trade-heading1'>Add Bond Details: </h3>

        <Form className=' mb-3 py-1' > 

        <Row className='px-1'>
              <Col md={12} lg={6}>   
              <Form.Group className="mb-1" >
                <Form.Label className="form-labels fs-5">
                  Company:
                </Form.Label>
                <Form.Select className="mb-3"  value={buyId}   onChange={changeBuyId} required>
                { bonds.map((b, index)=>(                            
                    <option key={b.id} value={b.id}> {b.registered_entity}  </option>
                ))}
            
                </Form.Select>
            </Form.Group>
            </Col>  
            <Col md={12} lg={6}>
            <Form.Group className="mb-1" >
              <Form.Label className="form-labels fs-5">
                Quantity: 
              </Form.Label>
              <Form.Control type="number" min='1' value={buyQty} onChange={changeBuyQty}  required/>
            </Form.Group>
            </Col>
        </Row>

        <Row className='px-1'>
              <Col md={12} lg={6}>   
              <Form.Group className="mb-1" >
                <Form.Label className="form-labels fs-5">
                  Number of Years:
                </Form.Label>
                <Form.Select className="mb-3"  value={yearsID}   onChange={changeYears} required>
                { options.map((opt, index)=>(                            
                    <option key={index} value={opt.id}> {opt.y} </option>
                ))}
            
                </Form.Select>
            </Form.Group>
            </Col>  
            <Col md={12} lg={6}>
            <Form.Group className="mb-1" >
              <Form.Label className="form-labels fs-5 ">
                Note
              </Form.Label>
              <Row>
              <Form.Text className="text-dark">
                  <span className='fw-bold text-danger'> FOR BONDS ONLY: </span> 1 year is equivalent to 1 month in Investor Quest. If you buy 
                  bonds for 5 years it would mature after 5 months.
              </Form.Text> 
              </Row>
              
            </Form.Group>
            </Col>
        </Row>

        </Form>

        </Col>
        <Col xs={1} ></Col>
        </Row>

        <Row className='my-2 ' >
        <Col xs={1} ></Col>

        <Col xs={10} >
            <h3 className='my-3 trade-heading2 '> Buy Order: </h3>
            <Row>
                
                <Table  bordered className='my-3 currency border-dark border-2 '>                    
                    <tbody className='fs-5  '>
                        <tr>
                            <td width='50%'> <span className='fw-bold roboto-font text-primary'> 
                            Company:</span> 
                            {(years !== 0) ?
                            <span className='fw-bold'> {selectedBond.registered_entity} </span>
                            :<></> 
                            }
                            </td>
                            <td> <span className='fw-bold roboto-font text-primary'> 
                            Cost Per Bond: </span> 
                            {(years !== 0) ?
                            <span className='fw-bold'> 
                            <CurrencyFormat value={parseFloat(selectedBond.cost)}  
                            fixedDecimalScale={2} decimalScale={2} displayType={'text'} 
                            thousandSeparator={true} prefix={'$'} 
                            />
                         </span>
                            :<></> 
                            }</td>                            
                        </tr>
                                               
                        <tr>
                            <td> <span className='fw-bold roboto-font text-primary'> 
                            Number of Bonds: </span> 
                            <span className='fw-bold text-danger'>{buyQty} </span></td>
                            <td> <span className='fw-bold roboto-font text-primary'> 
                            Total Cost of Bonds: </span> 
                            <span className='text-danger fw-bold'> <CurrencyFormat value={parseFloat(costBonds)}  
                            fixedDecimalScale={2} decimalScale={2} displayType={'text'} 
                            thousandSeparator={true} prefix={'$'} 
                            />
                            </span></td>                            
                        </tr>

                        <tr>
                            <td> <span className='fw-bold roboto-font text-primary'> 
                            Number of Years: </span> <span className='fw-bold'> {years} </span></td>
                            <td> <span className='fw-bold roboto-font text-primary'> 
                            Interest Rate: </span> <span className='fw-bold'> {iRate}% </span> </td>                            
                        </tr>
                       
                        <tr>
                            <td  className='  fw-bold text-warning bg-dark'> Broker Fee: <CurrencyFormat 
                            value={parseFloat(brokerFeeBuy)}  
                            fixedDecimalScale={2} decimalScale={2} displayType={'text'} 
                            thousandSeparator={true} prefix={'$'} 
                            />
                            
                            </td>
                            <td  className='  fw-bold text-warning bg-dark'> 
                            Stock Exchange Fee: <CurrencyFormat value={parseFloat(exchangeBuy)}  
                            fixedDecimalScale={2} decimalScale={2} displayType={'text'} 
                            thousandSeparator={true} prefix={'$'} 
                            />
                            </td>                          
                        </tr>
                        <tr>
                            <td  className=' fw-bold text-light bg-dark'> 
                            Total: <CurrencyFormat value={parseFloat(feeBuy)}  
                            fixedDecimalScale={2} decimalScale={2} displayType={'text'} 
                            thousandSeparator={true} prefix={'$'} 
                            />
                            </td>
                            <td  className=' text-light bg-dark'> 
                    {(cashSpending >= 0)?
                        <>
                        {(years > 0)?
                                <Button variant="primary" 
                                onClick={executeTrade}> Place Order </Button> 
                            :
                            <Button variant="primary" disabled > Place Order </Button> 
                            } 
                        </>
                        :
                        <p className='fs-6 text-danger'> *You do not have enough cash to complete this trade.</p>
                    }
                            

                            <Button variant="primary" 
                                onClick={clearForm}
                                className='mx-2'
                            > 
                            Reset Form </Button> 
                            </td>                          
                        </tr>
                    </tbody>
           </Table>
                
            </Row>
            
        <Row className='border border-2 border-secondary my-3 currency px-3'>
            <Row >
            <h3 className='my-3 fs-2  text-primary'> Investment Details: </h3>
            <Col sm={12} md={6} className='fs-4 bonds-inv-c1'>
                <p>Amount to be paid at maturity:</p>
            </Col>
            <Col sm={12} md={6} className='fs-4 bonds-inv-c1'>
                <p> <CurrencyFormat value={parseFloat(costBonds)}  
                            fixedDecimalScale={2} decimalScale={2} displayType={'text'} 
                            thousandSeparator={true} prefix={'$'} 
                            />
                </p>
            </Col>
            
            </Row>

            <Row className='fs-4 bonds-inv-c2'>
            <Col sm={12} md={6} className='fs-4 '>
                <p>Payments to be made:</p>
            </Col>
            <Col sm={12} md={6} className='fs-4 '>
                <p> Every 2 weeks for {years} months.</p>
            </Col>            
            </Row>

            <Row className='fs-4 bonds-inv-c1'>
            <Col sm={12} md={6} className='fs-4'>
                <p>Number coupons / payments:</p>
            </Col>
            <Col sm={12} md={6} className='fs-4'>
                <p> {numberCoupons}</p>
            </Col>            
            </Row>

            <Row className='fs-4 bonds-inv-c2'>
            <Col sm={12} md={6} className='fs-4'>
                <p>Coupon Value:</p>
            </Col>
            <Col sm={12} md={6} className='fs-4'>
                <p>
                <CurrencyFormat value={parseFloat(couponValue)}  
                    fixedDecimalScale={2} decimalScale={2} displayType={'text'} 
                    thousandSeparator={true} prefix={'$'} 
                />
                </p>
            </Col>            
            </Row>

            <Row className='fs-4 bonds-inv-c1'>
            <Col sm={12} md={6} className='fs-4'>
                <p>Total interest to be paid:</p>
            </Col>
            <Col sm={12} md={6} className='fs-4'>
                <p> 
                <CurrencyFormat value={parseFloat(totalPayments)}  
                    fixedDecimalScale={2} decimalScale={2} displayType={'text'} 
                    thousandSeparator={true} prefix={'$'} 
                />
                </p>
            </Col>            
            </Row>

            
        </Row> 


         </Col>
         <Col xs={1} ></Col>
        </Row>
    </Row>

    <Modal show={show1} onHide={handleClose1} centered backdrop='static' keyboard={false}>  
        <Modal.Header closeButton className='bg-dark py-1'>
            <Modal.Title className='text-light fs-3 text-center  '> 
              Trading
            </Modal.Title>
          </Modal.Header>      
        <Modal.Body className='bg-light'> 
            <p className='fs-5 text-primary'> Congratulations on making your first 
            trade in {gameData.quest.name}. It would be executed within 24 hours.</p> 
                    
        </Modal.Body>
        <Modal.Footer className='bg-dark py-1'>
            <Button variant="warning" onClick={handleClose1}> Continue </Button>             
        </Modal.Footer>
    </Modal>

    <Modal show={show2} onHide={handleClose2} centered backdrop='static' keyboard={false}>  
        <Modal.Header closeButton className='bg-dark py-1'>
            <Modal.Title className='text-light fs-3 text-center  '> 
              Trading
            </Modal.Title>
          </Modal.Header>      
        <Modal.Body className='bg-light'> 
            <p className='fs-5 text-primary'> Your trade has been successfully posted. It would be executed 
             within 24 hours. </p> 
                    
        </Modal.Body>
        <Modal.Footer className='bg-dark py-1'>
            <Button variant="warning" onClick={handleClose2}> Continue </Button>             
        </Modal.Footer>
    </Modal>

    <Modal show={show4} onHide={handleClose4} centered backdrop='static' keyboard={false}>  
        <Modal.Header closeButton className='bg-dark py-1'>
            <Modal.Title className='text-light fs-3 text-center  '> 
              Trading
            </Modal.Title>
          </Modal.Header>      
        <Modal.Body className='bg-light'> 
            <p className='fs-5 text-primary'> Trading is currently closed while the system is being
             updated. Try back again in an hour.
            </p> 
                    
        </Modal.Body>
        <Modal.Footer className='bg-dark py-1'>
            <Button variant="warning" onClick={handleClose4}> Continue </Button>             
        </Modal.Footer>
    </Modal>
    </>);
}

export default BuyBonds;