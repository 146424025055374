import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { useNavigate, Link } from 'react-router-dom';
import AuthContext from '../Components/AuthContext';
import{useEffect, useContext, useState} from 'react';
import { GiFallingStar } from "react-icons/gi";
import {  BsFillInfoCircleFill } from "react-icons/bs";
import { DateTime } from "luxon";




const AdminHome = ()=>{
    const[showPage, setShowPage] = useState(false);
    const[showComing, setShowComing] = useState(false);
    const [comingSoon, setComingSoon] = useState([{}]);
    const [quest, setQuest] = useState([{}]);
    const [tradeStatus, setTradeStatus] = useState("");


    useEffect(() => {
        allActiveQuest();
        allComingSoonQuest();
        getTradeStatus();
       }, []);

    const allComingSoonQuest = async ()=>{
        const response = await fetch('/gameapi/getAllQuestCtgry/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                status: 'PUBLISHED',
             })
        });
        const data = await response.json();
        if(data.length !== 0){
            setComingSoon(data);
            setShowComing(true);
        }        
    }

    const allActiveQuest = async ()=>{
        const response = await fetch('/gameapi/allActiveQuests/', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        });
        const data = await response.json();
        setQuest(data);
        setShowPage(true);
    }

    const getTradeStatus = async ()=>{
        const response = await fetch('/gameapi/tradeStatus/', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        });
        const data = await response.json();
        setTradeStatus(data.message);
        
    }

    const openTrade = async ()=>{
        const response = await fetch('/gameapi/openTrading/', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        });
        const data = await response.json();
        if (data.message === "TRADING_OPENED"){
            alert("Trading is opened.");
            setTradeStatus('YES')
        }
        
    }

    return(
    <>        
      
            
            <h1 className="mb-5 mt-3 text-center text-primary roboto-font"> Inve$tor Quest: Administrator Interface </h1>
            
            
            

            <Row>
                <Col xs={1}>
                
                </Col>
                
                <Col xs={10}>
                <Row className='border border-secondary border-2 p-3 my-4'>
                <h3 classname='my-5 '> <GiFallingStar size={30} style={{ fill: '#FFC107' }} /> Quick Access: 
                <span className='text-success roboto-font'> Active Quests </span> </h3>
                {(setShowPage) ? 
                <>
               <Col>
                    <Table className='p-3 mt-3 mb-5 ms-5'  bordered  responsive>
                { quest.map((q, index)=>(
                    <tr key={q.id} className='fs-5 p-4'>
                        <td className='fw-bold' width="30%"> {q.name}</td>
                        <td width="40%">
                        {DateTime.fromISO(q.start_date).toLocaleString(DateTime.DATE_MED)}   
                        - 
                        {DateTime.fromISO(q.end_date).toLocaleString(DateTime.DATE_MED)}
                        </td>
                        <td>
                        <Link to={`/admin/activeQuest/${q.id}`} >View Details</Link>
                        
                        </td>
                    </tr>
                ))}
            </Table>
            </Col>
                </>
                :<></>
                } 
                </Row>             
                </Col>
                 
                <Col xs={1}>
                
                </Col>
              
        </Row>

        <Row>
                <Col xs={1}>
                
                </Col>
                
                <Col xs={10}>
                <Row className='border border-secondary border-2 p-3 my-4'>
                <h3 classname='my-5 '> <GiFallingStar size={30} style={{ fill: '#FFC107' }} /> Quick Access: 
                <span className='text-success roboto-font'> Quests that are Coming Soon</span></h3>
                {(showComing) ? 
                <>
               <Col>
                    <Table className='p-3 mt-3 mb-5 ms-5'  bordered  responsive>
                { comingSoon.map((c, index)=>(
                    <tr key={c.id} className='fs-5 p-4'>
                        <td className='fw-bold' width="30%"> {c.name}</td>
                        <td width="40%">
                        {DateTime.fromISO(c.start_date).toLocaleString(DateTime.DATE_MED)}   
                        - 
                        {DateTime.fromISO(c.end_date).toLocaleString(DateTime.DATE_MED)}
                        </td>
                        <td>
                        <Link to={`/admin/publishedQuest/${c.id}`} >View Details</Link>
                        
                        </td>
                    </tr>
                ))}
            </Table>
            </Col>
                </>
                :
                <h5 className='text-primary'> There are no upcoming quests.</h5>
                } 
                </Row> 

                <Row className='border border-secondary border-2 p-3 my-4 ' >
                    <Col xs={6}>
                    <p className='fs-5 text-success fw-bold'> Trading Opened: <span className='text-dark'>{tradeStatus} </span>  </p> 
                    </Col>
                    <Col xs={6}>
                    
                    {(tradeStatus === "YES")?
                    <div className='text-end'>
                    <Button variant='warning' onClick={openTrade} disabled>
                        Open Trading
                    </Button>
                </div>  
                    :
                    <div className='text-end'>
                     <Button variant='warning' onClick={openTrade}>
                        Open Trading
                    </Button>
                    </div>
                      
                }
                    </Col>
                    
                </Row>             
                </Col>
                 
                <Col xs={1}>
                
                </Col>
              
        </Row>
           
        

            <Row className='my-5'>            
            <Col xs={1}>                
            </Col>
            <Col xs={10}>
            <h3 classname='my-3'> <BsFillInfoCircleFill size={25} style={{ fill: '#FFC107' }} /> Information: 
            <span className='text-success roboto-font'> Quest Status </span> </h3>
            <Table bordered className='border-2 my-3 p-3 fs-5' hover >
                    <thead className='bg-primary text-white p-3'>
                        <th className='px-3'>Quest Status</th>
                        <th className='px-3'>Meaning</th>
                    </thead>
                    <tbody>
                        <tr>
                            <td className='fw-bold' width='30%'> UNPUBLISHED</td>
                            <td> A quest that is not seen by players and can be updated or deleted.</td>
                        </tr>
                        <tr>
                            <td className='fw-bold'> PUBLISHED</td>
                            <td> A quest that appears under the Coming Soon section. 
                                It cannot be updated or deleted and players cannot join the quest.</td>
                        </tr>
                        <tr>
                            <td className='fw-bold'> ACTIVE </td>
                            <td> A quest that appears under the Available section. 
                                It cannot be updated or deleted and players can join the quest.</td>
                        </tr>
                        <tr>
                            <td className='fw-bold'> COMPLETED </td>
                            <td> A closed quest that is no longer available for player to join.
                                Players / Administrators can only view details about these quest.</td>
                        </tr>
                        
                    </tbody>
                </Table>  

                             
            </Col>
            <Col xs={1}>                
            </Col>
               
            </Row>

            
            
            
        

        
      

    
    </>);
}

export default AdminHome;