import { useNavigate,  useParams} from 'react-router-dom';
import {useState, useEffect} from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';


const DeleteAdminUser = ()=>{
    const navigate = useNavigate();
    
    const {adminId} = useParams();
    
    const [show, setShow] = useState(false); 
    const handleClose = () => {
      setShow(false);      
    }
    const handleShow = () => setShow(true);

    const [details,setDetails]=useState({
        first_name:'',
        last_name:'',
        email: ''
    })

    useEffect(() => {
        getAdmin();
    }, []);


    const getAdmin = async()=>{   
      const response = await fetch('/api/getUserDetails/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          uid: `${adminId}`,
         })       
    });
    const data = await response.json();
    if(! ('message' in data))  { 
        setDetails(original => ({...original, first_name: data.user.first_name}))
        setDetails(original => ({...original, last_name: data.user.last_name}))
        setDetails(original => ({...original, email: data.user.email}))
       
    }  
    else{alert(data.message)}
  }

   
  const removeAdmin =  async (e) =>{
      e.preventDefault();    
      const response = await fetch('/api/deleteAdminUser/', {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          uid: `${adminId}`,
         })
    });
    const data = await response.json();
    if(data.message === 'ADMIN_USER_DELETED'){      
      handleShow();
    }
  }
 


    return (
        <>
           
        <Row >
        <Col md={12} lg={2}>
        </Col>
        <Col md={12} lg={8}>
        <Row className="p-1 border border-2 border-secondary rounded mb-5"> 
            <Row classname = "my-1">
            <h2 className="my-3 text-center form-heading"> Confirm Delete Admin User </h2>
            

            <Table className='mx-3'>
            <tbody>
                <tr>
                    <td width='30%' className='currency text-primary fs-4'> First Name </td>
                    <td className='robot0-font  fs-4'> 
                    {details.first_name}
                    </td>
                </tr>
                <tr>
                    <td className='currency text-primary fs-4'> Last Name </td>
                    <td className='robot0-font  fs-4'> {details.last_name} </td>
                </tr>
                <tr>
                    <td className='currency text-primary fs-4'> Email </td>
                    <td className='robot0-font  fs-4'>{details.email} </td>
                </tr>
            </tbody>
            </Table>

              <div className="text-end my-3">
            
            <Button variant="danger" onClick={removeAdmin} >
              Confirm Delete
            </Button>

            <Button variant="primary" className='mx-2'  onClick={() => navigate("/admin/viewAdminUsers/")}>
              Cancel 
            </Button>

          </div> 
             

            </Row>
        </Row>
        </Col>

        <Col  md={12} lg={2}>
        </Col>
    
    </Row>

    
      <Modal show={show} onHide={handleClose} backdrop='static' keyboard={false} centered>  
        <Modal.Header closeButton className='bg-dark py-1'>
            <Modal.Title className='text-light fs-3 text-center  '> 
              Delete Admin User
            </Modal.Title>
          </Modal.Header>      
        <Modal.Body className='bg-light'> 
            <p className='fs-4 '> User Successfully Deleted.</p> 
                    
        </Modal.Body>
        <Modal.Footer className='bg-dark py-1'>
            
            <Button variant="warning" onClick={() => navigate("/admin/viewAdminUsers/")}> Done </Button> 
        </Modal.Footer>
    </Modal>
    </>
    );
}

export default DeleteAdminUser;