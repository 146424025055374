import { Outlet } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import StockMarketNavBar from './StockMarketNavBar';
import {   AiOutlineLineChart} from "react-icons/ai";


const StockMarket = ()=>{
    return(<>
        <Row >
      <Row className='home-header' > 
      <div className="text-center" >
            <span className="inv-color fs-1 roboto-font"> Inve$tor </span> 
            <span className="quest-color fs-1 roboto-font"> Quest TT 2.0 <AiOutlineLineChart size={35} style={{ fill: 'white' }} /> 
            </span>
            
            <p className="text-white fs-2 sriracha-font"> Twin Island Stock Exchange </p>
                
            </div>  
     
        <StockMarketNavBar />
        
        </Row>     
        
          <Outlet />
      </Row>
    </>);
}
export default StockMarket;