import { useNavigate } from 'react-router-dom';
import { useContext} from 'react';
import AuthContext from './AuthContext';
import Button from 'react-bootstrap/Button';
const Logout = ()=>{
    const navigate = useNavigate()
    const {logout }= useContext(AuthContext)

    const logoutUser = () =>{
        logout()
        navigate('/')
    }

    return(
        <>
        
        <Button variant="warning" onClick = {logoutUser} size='sm' className="ms-1">
            Logout
        </Button>
        </>
    );
}

export default Logout;