import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { useState, useEffect } from "react";
import LoadingSpinner from '../Components/LoadingSpinner';
import Table from 'react-bootstrap/Table';
import { DateTime } from "luxon";


const ActiveAlerts = ()=>{
    
    const[loaded, setLoaded] = useState(false);
    const [alertData, setAlertData] = useState([]);
    

    const [alertChanges, setAlertChanges] = useState([]);

    const [show, setShow] = useState(false); 
    const handleClose = () => {
        setShow(false); 
    }
    const handleShow = () => setShow(true);

         

    useEffect(() => {
        getActiveAlerts();
       }, []);

    

    const getActiveAlerts =  async () =>{ 
        const response = await fetch('/gameapi/getAllAlertsByStatusDesc/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            status: 'SHOW',
           })
      });
      const data = await response.json();
      if(! ('message' in data)){      
        setAlertData(data);
        setLoaded(true);
      }
       
      
    }

    const getAffectedSecurites = async(alertId) =>{
        
        const response = await fetch('/gameapi/allAffected/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              aid: `${alertId}`,
             })
        });
        const data = await response.json();
        if(! ('message' in data)){      
         setAlertChanges(data);
         handleShow();
        }
        else{
            alert(data.message);
        }
         
    }

    
    return (<>
    <Row>
    <h2 className="my-3 text-center roboto-font fs-1"> Alerts Already Displayed </h2>
    {(loaded) ?
    <> 
        
        
        <Row className='my-3'>
        {(alertData.length <= 0) ?
        <p className='fs-5 text-primary text-center'> There are no active alerts to display </p>:
        <>
        <Table striped hover>
                <thead>
                    <tr className='text-center roboto-font text-primary fs-5'>
                        <th>Alert Date</th>
                        <th>Heading</th>
                        <th>Details</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    
                    { alertData.map((a, index)=>(
                            <tr key={index} >
                                <td width='15%' className=' fs-5 currency '>
                                {DateTime.fromISO(a.alert_date).toLocaleString(DateTime.DATE_MED)}
                                </td>
                                <td width='15%' className=' fs-5 currency  fw-bold'>{a.heading}</td>
                                <td width='60%' className=' fs-5 currency '> {a.details}</td>
                                <td className='p-1'> 
                                <div className="d-grid gap-2">
                                  <Button variant="primary" size='sm' className='my-1'
                                    onClick={() => getAffectedSecurites(a.id)} >
                                    View Effect
                                   </Button>

                                   </div>
                                </td>
                            </tr>
                        ))}
                    

                </tbody>
            </Table>
        
        </>
         }
            

        </Row>
    </>
    :
    <> 
        <div className="p-3 mb-3 text-center">
                <LoadingSpinner/>
        </div>
    </>
    }
    
    </Row>

    <Modal show={show} onHide={handleClose} backdrop='static' keyboard={false} >  
        <Modal.Header closeButton className='bg-dark py-1'>
            <Modal.Title className='text-light fs-3 text-center  '> 
              Stocks & Mutual Funds Affected
            </Modal.Title>
          </Modal.Header>      
        <Modal.Body className='bg-light'> 
        
        { alertChanges.map((a, index)=>(
                <div key={index} >
                    <p className=' fs-5 currency '>
                    <span className='text-primary fw-bold'> {a.stocks_mf.registered_entity} </span>
                    {a.action} by {a.percentage}% from {DateTime.fromISO(a.start_date).toLocaleString(DateTime.DATE_MED)}  to {DateTime.fromISO(a.end_date).toLocaleString(DateTime.DATE_MED)} 
                    ({a.number_days} days) 
                    <hr/>
                    </p>
                </div>
            ))}
            
                    
        </Modal.Body>
        <Modal.Footer className='bg-dark py-1'>
            
            <Button variant="warning" onClick={handleClose}> Done </Button> 
        </Modal.Footer>
    </Modal>

    

        
    </>);
}

export default ActiveAlerts;