import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { useNavigate, useOutletContext } from 'react-router-dom';
import {  useState,  useEffect } from 'react';
import LoadingSpinner from '../Components/LoadingSpinner';
import PortfolioBarChart from './PortfolioBarChar';
import Image from 'react-bootstrap/Image';
import { DateTime } from "luxon";
import HistoryButton from './HistoryButton';
import { FaLongArrowAltDown, FaLongArrowAltUp, FaMinus } from "react-icons/fa";
import { BsChevronDoubleRight} from "react-icons/bs";
import Page from "../Components/Page";


const QuestHome = ()=>{

    var ordinal = require('ordinal')
    const navigate = useNavigate();

    var CurrencyFormat = require('react-currency-format');
    
    const [gameData, setGameData] = useOutletContext();
    const [overallReturns, setOverallReturns] = useState();
    const [showBarChart, setShowBarChart ] = useState(false);

    const [pendingTransactions , setPendingTransactions] = useState([]);
    const [pendingBondsBuy , setPendingBondsBuy] = useState([]);
    const [pendingBondsSell , setPendingBondsSell] = useState([]);
    const [sMFOwned, setSMFOwned] = useState([]);
    const [stocksOwned, setStocksOwned] = useState([]);
    const [mutualFundsOwned, setMututalFundsOwned] = useState([]);
    const [bondsOwned, setBondsOwned] = useState([]);
    const [bondsOwnedLoaded, setBondsOwnedLoaded] = useState(false);

    const [portfolioChange, setPortfolioChange] = useState(0);
    const [cashPer, setCashPer] = useState(0);
    const [stockPer, setStockPer] = useState(0);
    const [mfPer, setMfPer] = useState(0);
    const [bondsPer, setBondsPer] = useState(0);

    const [chartData, setChartData] = useState([
        {
            id: 1,
            name: "Cash",
            value: 0
        },
        {
            id: 2,
            name: "Stocks",
            value: 0
        },
        {
            id: 3,
            name: "Mutual Funds",
            value: 0
        },
        {
            id: 4,
            name: "Bonds",
            value: 0
        }    
    ]);

    useEffect(() => { 
        loadChartData();  
        calculatePercentages();  
        getPendingTransactions(); 
        getPendingBuyBonds();
        getSecuritiesOwned();  
        getBondsOwned();
      },[]);

      useEffect(() => { 
        if(bondsOwnedLoaded)
            getPendingSellBonds();
      },[bondsOwnedLoaded]);

    const loadChartData = ()=>{
        const newData = chartData.map(data => {
            if (data.id === 1) {
              return {...data, value: parseFloat(gameData.cash_value).toFixed(0)};
            }
            else if (data.id === 2) {
                return {...data, value: parseFloat(gameData.stock_value).toFixed(0)};
            }
            else if (data.id === 3) {
                return {...data, value: parseFloat(gameData.mf_value).toFixed(0)};
            }
            else if (data.id === 4) {
                return {...data, value: parseFloat(gameData.bonds_value).toFixed(0)};
            }
          });
          setChartData(newData);
          setShowBarChart(true);
    }

    const calculatePercentages = ()=>{
      let ch = gameData.current_value - gameData.quest.start_value;
      setPortfolioChange(ch);
      let per = (ch/gameData.quest.start_value) * 100;
      setOverallReturns(per.toFixed(1));

      let c = (gameData.cash_value / gameData.current_value) * 100;
      setCashPer(c.toFixed(1));

      let s = (gameData.stock_value / gameData.current_value) * 100;
      setStockPer(s.toFixed(1));

      let m = (gameData.mf_value / gameData.current_value) * 100;
      setMfPer(m.toFixed(1));
      
      let b = (gameData.bonds_value / gameData.current_value) * 100;
      setBondsPer(b.toFixed(1));
    } 

    const findStockorMF = (sid)=>{
        const searchIndex = gameData.quest.stocks_and_mf.findIndex((s) => s.id == sid);
        return gameData.quest.stocks_and_mf[searchIndex];
    }
    

    const getPendingTransactions = async () =>{
        const response = await fetch('/gameapi/getAllPendingTransactions/', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
              qpID: `${gameData.id}`,
             })
        });
        const data = await response.json(); 
        if(! ('message' in data))  {    //24th may  update: all_pending_transactions   in views
            data.map((s, index) => {
                let sec = findStockorMF(s.stocks);
                let amt = (parseFloat(s.total) / parseInt(s.quantity)).toFixed(2);
                setPendingTransactions(original => [...original, 
                    {image: sec.image,
                     name: sec.registered_entity,
                     quantity: s.quantity,
                     market_price: amt,
                     total: s.total,
                     action: s.action
                    }
                ])
            });
        }       
      }

      const findBond = (bid)=>{
        const searchIndex = gameData.quest.bonds.findIndex((b) => b.id == bid);
        return gameData.quest.bonds[searchIndex];
    }

      const getPendingBuyBonds = async () =>{  //24th may
        const response = await fetch('/gameapi/getAllPendingBondsBuy/', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
              qpID: `${gameData.id}`,
             })
        });
        const data = await response.json(); 
        if(! ('message' in data))  {      
            data.map((aBond, index) => {
                let b = findBond(aBond.bond);
                setPendingBondsBuy(original => [...original, 
                    {
                     image: b.image,
                     name: b.registered_entity,
                     cost: b.cost,
                     quantity: aBond.quantity,
                     principal: aBond.principal
                   }
                ])
            });
        }       
      }

      const findBondOwned = (bid)=>{
        const searchIndex = bondsOwned.findIndex((b) => b.id == bid);
        return bondsOwned[searchIndex];
    }

      const getPendingSellBonds = async () =>{  //24th may
        const response = await fetch('/gameapi/getAllPendingBondsSell/', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
              qpID: `${gameData.id}`,
             })
        });
        const data = await response.json(); 
        if(! ('message' in data))  {      
            
            data.map((bOwn, index) => {
                let b = findBondOwned(bOwn.bonds_owned);
                setPendingBondsSell(original => [...original, 
                    {
                     id: bOwn.bonds_owned,
                     image: b.image,
                     name: b.name,
                     sellValue: bOwn.sell_value,
                   }
                ])
            });
        }       
      }


      

      const getSecuritiesOwned = async () =>{
        const response = await fetch('/gameapi/getAllStocksMFOwned/', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
              qpID: `${gameData.id}`,
             })
        });
        const data = await response.json();                  
        data.map((s, index) => {
            let sec = findStockorMF(s.stocks_mf_owned);
            let total = (parseFloat(sec.current_value) * parseInt(s.quantity)).toFixed(2);
            let arrow = "UP";
            if(parseFloat(sec.current_value) < parseFloat(sec.previous_value)){
                arrow  = "DOWN";
            }
            else if(parseFloat(sec.current_value) == parseFloat(sec.previous_value)){
                arrow = "EQUAL";
            }
            if(sec.type === 'STOCK'){
                setStocksOwned(original => [...original, 
                    {   image: sec.image,
                        ticker: sec.ticker,
                        color: sec.color,
                        name: sec.registered_entity,
                        quantity: s.quantity,
                        total_value: total,
                        show_arrow: arrow, 
                        current_price: sec.current_value
                    }
                ])
            
            }
            else{
                setMututalFundsOwned(original => [...original, 
                    {   image: sec.image,
                        ticker: sec.ticker,
                        color: sec.color,
                        name: sec.registered_entity,
                        quantity: s.quantity,
                        total_value: total,
                        show_arrow: arrow,
                        current_price: sec.current_value
                    }
                ])
            }              
        });
      }

      const getBondsOwned = async () =>{
        const response = await fetch('/gameapi/getAllBondsOwned/', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
              qpID: `${gameData.id}`,
             })
        });
        const data = await response.json();                  
        if(! ('message' in data)){
            data.map((aBond, index) => {
                let b = findBond(aBond.bond);
                setBondsOwned(original => [...original, 
                    {
                     id: aBond.id,
                     image: b.image,
                     name: b.registered_entity,
                     principal: aBond.principal,
                     issue_date: aBond.date_bought,
                     maturity_date: aBond.maturity_date
                   }
                ])
            });
            setBondsOwnedLoaded(true);
        } 
      }
      

    
    
   

    return(<>
    <Page title={`Your Quest - ${gameData.quest.name}`}/>
    <Row className='border border-2 border-dark mb-3'>

    <Row className="my-4 ">   {/* start heading info */} 
        <Col md={12} lg={1}></Col>    
        <Col md={12} lg={4} >
          <p className="quest-play-subheading1 ">Current Portfolio: </p> 
          <span className="fs-1 text-success currency"> 
          <CurrencyFormat value={parseFloat(gameData.current_value)}  
                    fixedDecimalScale={2} decimalScale={2} displayType={'text'} 
                    thousandSeparator={true} prefix={'$'} 
          />
          </span> 
        </Col>

        <Col md={12} lg={6} >                    
        <Table className='p-2 my-2' >
                <tr className='border-bottom'>
                    <td className="quest-play-subheading2">  Your Rank</td>
                    { (gameData.current_position < 0)  ?                     
                    <td className='fs-6 text-end roboto-font px-1'>  Start Trading to see your rank </td>
                    : 
                    <td className='fs-4 text-end roboto-font px-1 text-primary'> 
                        {ordinal(gameData.current_position)} </td> 
                    } 
                        
                         
                </tr>
                <tr className='border-bottom'>
                    <td className="quest-play-subheading2">  Quest Start Value:</td>
                    <td className= "fs-4 currency text-end "> 
                    <CurrencyFormat value={parseFloat(gameData.quest.start_value)}  
                    fixedDecimalScale={2} decimalScale={2} displayType={'text'} 
                    thousandSeparator={true} prefix={'$'} />
                    </td>
                </tr>
                
                
                <tr className='border-bottom'>
                    <td className="quest-play-subheading2">  Overall Returns: </td>
                    {(portfolioChange >= 0) ?
                        <td className= 'text-end text-success currency fs-4'>                        
                        ({overallReturns}%) + 
                        <CurrencyFormat value={parseFloat(portfolioChange)}  
                            fixedDecimalScale={2} decimalScale={2} displayType={'text'} 
                            thousandSeparator={true} prefix={'$'} 
                        />
                        </td>
                    :
                    <td className= 'text-end text-danger currency fs-4'>                        
                        ({overallReturns}%) - 
                        <CurrencyFormat value={Math.abs(portfolioChange)}  
                            fixedDecimalScale={2} decimalScale={2} displayType={'text'} 
                            thousandSeparator={true} prefix={'$'} 
                        />

                        </td>
                    
                    }                    
                </tr> 

                <tr className='border-bottom'>
                    <td className="quest-play-subheading2">  Portfolio Cash Value:</td>
                    <td className= "fs-4 currency text-end "> 
                    <CurrencyFormat value={parseFloat(gameData.cash_value)}  
                            fixedDecimalScale={2} decimalScale={2} displayType={'text'} 
                            thousandSeparator={true} prefix={'$'} 
                    />
                    </td>
                    
                </tr>              
                

                <tr className='border-bottom'>
                    <td className="quest-play-subheading2"> Date Joined:</td>
                    <td className= "fs-5 roboto-font text-end ">  
                    {DateTime.fromISO(gameData.date_joined).toLocaleString(DateTime.DATE_MED)}
                    </td>
                    
                </tr>
                
            </Table>                     
        </Col>   
        <Col md={0} lg={1}></Col>                      
    </Row> {/* end heading info */}

    <Row className='my-3'>
        <Col md={0} lg={1}></Col> 
        <Col md={0} lg={10}>
        <h1 className='quest-play-sectionheadings my-3'> Portfolio Details: </h1>
      { (showBarChart)?
      <> 
        <div className="p-3 mb-2 text-center border border-3 border-primary">
        <PortfolioBarChart chartData = {chartData}/>
        </div>
        <div className='text-center roboto-font fs-5'>
            <span className='graph-cash'> Cash: {cashPer} % </span>  ||
            <span className='graph-stock'> Stocks: {stockPer} % </span>||
            <span className='graph-mf'> Mutual Funds: {mfPer} % </span> ||
            <span className='graph-bonds'> Bonds: {bondsPer}</span>%  
        </div>
      </>
      :
      <>
        <div className="p-3 mb-3 text-center"> 
                <LoadingSpinner/>  
            </div>
      </>

      }

    <Row>    
       
        {(stocksOwned.length != 0)?
        <>
         <Table className='my-5 mx-2' responsive="lg" hover>
        <thead className='border-bottom'>
            <tr>
                <th colSpan={3} className='bg-dark roboto-font fs-4 px-3 text-white'>Stocks Owned </th>
                <th colSpan={3} className='text-end bg-dark'>
                <span className='  px-4'>
                    <HistoryButton type={'STOCK'}/>
                </span>
                </th>
            </tr>
            <tr className='text-primary fs-5'>
                <th></th>
                <th></th>
                <th></th>
                <th className='text-center' width='30%'>Number of Shares Owned</th>
                <th className='text-end' width='20%'>Current Value</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
        { stocksOwned.map((stock, index)=>(
        <tr key={index} className='fs-5 currency'> 
            <td> 
            <Image  src={require(`../assets/logos/${stock.image}`)}  rounded width="45px" height="auto" />
            </td>
            <td className='roboto-font' style={{ color: `${stock.color}` }}> {stock.ticker} </td>
            <td> {stock.name} </td>
            <td className='text-center'> {stock.quantity} <span className='text-secondary'>(<CurrencyFormat 
                            value={parseFloat(stock.current_price)}  
                            fixedDecimalScale={2} decimalScale={2} displayType={'text'} 
                            thousandSeparator={true} prefix={'$'} 
            /> per share) </span>
            </td>
            <td className='text-end'> 
            <CurrencyFormat value={parseFloat(stock.total_value)}  
                            fixedDecimalScale={2} decimalScale={2} displayType={'text'} 
                            thousandSeparator={true} prefix={'$'} 
            />
            </td>
            <td className='text-end'>
                {(stock.show_arrow === "UP") ?
                    <FaLongArrowAltUp size={25} style={{ fill: 'green' }} /> :
                    <>
                    {(stock.show_arrow === "DOWN") ?
                        <FaLongArrowAltDown size={25} style={{ fill: 'red' }} /> :
                        <FaMinus size={25} style={{ fill: 'black' }} />
                    }
                    </>
                }
            
            </td> 
        </tr>
        ))}
         </tbody>
    </Table>
        </>:         
        <Table className='my-5 mx-2' hover>
        <thead className='border-bottom'>
            <tr>
                <th colSpan={3} className='bg-dark roboto-font fs-4 px-3 text-white'>Stocks Owned </th>
                <th colSpan={3} className='text-end bg-dark'>
                <span className='  px-4'>
                    <HistoryButton type={'STOCK'}/>
                </span>
                </th>
            </tr>
        </thead>
        <tbody>
        <tr className='fs-6'> 
         <td colSpan={5} className='fs-5 text-secondary '> 
             You currently do not own any stocks in this Quest.            
         </td>
         </tr>
         </tbody>
    </Table>
        }
    </Row>

    <Row>    
    
        {(mutualFundsOwned.length != 0)?
        <>
        <Table className='my-5 mx-2' responsive="lg" hover>
        <thead className='border-bottom'>
            <tr>
                <th colSpan={3} className='bg-dark roboto-font fs-4 px-3 text-white'>Mutual Funds Owned </th>
                <th colSpan={3} className='text-end bg-dark'>
                <span className='  px-4'>
                    <HistoryButton type={'MUTUAL_FUND'}/>
                </span>
                </th>
            </tr>
            <tr className='text-primary fs-5'>
                <th></th>
                <th></th>
                <th></th>
                <th className='text-center' width='30%'>Number of Shares Owned</th>
                <th className='text-end' width='20%'>Current Value</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
        { mutualFundsOwned.map((mf, index)=>(
        <tr key={index} className='fs-5 currency'> 
            <td> 
            <Image  src={require(`../assets/logos/${mf.image}`)}  rounded width="45px" height="auto" />
            </td>
            <td className='roboto-font'style={{ color: `${mf.color}` }}> {mf.ticker} </td>
            <td > {mf.name} </td>
            
            <td className='text-center'> {mf.quantity} <span className='text-secondary'>(<CurrencyFormat 
                            value={parseFloat(mf.current_price)}  
                            fixedDecimalScale={2} decimalScale={2} displayType={'text'} 
                            thousandSeparator={true} prefix={'$'} 
            /> per share) </span>
            </td>
            <td className='text-end'> 
            <CurrencyFormat value={parseFloat(mf.total_value)}  
                            fixedDecimalScale={2} decimalScale={2} displayType={'text'} 
                            thousandSeparator={true} prefix={'$'} 
            />
            
            </td>

            <td className='text-end'>
                {(mf.show_arrow === "UP") ?
                    <FaLongArrowAltUp size={25} style={{ fill: 'green' }} /> :
                    <>
                    {(mf.show_arrow === "DOWN") ?
                        <FaLongArrowAltDown size={25} style={{ fill: 'red' }} /> :
                        <FaMinus size={25} style={{ fill: 'black' }} />
                    }
                    </>
                }
            
            </td>
        </tr>
        ))}
         </tbody>
    </Table>
        </>:         
        <Table className='my-5 mx-2' hover>
        <thead className='border-bottom'>
            <tr>
                <th colSpan={3} className='bg-dark roboto-font fs-4 px-3 text-white'>Mutual Funds Owned </th>
                <th colSpan={3} className='text-end bg-dark'>
                <span className='  px-4'>
                    <HistoryButton type={'MUTUAL_FUND'}/>
                </span>
                </th>
            </tr>
        </thead>
        <tbody>
        <tr className='fs-6'> 
         <td colSpan={5} className='fs-5 text-secondary '> 
             You currently do not own any Mutual Funds in this Quest.            
         </td>
         </tr>
         </tbody>
    </Table>
        }
    </Row>

    

    <Row> {/*Bonds */}
        <Table className='my-5 mx-2' responsive="lg" hover>
        <thead className='border-bottom'>
        <tr>
                <th colSpan={4} className='bg-dark roboto-font fs-4 px-3 text-white'>Bonds Owned </th>
                <th colSpan={3} className='text-end bg-dark px-4'>
                    <Button size='sm' variant="warning" onClick={() => navigate('../bondHistory')}>
                    View History <BsChevronDoubleRight/>
                    </Button>

                </th>
            </tr>
            {(bondsOwned.length != 0) ?
            
            <tr className='text-primary fs-5'>
                <th>  </th>
                <th> </th>
                <th> Issue Id</th>
                <th className='text-center'>Principal</th>
                <th className='text-center'>Issue Date</th>
                <th className='text-center'>Maturity Date</th>
                <th className='text-end'></th>
            </tr>           
            :
            <>
            <tr>
            <td colSpan={7} className='fs-5 text-secondary '> 
             You currently do not own any Bonds in this Quest.            
         </td>
         </tr>
            </>

            }            
        </thead>
        <tbody>
        {(bondsOwned.length != 0) ?
        <>
            { bondsOwned.map((b, index)=>(
                <tr key={index} className='fs-5 currency'>
                    <td> 
                    <Image  src={require(`../assets/logos/${b.image}`)}  rounded width="45px" height="auto" />
                    </td>
                    <td > {b.name} </td>
                    <td className='text-center' > B-{b.id} </td>
                    <td className='text-center'> 
                    <CurrencyFormat value={parseFloat(b.principal)}  
                            fixedDecimalScale={2} decimalScale={2} displayType={'text'} 
                            thousandSeparator={true} prefix={'$'} 
                    />
                    
                    </td>
                    <td className='text-center'> 
                    {DateTime.fromISO(b.issue_date).toLocaleString(DateTime.DATE_MED)}
                    </td>
                    <td className='text-center'> 
                    {DateTime.fromISO(b.maturity_date).toLocaleString(DateTime.DATE_MED)}
                    </td>
                    <td className='text-end'>
                        <Button size='sm' variant='warning' onClick={() => navigate(`../bondDetails/${b.id}`)}>
                            Details
                        </Button>
                    
                    </td>
                </tr>
            ))}
        </>      
        
        :
        <></>
        }
        

        </tbody>
        </Table>
    </Row>{/* end Bonds */}

    <Row>
    <h1 className='quest-play-sectionheadings mt-5 mb-3'> 
    Pending Transactions <span className='fs-6'> (Stocks & Mututal Funds) </span> 
    
    </h1>
        {(pendingTransactions.length != 0)?
        <>
        <Table responsive="lg" bordered className='mx-2'> {/* 24 May */}
            <thead className='fs-5 text-primary fw-bold text-center currency '>
                <tr>
                    <th></th>
                    <th>Company</th>
                    <th>Quantity</th>
                    <th>Share Price</th>
                    <th>Total</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
            { pendingTransactions.map((pt, index)=>(
                    <tr key={index} className=' fs-5 currency '>
                        <td>
                        <Image  src={require(`../assets/logos/${pt.image}`)}  rounded width="45px" height="auto" />
                            </td>
                        <td>{pt.name}</td>
                        <td className='text-center'>{pt.quantity}</td>
                        <td className='text-end'> ${pt.market_price}</td>
                        <td className='text-end'> ${pt.total}</td>
                        <td className='text-center'>{pt.action}</td>
                    </tr>
                ))}
            </tbody>              
        </Table>
        </>
        :
        <>
        {(gameData.active === 'NO')?
            <p className='fs-6 my-2 text-secondary'> You don't have any pending transactions for Stocks and Mutual Funds in this Quest. </p>
        :
            <p className='fs-6 my-2 text-secondary'> All pending transactions for Stocks and Mutual Funds have been completed in this Quest. </p>
        }
        
        </>
        
        }
        
      </Row>  {/* Pending trans stock & mf*/}

      <Row>
    <h1 className='quest-play-sectionheadings2 mt-5 mb-3'> 
    Pending Transactions <span className='fs-6 '> (Bonds) </span> 
    </h1>
    {(pendingBondsBuy.length == 0 && pendingBondsSell.length == 0) ?               
        <p className='fs-6 my-2 text-secondary'> You don't have any pending transactions for Bonds in this Quest. </p>
        :
        <>
        {(pendingBondsBuy.length != 0 ) ?
            <>
            <Table responsive="lg" bordered className='mx-2'> {/* 24 May */}
                    <thead className='fs-5 text-primary fw-bold text-center currency '>
                        <tr className='bg-warning roboto-font text-light'>
                            <th colSpan={5}>Buying Bonds</th>
                        </tr>
                        <tr>
                            <th></th>
                            <th>Company</th>
                            <th>Cost per Bond </th>
                            <th>Quantity</th>
                            <th>Principal</th>
                            
                        </tr>
                    </thead>
                    <tbody>
                    { pendingBondsBuy.map((pt, index)=>(
                            <tr key={index} className=' fs-5 currency '>
                                <td>
                                <Image  src={require(`../assets/logos/${pt.image}`)}  rounded width="45px" height="auto" />
                                    </td>
                                <td>{pt.name}</td>
                                <td className='text-end'>
                                <CurrencyFormat value={parseFloat(pt.cost)}  
                                    fixedDecimalScale={2} decimalScale={2} displayType={'text'} 
                                    thousandSeparator={true} prefix={'$'} 
                                />
                                </td>
                                <td className='text-center'> {pt.quantity}</td>
                                <td className='text-end'> 
                                <CurrencyFormat value={parseFloat(pt.principal)}  
                                    fixedDecimalScale={2} decimalScale={2} displayType={'text'} 
                                    thousandSeparator={true} prefix={'$'} 
                                />
                                </td>
                            </tr>
                        ))}
                    </tbody>              
                </Table>
            </>
            :
            <></>
        }


        {(pendingBondsSell.length != 0) ?
            <>
            <Table responsive="lg" bordered className='mx-2 my-3'> {/* 24 May */}
                    <thead className='fs-5 text-success fw-bold text-center currency '>
                    <tr className='bg-primary roboto-font text-light'>
                            <th colSpan={3}>Selling Bonds</th>
                        </tr>
                        <tr>
                            <th> Issue ID</th>
                            <th>Company</th>
                            <th>Selling Price</th>                        
                        </tr>
                    </thead>
                    <tbody>
                    { pendingBondsSell.map((pt, index)=>(
                            <tr key={index} className=' fs-5 currency '>                            
                                <td className='text-center'>B-{pt.id}</td>
                                <td>
                                <Image  src={require(`../assets/logos/${pt.image}`)}  rounded width="45px" height="auto" />
                                    {pt.name} </td>
                                <td className='text-end'> 
                                <CurrencyFormat value={parseFloat(pt.sellValue)}  
                                    fixedDecimalScale={2} decimalScale={2} displayType={'text'} 
                                    thousandSeparator={true} prefix={'$'} 
                                />
                                </td>                            
                            </tr>
                        ))}
                    </tbody>              
                </Table>
            </>
            :
            <> </>
        }
        </>
    }
   


    </Row>

      <Row className='my-4 '>
        <Col >
        <hr></hr>
        <h6 className='roboto-font fs-6 ttsec-blue mt-1'>Note the following:</h6>
        <p className='fs-6 text-danger'>*All pending transactions would be completed within 24 hours of the order being placed.</p>
        <p className="quest-play-subheading2"> Pending Fees: 
        <span className='fs-4 currency text-dark px-1 py-1'  > <CurrencyFormat value={parseFloat(gameData.fees_owed)}  
                            fixedDecimalScale={2} decimalScale={2} displayType={'text'} 
                            thousandSeparator={true} prefix={'$'} 
                    />  </span> </p>

        <p className='my-2 roboto-font fs-6 text-primary'>
            Pending Fees  = (total broker fees + total stock exchange fees + total price of buying securites) 
             For All Pending Transactions.
        </p>
        <hr></hr>
        </Col>
      </Row>



      </Col> 
      <Col md={12} lg={1}  className="m-auto d-none d-md-block"></Col>
    </Row>

      
       
        
         
      
      </Row>    
       
    </>);
}

export default QuestHome;