import { useParams, useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import {useState } from 'react';
import ResetNavBar from './ResetNavBar';
import Footer from '../Components/Footer';
import Page from "../Components/Page";

const ResetLinkSent = ()=>{
    const navigate = useNavigate();
    const{value} = useParams(); 
    const [email, setEmail] = useState("");
    const [rLink, setRLink] = useState("reset link to be displayed here");

    const changeEmail = (e)=>{
        setEmail(e.target.value);
    }

    const getLink = async()=>{
        const response = await fetch('/api/getResetLink/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email: `${email}` })
        });
        const data = await response.json();
        setRLink(data.message);
    }
 
    return (<>
    <Page title='Email Sent - Password Reset'/>
    <ResetNavBar />

    <Container>
    
    <Row className='mt-4'>
        <Col sm={12} md={2} className="d-none d-md-block"> </Col>
        <Col sm={12} md={8} >  {/* Main section */}

        <Row className="align-items-center homepage-header-bg border border-1 border-secondary "> {/* header row*/}
            <Col xs={12} sm={4} className = " text-center  my-3">
                
            <Image  src={require('../assets/logos/IQlogo.png')}   width="70%" height="auto" />
            </Col>
            <Col xs={12} sm={8}>                
            <p className='fs-3 roboto-font text-light text-center my-2'> Reset Link</p>
            </Col>
        </Row> {/* close header row*/}
        

        <Row className='border border-2 border-dark mb-5'> {/* page body row*/}
        <Col sm={12} md={1} className="d-none d-md-block"> </Col>
        <Col sm={12} md={10} >
        { (value !== "err") ?
    <>
    <p className= 'text-center fs-4 my-4 currency'>We have sent you an email with a 
    password reset link. This link will expire after 24 hours. </p>  
           
        <p className='text-center text-primary fs-5 my-4 currency'> 
        *Remember to check your junk or spam folder if the email is not in your inbox.  </p>

        <div className='text-end mb-3 mx-2' >
            <Button variant='warning' size='sm' onClick={() => navigate("/")}>
                Back to Home
            </Button>
        </div>
    </> 
    :
    <>
    <p className= 'text-center fs-4 my-4 currency'>We were unable to send you the reset link
    at this time. Please try again.
     </p>  
        
        <div className='text-end mb-3 mx-3' >
            <Button variant='warning' size='sm' onClick={() => navigate("/")}>
                Back to Home
            </Button>
        </div>

    </>
    }
        
                    
        </Col>
        <Col sm={12} md={1} className="d-none d-md-block"> </Col>
        </Row>
        </Col>
        <Col sm={12} md={2} className="d-none d-md-block"> </Col>
    </Row>



    
    
    </Container >


<Footer />
    </>);
}

export default ResetLinkSent;