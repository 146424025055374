import { useState, useEffect } from 'react';
import { useParams, useNavigate} from 'react-router-dom';
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import LoadingSpinner from '../Components/LoadingSpinner';

const UpdateQuestMutualFunds = ()=>{
    const navigate = useNavigate();
    const{questId} = useParams();
    const [loaded, setLoaded] = useState(false);  
    const [mfData, setMfData] = useState([{}]); 
    const [selectedMF, setSelectedMF] = useState([]);

    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);

    useEffect(() => { 
        getAllMF();      
    },[]);

    
    const getAllMF = async ()=>{
        const response = await fetch('/gameapi/allStocksOrMF/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                type: 'MUTUAL_FUND',
             })
        });
        const data = await response.json();
        if(data.length !== 0){
            setMfData(data);
        } 
        setLoaded(true)       ;
    }

   
    const updateSelectedItem = (e)=>{ 
        if(e.target.checked) //add
            setSelectedMF(selectedMF => [...selectedMF, e.target.id])
        else{ //remove
            setSelectedMF((current) =>
                current.filter((v) => {
                return v !== e.target.id;
            }))
        }       
    }

    const updateMF = async(e) =>{
        e.preventDefault();
        if(selectedMF.length <= 0){
            handleShow();
        }
        else{
            var selectedIds = JSON.stringify({selectedIds: `${selectedMF}`})
            selectedIds = selectedIds.substring(1, selectedIds.length)
            var jsonStr = `{"qid": "${questId}"` + "," + selectedIds;
            
            const response = await fetch('/gameapi/updateQuestMF/', {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: jsonStr
            });
            const data = await response.json();
            if(data.message === "ERROR_UPDATING_MF"){
                alert('Something went wrong! Try updating it again.');
            }
            else{
                handleShow1();
            }   
            
            
        }
    }

    

    return(<>
     <Row >
        <Col md={12} lg={1}>
        </Col>
        <Col md={12} lg={10}>
        
        <Row className="p-1 border border-2 border-primary rounded mb-5">             
            <h2 className="my-2 text-center form-heading"> Update Stock For Quest   </h2>          
            
            {(loaded) ?
            <>
            
            <Form className=' mb-3' onSubmit={updateMF} > 
            <Table responsive="sm" >
                <thead>    <tr>
                <th></th>
                <th className=' text-danger'>Company</th>
                <th className='text-danger'>Sector & Industry</th>
                 <th className='text-center text-danger'>Add To Quest?</th>
                </tr>  
                </thead>
                <tbody>
                { mfData.map((m, index)=>(
                    <tr key={m.id}>
                        <td> 
                        <Image  src={require(`../assets/logos/${m.image}`)}  rounded width="50px" height="auto" />
                        </td>
                        <td className='fw-bold'>
                            {m.registered_entity}
                        </td>
                        <td>
                            {m.sector}, {m.industry}
                        </td>
                        <td> 
                        <div className="text-center">
                            <Form.Check
                                name={m.id}
                                type='checkbox'
                                id={m.id}
                                onChange={updateSelectedItem}                   
                            />
                            </div>
                        </td>
                        
                    </tr>
                ))} 
                </tbody>
                </Table>
                <div className="text-center">            
                <Button variant="primary" type="submit" >
                Update Mututal Funds
                </Button>  
                <Button variant="primary" className='mx-2'  onClick={() => navigate(`/admin/unpublishedQuest/${questId}`)}>
                Cancel 
                </Button>         
                </div> 
               </Form>
            </>  
            :
            <>
            <div className="p-3 mb-3 text-center">
                <LoadingSpinner/>
            </div>
            </>
            }

        </Row>       

        </Col>

        <Col  md={12} lg={1}>
        </Col>
    
    </Row>

    <Modal show={show} onHide={handleClose} backdrop='static' centered>  
        <Modal.Header closeButton className='bg-dark py-1'>
            <Modal.Title className='text-light fs-3 text-center  '> 
              Updating Mutual Funds in Quest
            </Modal.Title>
          </Modal.Header>      
        <Modal.Body className='bg-light'> 
            <p className='fs-4 '> You must select at least 1 Mutual Fund.</p> 
                    
        </Modal.Body>
        <Modal.Footer className='bg-dark py-1'>
            <Button variant="warning" onClick={handleClose}> Close </Button> 
        </Modal.Footer>
    </Modal> 

    <Modal show={show1} onHide={handleClose1} backdrop='static' centered>  
        <Modal.Header closeButton className='bg-dark py-1'>
            <Modal.Title className='text-light fs-3 text-center  '> 
                Updating Mutual Funds in Quest
            </Modal.Title>
          </Modal.Header>      
        <Modal.Body className='bg-light'> 
            <p className='fs-4 '> Mutual Funds sucessfully updated.</p> 
                    
        </Modal.Body>
        <Modal.Footer className='bg-dark py-1'>
        <Button variant="warning" onClick={() => navigate(`/admin/unpublishedQuest/${questId}`)}> Continue </Button>            
        </Modal.Footer>
    </Modal>
    </>);
}

export default UpdateQuestMutualFunds;