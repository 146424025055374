import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate, Outlet } from 'react-router-dom';
import {  useState, useContext, useEffect } from 'react';
import AuthContext from '../Components/AuthContext';
import NavigationBar from '../Components/NavigationBar';
import {getPlayerData} from '../Components/IQfunctions';
import Footer from '../Components/Footer';
import LoadingSpinner from '../Components/LoadingSpinner';



const Player = () =>{
    const navigate = useNavigate();
    const { authorisePlayer, role, userid, authToken}= useContext(AuthContext);    
    const [status, setStatus] = useState('');  
    const [loadPlayer, setLoadPlayer]  = useState(false);

    const [details,setDetails]=useState({
      first_name:'',
      player_name:'',
      status: '',
      broker_tag: '',
      age_group: ''      
  });


    useEffect( () => {
        getDetails();
      }, []);

    const getDetails = async()=>{
        const data = await getPlayerData(authToken); 
        updatePlayerDetails(data);     
        setStatus(data.status);    
      }

    const updatePlayerDetails = (data) =>{
      setDetails({
        first_name: data.first_name,
        player_name: data.playername,
        status: data.status,
        broker_tag: data.broker_tag,
        age_group: data.age_group
      })
    }

    useEffect( () => {
        authorisePlayer();
        if(localStorage.getItem('qtnxvf') === null){
            navigate("/");
        }
        else if(authToken === null) {
            navigate("/");
        }

      }, []);

      useEffect(() => {
        if(status === 'new'){
          navigate('/start/');
        }
        //add if status === disabled
      }, [status]);

      useEffect(() => {
        if(status === 'active'){
          setLoadPlayer(true);
        }
      }, [status]);


    //col 1: space  col 2-11: main screen  col 12: space

    return(
    <>
    <NavigationBar firstName={details.first_name} playerName={details.player_name}/>

    {(loadPlayer) ?
    <>
      <Container  fliud>
        <Row >
        <Col sm={12} md={1} className="d-none d-md-block"> </Col>
        
      <Col sm={12} md={10} >

      <Outlet context={[details, setDetails]} />

      </Col>

        <Col sm={12} md={1} className="d-none d-md-block"> </Col>
        </Row>
      </Container>    
    </>
    :
    <div className="p-3 mb-3 text-center"> 
                <LoadingSpinner/>  
    </div>

    }   
    
    <Footer />
    
    </>
    );
}

export default Player;
