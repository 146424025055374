import Button from 'react-bootstrap/Button';
import{useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import { DateTime } from "luxon";


const PlayerUserTable = ({userData})=>{
    const navigate = useNavigate();


    

    return(<>

    <Table  hover className='my-3' responsive>
            <thead className='ttsec-blue-bg'>    
                <tr className=' text-light fs-5 roboto-font'>
                <th >Name</th>
                <th>Username</th>
                <th>Email</th>
                <th>Location</th>
                 <th>Date Joined</th>
                 <th> </th>
                </tr>  
            </thead>
            <tbody className='admin-table-data'>
                { userData.map((u, index) =>(
                    <tr key={index}>
                        <td>{u.user.first_name} {u.user.last_name}</td>
                        <td>{u.playername}</td>
                        <td>{u.user.email} </td>   
                        <td>{u.geo_location}</td>                     
                        <td>
                        {DateTime.fromISO(u.user.date_joined).toLocaleString(DateTime.DATE_MED)}
                        </td>
                        <td>
                            <Button variant="danger" size="sm" onClick={() => navigate(`/admin/deletePlayer/${u.user.id}`)} >
                                Delete User
                            </Button>
                        </td>
                        
                        
                    </tr>
                ))}
            </tbody>
        </Table>

        
    
    </>);
}

export default PlayerUserTable;