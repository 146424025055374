import { createContext, useState, useEffect} from 'react'
import {  useNavigate} from 'react-router-dom';
import jwt_decode from "jwt-decode"

const AuthContext = createContext()

export default AuthContext;


export const AuthProvider = ({children}) =>{
    const navigate = useNavigate();
    const [authToken, setAuthToken]  = useState(null);
    const [userid, setUserid] = useState(null);
    const [role, setRole] = useState(null);
    const [loading, setLoading] = useState(true);

    
    useEffect( ()=>{
        loadDetails();
        const minutes_350 = 350 * 60 * 1000;
        let interval = setInterval(()=>{
            if(authToken !== null){
                updateToken(); 
            }            
        }, minutes_350)
        return ()=>clearInterval(interval)
        
    },[authToken])


    const loadDetails = () =>{
        if(loading){            
            const userReference = localStorage.getItem('qtnxvf')
            if(userReference !== null)  {
                //  remove 5 additional characters added to user reference String 
                let s1 = userReference.slice(0,10)
                let s2 = userReference.slice(15 - userReference.length) 
                let userR = s1 + s2
                loadTokens(userR)
            }
            setLoading(false);
        }                  
    }

    const loadTokens = async (uRef)=>{
        //get token from DB:
        const res = await fetch('/api/dbRefreshToken/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({'user_ref' : uRef })
        })
        const tok_data = await res.json()
        const refreshToken = tok_data.r_token
        //---------------------------------------------------------//
        //  if token successfully received:
        if(refreshToken !== 'UNAVAILABLE' || refreshToken !== 'ERROR'){
            const response = await fetch('/log/token/refresh/', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({'refresh' : refreshToken })
            })
            const data = await response.json()
            if(response.status === 200){
                const user = jwt_decode(data.access)
                setAuthToken(data)
                setRole(user.r)
                setUserid(user.user_id)
                await setRefreshToken(data)
            }
            else{ logout();  }
        }
        else { logout(); }            
        
    }

    const setRefreshToken = async (tokens)=>{
        //  Updating the refresh token in the database
        const response = await fetch('/api/updateRefreshTokenDB/', {
            method: 'PUT',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization' : 'Bearer ' + String(tokens.access)
            },
            body: JSON.stringify({ r_token: `${tokens.refresh}` })
        })
        const data = await response.json()
    }

    const logout = () =>{
        setAuthToken(null)
        setUserid(null)
        setRole(null)
        localStorage.removeItem('qtnxvf')
    }

    const authorisePlayer = () =>{
        if(authToken === null || role === null){
            navigate('/');
        }
        else if (role !== 'player'){
            navigate('/');
        }
    }

    const authoriseAdmin = () =>{
        if(authToken === null || role === null){
            navigate('/');
        }
        else if (role !== 'admin'){
            navigate('/');
        }
    }

    const updateToken = async ()=>{
        const response = await fetch('/log/token/refresh/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({'refresh' : authToken.refresh })
        })
        const data = await response.json()
        if(response.status === 200){
            const user = jwt_decode(data.access)
            setAuthToken(data)
            setRole(user.r)
            setUserid(user.user_id)
            await setRefreshToken(data)
        }
        else{
            logout();
        }      
        
    }

    

    const contextData = {
        authToken: authToken,
        userid:userid,
        role:role,
        setAuthToken: setAuthToken,
        setUserid:setUserid,
        setRole: setRole,
        logout: logout,
        authorisePlayer:authorisePlayer,
        authoriseAdmin: authoriseAdmin,
        setRefreshToken: setRefreshToken   
    }

    return(
        <AuthContext.Provider value={contextData}>
            {children}
        </AuthContext.Provider>
    )    

}

