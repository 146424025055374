import Row from 'react-bootstrap/Row';
import SecurityTable from './SecurityTable';
import Page from "../Components/Page";


const MutualFunds= ()=>{

 

    return (<>
    <Page title='Mutual Funds'/>
    <Row className='border border-2 border-dark mb-3'>
        <h2 className='roboto-font ttsec-blue text-center mt-4'>
            Mutual Funds Data
        </h2>
        <p className='fs-6 text-center text-danger mb-4'>
            Click on the ticker for each company to get more details.
        </p>
        <SecurityTable sec_type={"MUTUAL_FUND"} />
    </Row>
    </>);
}

export default MutualFunds;