import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import {useOutletContext, } from 'react-router-dom';
import {  useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import LoadingSpinner from '../Components/LoadingSpinner';
import { FaLongArrowAltDown, FaLongArrowAltUp, FaMinus } from "react-icons/fa";
import Page from "../Components/Page";

const LeaderBoard = ()=>{

    const[showPage, setShowPage] = useState(false);
    const [leadData, setLeadData] = useState([]);
    const [gameData, setGameData] = useOutletContext();
    var CurrencyFormat = require('react-currency-format');
    var ordinal = require('ordinal')

    useEffect(() => {
        getLeaderboardData();
      }, []);

    const getLeaderboardData = async () =>{
        const response = await fetch('/gameapi/questLeaderboard/', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
              qid: `${gameData.quest.id}`
             })
        });
        const data = await response.json(); 
        if(! ('message' in data))  {    //24th may  update: all_pending_transactions   in views
            setLeadData(data);
        }  
        setShowPage(true);
      }


    return(<>
    <Page title='Leaderboard'/>
    <Row className='border border-2 border-dark mb-5'>

        

    <h1 className='mt-3 mb-3 quest-play-sectionheadings2 text-center '>
   Leaderboard
        </h1>
 

 

    {(showPage) ?
    <>
    <Row className='mt-4 mb-5'> 
        <Col xs={1}></Col>   
        <Col xs={10} > {/* main section*/}

        {(leadData.length == 0) ?
        <Row className='mb-3'>
            
            <p className='currency fs-4 text-center text-primary'>
                The Leaderboard will become active within 24 hours of the first trade.
            </p>
        </Row>
        :
        <>
        <Table responsive='lg' hover striped className='px-5'>
            <thead>
            <tr className='bonds-inv-c2 fs-4 currency '>
                    <th width='15%'>  </th>    
                    <th width='15%'>  </th>                  
                    <th width='25%'> Player </th>                    
                    <th width='10%'> Rank </th>
                    <th width='5%'> </th>
                    <th width='30%' className='text-end'> Portfolio Value </th>
                    
                </tr>
            </thead>
            <tbody>
            { leadData.map((lb, index)=>(
                <tr key={index} className='fs-5 roboto-font'> 
                <td className='text-center'>                
                <Image  src={require(`../assets/images/${lb.player_image}`)}   width="45px" height="auto" />
                </td>
                <td>
                    {(index == 0) ?
                    <Image  src={require(`../assets/images/gold_m.png`)}   width="45px" height="auto" />
                    :
                    <>
                    {(index == 1) ?
                    <Image  src={require(`../assets/images/silver_m.png`)}   width="45px" height="auto" />
                    :
                    <>
                    { (index == 2) ?
                    <Image  src={require(`../assets/images/bronze_m.png`)}   width="45px" height="auto" />
                    :
                    <></>
                    }
                    </>
                    
                    }
                    </>
                    }

                </td>
                <td >               
                    {lb.player_name}
                 </td>
                {(parseInt(lb.rank) <= 10)?
                    <td className='text-primary'> {ordinal(lb.rank)} </td>
                :
                    <td> {ordinal(lb.rank)} </td>
                }
                
                
                
                <td>
                    {(parseInt(lb.previous_rank) <= 0) ?
                        <FaMinus size={25} style={{ fill: 'teal' }} />
                        :
                        <>
                        {(parseInt(lb.rank) < parseInt(lb.previous_rank)) ?
                        <FaLongArrowAltUp size={25} style={{ fill: 'limegreen'}} />
                        :
                        <>
                        {(parseInt(lb.rank) > parseInt(lb.previous_rank)) ?
                            <FaLongArrowAltDown size={25} style={{ fill: 'orange' }} />
                            :
                            <FaMinus size={25} style={{ fill: 'teal' }} />
                        }
                        </>
                        
                    }
                        
                        </>
                        
                    }
                    
                
                </td>

                <td className='fs-4 text-end currency fw-bold'>
                <CurrencyFormat value={parseFloat(lb.portfolio_value)}  
                            fixedDecimalScale={2} decimalScale={2} displayType={'text'} 
                            thousandSeparator={true} prefix={'$'} 
                    />   
                </td>                
                
                
                
                
            </tr>
        ))}

            </tbody>
       
</Table>

  



            

        </>            
        }

        
        
        </Col> {/* end main section*/}
        <Col xs={1}></Col> 
    </Row>



    </>
    :
    <div className="p-3 mb-3 text-center"> 
        <LoadingSpinner/>  
    </div>

    }
    </Row>   
    </>);
}

export default LeaderBoard;