import { useNavigate, useOutletContext, Link } from 'react-router-dom';
import {useState, useEffect} from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from "react-bootstrap/Modal";
import coins from '../assets/audio/coins.mp3';
import congrats from '../assets/audio/congrats.mp3';
import Page from "../Components/Page";



const SellStocks = ()=>{
    const navigate = useNavigate();
    const [gameData, setGameData] = useOutletContext();
    const [up, setUp] = useState(0);
    const [stocks, setStocks] = useState([{}])
    const [numberStocksOwned, setNumberStocksOwned] = useState(0);
    //This is being generated
    const [pendingTrades, setPendingTrades] = useState([]);
    const [cashSpending, setCashSpending] = useState();
    const [availableCash, setAvailableCash] = useState(0);

    const [brokerName, setBrokerName] = useState("");
    const [exchFee, setExchFee] = useState(0);
    const [brFee, setBrFee] = useState(0);

    const [stocksOwned, setStocksOwned] = useState([{}]);
    //this is being retrieved from DB:
    const [pendingTransactions , setPendingTransactions] = useState([]); 

    const [sellId, setSellId] = useState(-1);
    const [sellQty, setSellQty] = useState(1);
    const [sellPrice, setSellPrice] = useState();
    const [maxSellQty, setMaxSellQty] = useState();
    const [totalSell, setTotalSell] = useState();
    const [brokerFeeSell, setBrokerFeeSell] = useState();
    const [exchangeSell, setExchangeSell] = useState();
    const [feeSell, setFeeSell] = useState();

  
    const audio = new Audio(coins);
    const audio2 = new Audio(congrats);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show1, setShow1] = useState(false);
    

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => {
        setShow2(false);
        navigate(`/game/quest/${gameData.quest.id}`);
    }
    
    const handleShow2 = () => {
        setShow2(true);
        audio2.play();
    }

    const [show4, setShow4] = useState(false);
    const handleClose4 = () => setShow4(false);
    const handleShow4 = () => setShow4(true);

    useEffect(() => {         
        getStocksOwned();
        getAvailbleCash();
        getPendingTransactions();
        getBrokerRates();
      },[]);

      useEffect(() => { 
        if (up > 0)
         calculateFees();
      },[up]);

    //////////////////////////////////////////////////////////////////////

    const getBrokerRates = ()=>{
        if (gameData.player.broker_tag === 'Elite'){
            setBrokerName('Elite Securities Limited');
            setExchFee(0.14);
            setBrFee(1.5);
        }
        else if (gameData.player.broker_tag === 'Capital'){
            setBrokerName('360 Capital Market Brokers Limited');
            setExchFee(0.14);
            setBrFee(1.25);
        }
        else{
            setBrokerName('Butterfly Interactive Brokers Limited');
            setExchFee(0.14);
            setBrFee(1.0);
        }
    }

    const getStocksOwned = async () =>{
        const response = await fetch('/gameapi/getAllStocksMFOwned/', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
              qpID: `${gameData.id}`,
             })
        });
        const data = await response.json();                  
        data.map((s, index) => {
            let sec = findStockorMF(s.stocks_mf_owned);            
            if(sec.type === 'STOCK'){
                setNumberStocksOwned(numberStocksOwned+1);
                let total = (parseFloat(sec.current_value) * parseInt(s.quantity)).toFixed(2);
                setStocksOwned(original => [...original, 
                    {   id: s.stocks_mf_owned,
                        image: sec.image,
                        name: sec.registered_entity,
                        quantity: s.quantity,
                        total_value: total
                    }
                ])
            
            }           
        });
      }


    const changeSellId = (e)=>{
        setSellId(e.target.value);  
        let p = findPrice(e.target.value);
        setSellPrice(p);
        let q = findQuantityOwned(e.target.value);
        let qtySold = 0;
        pendingTransactions.map((pt) => {
            if (pt.action === 'SELL' && pt.stocks == e.target.value)
                qtySold = qtySold + parseInt(pt.quantity);
        });
        let maxQ = q - qtySold;
        setMaxSellQty(maxQ);
      }


      const getPendingTransactions = async () =>{
        const response = await fetch('/gameapi/getAllPendingTransactions/', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
              qpID: `${gameData.id}`,
             })
        });
        const data = await response.json(); 
        if(data.id !== 'ERROR_GETTING_ALL_PENDING')  { 
            setPendingTransactions(data);
        }
        else
          alert("Something went wrong, try again later");
        
      }

      const findQuantityOwned = (sid)=>{
        const searchIndex = stocksOwned.findIndex((s) => s.id == sid);
        if (searchIndex < 0)
            return 0;
        else
            return stocksOwned[searchIndex].quantity;
     }


    const findPrice = (sid)=>{
        const searchIndex = gameData.quest.stocks_and_mf.findIndex((s) => s.id == sid);
        if (searchIndex < 0)
            return 0;
        else
            return gameData.quest.stocks_and_mf[searchIndex].current_value;
    }

    const findStockorMF = (sid)=>{
        const searchIndex = gameData.quest.stocks_and_mf.findIndex((s) => s.id == sid);
        return gameData.quest.stocks_and_mf[searchIndex];
    }

    const getAvailbleCash = ()=>{
        let spend = parseFloat(gameData.cash_value) - parseFloat(gameData.fees_owed);
        setAvailableCash(spend.toFixed(2));
    }

    const changeSellQty = (e)=>{
        setSellQty(e.target.value);  
    }

    const addTrade = (e)=>{
        e.preventDefault();
        let added = alreadyAdded(sellId);
        if(added)
        {
            handleShow();
            return;
        }
        let sec = findStockorMF(sellId);
        let costStock = (sellQty * parseFloat(sec.current_value)).toFixed(2); 
        let s = {id: sellId, 
            name: sec.registered_entity, 
            quantity: sellQty, 
            current_price: sec.current_value,
            action: 'SELL',
            total: costStock,
            questId: gameData.id
        };
        setPendingTrades(original => [...original, s] );
        setUp(up+1);
        
    }

    const findStock = (sid)=>{
        const searchIndex = stocks.findIndex((s) => s.id == sid);
        return stocks[searchIndex];
    }

    const deleteStock = (sid)=>{
        setPendingTrades((current) =>
            current.filter((s) => {
              return s.id !== sid;
            }))

            setUp(up+1);
      }

      const alreadyAdded = (sid)=>{
        const index = pendingTrades.findIndex((s) => s.id == sid);
        if(index < 0)
            return false;
        return true;
    }

    
    ///////////////////////////////////////////////////////////////////////

    const calculateFees = ()=>{  
        let total = 0;
        pendingTrades.map((pt, index) => {
            total = total + parseFloat(pt.total);
        });      
        
        let bFee = ((brFee/100) * total);
        let eFee = ((exchFee/100) * total);
        let fees = (bFee + eFee); //just transactions fees
        setTotalSell(total.toFixed(2));
        setBrokerFeeSell(bFee.toFixed(2));
        setExchangeSell(eFee.toFixed(2));
        setFeeSell(fees.toFixed(2));

        let spend = parseFloat(gameData.cash_value) - (fees + parseFloat(gameData.fees_owed));
        setCashSpending(spend.toFixed(2));
    }

    const getquantityStocksOwned = async (sid) =>{
        const response = await fetch('/gameapi/quantityStockMFOwned/', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
              qpID: `${gameData.id}`,
              smfID : `${sid}`
             })
        });
        const data = await response.json(); 
        if(! ('message' in data)) {
            return data.amount
        }   
        else{
            return 0;
        }             
      }

    const executeTrade = async () =>{
        const response = await fetch('/gameapi/tradeStatus/', {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        });
        const data = await response.json(); 
        if(data.message === 'YES'){
            ///////////////////// data checking that data is synced with database
            const response = await fetch('/gameapi/getAllPendingTransactions/', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                  qpID: `${gameData.id}`,
                 })
            });
            const data = await response.json();
            if(data.id === 'ERROR_GETTING_ALL_PENDING')  {
                alert("Oops something went wrong, try again later.");
                navigate(`/game/`);
            }            
            
            for(let i=0; i<pendingTrades.length; i++){
                let sec_id = pendingTrades[i].id;

                let  qtyOwned = 0; 
                qtyOwned = await getquantityStocksOwned(sec_id);
                
                
                let qSold = 0;
                let amtLeft = 0;            
                data.map((pt) => {
                    if (pt.action === 'SELL' && pt.stocks == sec_id)
                        qSold = qSold + parseInt(pt.quantity);
                });
                amtLeft = qtyOwned - qSold;           
            
                if (amtLeft < parseFloat(pendingTrades[i].quantity)){
                    alert("Your data is out of sync, unable to complete trade.");
                    navigate(`/game/`);
                    return;
                    
                } 
            }

            ///////////////////////////////////////
            executeSell();
        }
        else
            handleShow4();

    }



     const executeSell = ()=>{
        let v = JSON.stringify(pendingTrades)
        let s = `{"stocks" : ${v}, "fees": "${feeSell}", "qpID": "${gameData.id }"}`;        
        let totalFee = (parseFloat(feeSell) + parseFloat(gameData.fees_owed)).toFixed(2);
        setGameData(original => ({...original, fees_owed: totalFee}))
        sellStocks(s);     
    }

    const sellStocks = async (jsonString) =>{
        const response = await fetch('/gameapi/addPendingTransaction/', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: jsonString
        });
        const data = await response.json(); 
        if(data.message === 'PENDING_VALUES_UPDATES'){
            handleShow2();
            setPendingTrades([]);
        }
        else
            alert('Unable to complete transaction, try agina later');       
        
      }

      

      
    

return(<>
    <Page title='Stock Trading - Sell'/>
    <Row className='border border-2 border-dark mb-3'>

    {(numberStocksOwned === 0)?
    <>
    <Row className='my-3'> 
        <Col xs={1}></Col>

        <Col xs={10}>
        <h1 className='my-3 trading-main-header text-center '>Stock Trading 
            <span className='roboto-font fs-3 inv-color'> (Sell) </span>         
        </h1>
        <h3 className='my-3 roboto-font text-center '>You currently do not own any stocks to sell.</h3>
        </Col>
        <Col xs={1}></Col>
    </Row>
    </>
    :
    <>

<Row className='my-3'> 
        <Col xs={0} sm={1}></Col>

        <Col sm={10}>
        <h1 className='my-3 trading-main-header text-center '>Stock Trading 
            <span className='roboto-font fs-3 inv-color'> (Sell) </span>         
        </h1>
        <Table bordered className='mt-3 mb-2 px-5 bg-dark'>
            <tbody>
            <tr >
                <td className='fs-4 currency text-info'>
                    Available Cash: ${availableCash} 
                    <span className='fs-5 text-light'> (Cash Value - Pending Fees) </span></td>
            </tr>
            </tbody>
            
        </Table>

        <Row className="mb-4 ">   {/* start heading info */} 

        <Col >                    
        <Table className='p-2 my-2'>
              <tr className='border-bottom'>
                    <td className="quest-play-subheading2">  Broker:</td>
                    <td className= "fs-4 currency text-end "> {brokerName} </td>
                </tr>

                <tr className='border-bottom'>
                    <td className="quest-play-subheading2">  Broker Fees:</td>
                    <td className= "fs-4 currency text-end "> 
                    <span className='text-primary'> {brFee.toFixed(2)}% </span> of Transaction </td>
                </tr>

                <tr className='border-bottom'>
                    <td className="quest-play-subheading2">  Stock Exchange Fees:</td>
                    <td className= "fs-4 currency text-end "> 
                    <span className='text-primary'> {exchFee.toFixed(2)}% </span> of Transaction </td>
                </tr>
                
            </Table>                     
        </Col>   
                             
    </Row> {/* end heading info */}

        <h3 className='my-3 trade-heading1'>Select Stocks to Sell: </h3>
        <Form className=' mb-3 py-1' onSubmit={addTrade} > 
        <Row className='px-1'>
              <Col md={12} lg={6}>   
              <Form.Group className="mb-1" >
                <Form.Label className="form-labels fs-5">
                  Company:
                </Form.Label>
                <Form.Select className="mb-3"  value={sellId}   onChange={changeSellId} required>
                { stocksOwned.map((s, index)=>(                            
                    <option key={s.id} value={s.id}> {s.name}  </option>
                ))}
            
                </Form.Select>
            </Form.Group>
            </Col>  
            <Col md={12} lg={6}>
            <Form.Group className="mb-1" >
              <Form.Label className="form-labels fs-5">
                Quantity: 
              </Form.Label>
              <Form.Control type="number" min='1' max={maxSellQty} value={sellQty} onChange={changeSellQty}  required/>
            </Form.Group>
            </Col>
            </Row>

            <Row>
            <Col md={12} lg={6}> 
             <Form.Group className="mb-1" >
              <Form.Label className=" currency fs-4">
                Market Price: ${sellPrice}
              </Form.Label>              
            </Form.Group>
            </Col>
           
            <Col md={12} lg={6}> 
            <Form.Group className="mb-1" >
              <Form.Label className="roboto-font fs-5">
                Max number of shares: {maxSellQty}
              </Form.Label>              
            </Form.Group>
              <div className="text-end">
            
            <Button variant="warning" type="submit" >
              Add Stock
            </Button>
            
            </div>  
              </Col>

            </Row>
        </Form>

        </Col>

        <Col xs={0} sm={1}></Col>
    </Row>

    
    <Row className='my-3 ' >
            

            <Col sm={12} md={1}></Col>

            <Col sm={12} md={10}>
            <h3 className='my-3 trade-heading2 '> Sell Order: </h3>     
            {(pendingTrades.length === 0) ?
                <p className='text-secondary fs-5 '> No Stocks selected - Add stocks in order to sell them. </p>
            :
            <>
                <Table  bordered className='my-3'>
                    <thead className='fs-5 text-white bg-secondary text-center'>
                        <th> Company</th>
                        <th> Market Price </th>
                        <th> Quantity </th>
                        <th> Sub-Total </th>
                        <th> </th>
                    </thead>
                    <tbody className='fs-5  '>
                    {pendingTrades.map((pt, index)=>(
                    <tr key={index} className='fs-5 currency '> 
                        <td> {pt.name} </td>
                        <td className='text-end'> ${pt.current_price}</td>
                        <td className='text-center'> {pt.quantity}  </td>
                        <td className='text-end'> ${pt.total} </td>   
                        <td> <Button variant="danger" size='sm'
                               onClick={()=>deleteStock(pt.id)}> Remove </Button>
                        </td>                 
                    </tr>
                ))}

                <tr className='currency'>              
                    <td colSpan={3} className='text-end bg-primary fw-bold '> Cash to be Earned: </td>
                    <td className='text-end bg-primary fw-bold'>${totalSell} </td>
                    <td></td>
                </tr>
                <tr className='currency'>              
                    <td colSpan={3} className='text-end bg-light fw-bold text-primary'> Broker Fee: </td>
                    <td className='text-end bg-light' >${brokerFeeSell}  </td>
                    <td></td>
                </tr>
                <tr className='currency'>              
                    <td colSpan={3} className='text-end bg-light fw-bold text-primary'> Stock Exchange Fee: </td>
                    <td className='text-end bg-light'>${exchangeSell}  </td>
                    <td></td>
                </tr>
                <tr className='currency'>              
                    <td colSpan={3} className='text-end bg-warning fw-bold'> Total Fees: </td>
                    <td className='text-end fw-bold bg-warning'>${feeSell}  </td>
                    <td></td>
                </tr>

                <tr>              
                    <td colSpan={5} className='text-end '> 
                    {(cashSpending >= 0)?
                    <Button variant="primary" 
                        onClick={executeTrade}> Place Order </Button>
                    :
                    <p className='fs-6 text-danger'> *You do not have enough cash to complete this trade.</p>
                    }
                    
                    
                    </td>
                    
                </tr>

                    </tbody>
                </Table>
            
            </>
            }

            </Col>

            <Col sm={12} md={1}>
            </Col>

    </Row>
    
    </>
    }
    
    
    </Row>

    
    <Modal show={show} onHide={handleClose} centered>  
        <Modal.Header closeButton className='bg-dark py-1'>
            <Modal.Title className='text-light fs-3 text-center  '> 
              Selling Stocks
            </Modal.Title>
          </Modal.Header>      
        <Modal.Body className='bg-light'> 
            <p className='fs-5 text-primary '> This Stock has already been added. </p> 
                    
        </Modal.Body>
        <Modal.Footer className='bg-dark py-1'>
            <Button variant="warning" onClick={handleClose}> Continue </Button>             
        </Modal.Footer>
    </Modal>

    
    <Modal show={show2} onHide={handleClose2} centered backdrop='static' keyboard={false}>  
        <Modal.Header closeButton className='bg-dark py-1'>
            <Modal.Title className='text-light fs-3 text-center  '> 
              Trading
            </Modal.Title>
          </Modal.Header>      
        <Modal.Body className='bg-light'> 
            <p className='fs-5 text-primary'> Your trade has been successfully posted. It would be executed 
             within 24 hours. </p> 
                    
        </Modal.Body>
        <Modal.Footer className='bg-dark py-1'>
            <Button variant="warning" onClick={handleClose2}> Continue </Button>             
        </Modal.Footer>
    </Modal>

    <Modal show={show4} onHide={handleClose4} centered backdrop='static' keyboard={false}>  
        <Modal.Header closeButton className='bg-dark py-1'>
            <Modal.Title className='text-light fs-3 text-center  '> 
              Trading
            </Modal.Title>
          </Modal.Header>      
        <Modal.Body className='bg-light'> 
            <p className='fs-5 text-primary'> Trading is currently closed while the system is being
             updated. Try back again in an hour.
            </p> 
                    
        </Modal.Body>
        <Modal.Footer className='bg-dark py-1'>
            <Button variant="warning" onClick={handleClose4}> Continue </Button>             
        </Modal.Footer>
    </Modal>


   


           

           
</>);
}

export default SellStocks;