import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import { DateTime } from "luxon";

const QuestTable = ({questData})=>{
    const navigate = useNavigate();
    return(<>

<Table striped hover className='my-3' responsive>
            <thead>    
                <tr>
                <th className=' text-danger fs-5'>Name</th>
                <th className='text-center text-danger fs-5'>Start Date</th>
                <th className='text-center text-danger fs-5'>End Date</th>
                 <th className='text-center text-danger fs-5'>Status</th>
                 <th></th>
                </tr>  
            </thead>
            <tbody className='admin-table-data'>
                { questData.map((q) =>(
                    <tr key={q.id}>
                        <td>{q.name}</td>
                        <td className='text-center'>{
                        DateTime.fromISO(q.start_date).toLocaleString(DateTime. DATE_MED)
                        }</td>
                        <td className='text-center'>{
                        DateTime.fromISO(q.end_date).toLocaleString(DateTime. DATE_MED)
                        }</td>
                        <td className='text-center'>{q.status}</td>
                        <td className='text-center'>
                        {(q.status === "UNPUBLISHED")?
                            <Button variant="primary"                             
                            className='mx-2' 
                            size='sm' 
                            onClick={() => navigate(`/admin/unpublishedQuest/${q.id}`)}>             
                            View Details
                            </Button>
                            :
                            <>
                                {(q.status === "PUBLISHED") ?
                                    <Button variant="primary"                              
                                    className='mx-2' 
                                    size='sm' 
                                    onClick={() => navigate(`/admin/publishedQuest/${q.id}`)}>                
                                    View Details
                                    </Button>
                                :
                                <>
                                {(q.status === "ACTIVE") ?
                                    <Button variant="primary"                              
                                    className='mx-2' 
                                    size='sm' 
                                    onClick={() => navigate(`/admin/activeQuest/${q.id}`)}>                
                                    View Details
                                    </Button>
                                :
                                <> 
                                <Button variant="primary"                              
                                    className='mx-2' 
                                    size='sm' 
                                    onClick={() => navigate(`/admin/completedQuest/${q.id}`)}>                
                                    View Details
                                    </Button>
                                </>
                                }
                                 
                                </>  
                                
                                }
                            
                           </> 
                        }
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    
    </>);
}

export default QuestTable;