import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import {useNavigate, Link, useLocation} from "react-router-dom";
import { useState, useEffect, useContext } from "react";





const NewPlayerNavBar = ()=>{
    

    const location = useLocation(); // once ready it returns the 'window.location' object
    const [url, setUrl] = useState(null);
    
    useEffect(() => {
        setUrl(location.pathname);
    }, [location]);


    return(
        <>
    <Navbar bg="dark" variant="dark" expand="lg" className="px-5 mb-4">

    <Navbar.Brand as={Link} to="/"> 
       <span className="inv-color roboto-font">Inve$tor</span> <span className="quest-color roboto-font">Quest TT 2.0</span> 
      </Navbar.Brand>
      
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="container-fluid ">
          
        <Nav.Item className="mx-3 my-2">
          <span className="text-light fw-bold"> Getting Started </span>        
        </Nav.Item>

          <Nav.Item className="ms-auto px-1">
        
        </Nav.Item>             
            

        
        </Nav>
      </Navbar.Collapse>
    
  </Navbar>
        </>
    );
}

export default NewPlayerNavBar;