import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { useOutletContext } from 'react-router-dom';
import {  useState,  useEffect } from 'react';
import LoadingSpinner from '../Components/LoadingSpinner';
import HistoryTable from './HistoryTable';

const HistoryByType = ({sec_type})=>{    
    const [gameData, setGameData] = useOutletContext();
    var CurrencyFormat = require('react-currency-format');

    const [transactions, setTransactions] = useState([]);
    const[showPage, setShowPage] = useState(false);
    const [query, setQuery] = useState("");

    useEffect(() => {
        getPendingTransactions();
      }, []);
    
    const getPendingTransactions = async () =>{
        const response = await fetch('/gameapi/allTransactionHistory/', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
              qpID: `${gameData.id}`,
              type: `${sec_type}`,
             })
        });
        const data = await response.json(); 
        if(! ('message' in data))  {    //24th may  update: all_pending_transactions   in views
            
           data.map((t, index) => {
            let unitCost = parseFloat(t.total) / parseFloat(t.quantity);
            setTransactions(original => [...original, 
                {
                 image: t.s_mf_image,
                 name: t.s_mf_name,
                 action: t.action,
                 cost: unitCost,
                 total: t.total,
                 quantity: t.quantity,
                 date_bought: t.date_bought
               }
            ])
        });
        }  
        setShowPage(true);
      }

      const search = (transactions) =>{
        return transactions.filter((t) =>
            t.name.toLowerCase().startsWith(query.toLowerCase()) ||
            t.action.toLowerCase().startsWith(query.toLowerCase()) 
        );
    }

    return(<>
    <Row className='border border-2 border-dark mb-5'>

       

        <h1 className='mt-3 mb-3 quest-play-sectionheadings2 text-center '>
        {(sec_type === 'STOCK') ?
            <span>Stocks Transaction History</span>
        :
            <span>Mutual Funds Transaction History</span>
        }
             </h1>
                           
            <p className='fs-6 text-danger  text-center currency'> 
                *Transaction History for the last 90 days only is shown.
            </p>  
        

         

        {(showPage) ?
        <>
        <Row className='mb-2'> 
            <Col xs={1}></Col>   
            <Col xs={10} > {/* main section*/}

            {(transactions.length == 0) ?
            <Row className='mb-3'>
                <p className='currency fs-4 text-center text-primary'>
                    There are currently no transaction details to display.
                </p>
            </Row>
            :
            <>
            
            <Row className='mb-3'>
            <Col>
            <Form.Group className="my-3"> 
            <Form.Label className="admin-form-labels fs-6" >
                 Filter Transactions - Enter Company or Action (Buy/Sell):
              </Form.Label>                 
                <Form.Control type="text"  onChange={e=> setQuery(e.target.value)}/>              
            </Form.Group>
            </Col>
            
        </Row>


        <Row> {/* body row*/}
        <HistoryTable history={search(transactions)}/>
       
        </Row> {/* close body row*/}
        

      
                

            </>            
            }

            
            
            </Col> {/* end main section*/}
            <Col xs={1}></Col> 
        </Row>
    

        
        </>
        :
        <div className="p-3 mb-3 text-center"> 
            <LoadingSpinner/>  
        </div>

        }
     </Row>   

        
    
    </>);
}

export default HistoryByType;