import { useState, useEffect } from 'react';
import { useParams, useNavigate} from 'react-router-dom';
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import Button from 'react-bootstrap/Button'; 
import { DateTime } from "luxon";
import LoadingSpinner from '../Components/LoadingSpinner';
import {getApplicableAgeGroupsDescriptionForAdmin} from '../utils/quest';

const ActiveQuest = ()=>{
    const navigate = useNavigate();
    const{questId} = useParams();
    const [loadPage, setLoadPage] = useState(false); 
    const [quest, setQuest] = useState({});
    const [leadData, setLeadData] = useState([]);
    const [nPlayers, setNPlayers] = useState(0);

    var CurrencyFormat = require('react-currency-format');
    var ordinal = require('ordinal')

    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);

    const [show2, setShow2] = useState(false); 
    const handleClose2 = () => {
      setShow2(false);      
    }

  const handleShow2 = (id) => {
    getLeaderboardData(id);
    setShow2(true);
  }

  const [show3, setShow3] = useState(false); 
    const handleClose3 = () => {
      setShow3(false);      
    }

  const handleShow3 = () => {
    getNumberPlayers();
    setShow3(true);
  }

  const getNumberPlayers = async () =>{
    const response = await fetch('/gameapi/numberQuestPlayers/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            qid: `${questId}`,
         })
    });
    const data = await response.json();
    setNPlayers(data.number_players);
}

  const getLeaderboardData = async (id) =>{
    const response = await fetch('/gameapi/questLeaderboard/', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          qid: `${id}`
         })
    });
    const data = await response.json(); 
    if(! ('message' in data))  {    //24th may  update: all_pending_transactions   in views
        setLeadData(data);
    }  
  }

    useEffect(() => { 
        getQuestDetails();       
    },[]);

    const getQuestDetails = async () =>{
        const response = await fetch('/gameapi/getQuestById/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                questId: `${questId}`,
             })
        });
        const data = await response.json();
        data.applicableAgeGroupsDescription = getApplicableAgeGroupsDescriptionForAdmin(data.applicable_age_groups);
        setQuest(data);
        setLoadPage(true);
    }

    const closeQuest = async () =>{
        handleClose();
        const response = await fetch('/gameapi/closeQuest/', {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                qid: `${questId}`,
             })
        });
        const data = await response.json();
        if(data.message === "ERROR_CLOSING_QUEST"){
            alert('Something went wrong! Try again in a while.');
        }
        else{
            handleShow1();
        }
    }

    
    return(<>
    <Row >
        <Col md={12} lg={1}>
        </Col>
        <Col md={12} lg={10}>
        <Row className="p-1  my-1">             
            <h2 className="mb-3 text-center form-heading"> Active Quest Details </h2>
            <h5 className="mb-3 text-center text-secondary"> (Players can join this Quest)</h5>               
            </Row> 

            <Row className='my-2'>
            {(loadPage) ?
            <>
            <Table bordered>
                    <tbody>
                        <tr>
                            <td>                            
                            <Button variant="primary"   className='mx-2'  
                            onClick={()=>handleShow2(questId)}>                
                                Leaderboard
                            </Button>

                            <Button variant="warning"   className='mx-2'  
                            onClick={()=>handleShow3()}>                
                                Number Players
                            </Button>

                         
                            {  (quest.type === 'SHORT')?
                            <>
                            <Button variant="danger"   className='mx-2'  
                            onClick={handleShow}>                
                                Close Quest
                            </Button>
                            <span className='text-primary f2-6'>
                                (Only use this button after the end date)</span>
                            </>
                            :
                            <></>
                            }

                            </td>
                        </tr>
                    </tbody>
                </Table>
                
            

            
                
                <Table bordered border-1>
                    <thead className='bg-dark'>
                        <tr>
                            <th  className='fs-5 text-white p-2' colSpan={2}>Quest Details:</th>
                            
                        </tr>
                    </thead>
                    <tbody className='px-1'>
                        <tr>
                            <td width="20%" className='fs-5 text-primary'> Quest Name</td>
                            <td className='fs-5'> {quest.name} </td>
                        </tr>
                        <tr>
                            <td className='fs-5 text-primary'> Start Date</td>
                            <td className='fs-5'> 
                            {
                            DateTime.fromISO(quest.start_date).toLocaleString(DateTime. DATE_HUGE)
                            }
                            </td>
                        </tr>
                        <tr>
                            <td className='fs-5 text-primary'> End Date</td>
                            <td className='fs-5'> 
                            {
                            DateTime.fromISO(quest.end_date).toLocaleString(DateTime. DATE_HUGE)
                            }
                            </td>
                        </tr>
                        <tr>
                            <td className='fs-5 text-primary'>Registration End Date</td>
                            <td className='fs-5'>
                                {
                                    quest.registration_end_date &&
                                    DateTime.fromISO(quest.registration_end_date).toLocaleString(DateTime. DATE_HUGE)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td className='fs-5 text-primary'> Description</td>
                            <td className='fs-5'> {quest.description} </td>
                        </tr>
                        <tr>
                            <td className='fs-5 text-primary'> Start Value </td>
                            <td className='fs-4 currency'> 
                            <CurrencyFormat value={parseFloat(quest.start_value)}  
                            fixedDecimalScale={2} decimalScale={2} displayType={'text'} 
                            thousandSeparator={true} prefix={'$'} 
                            /> 
                             </td>
                        </tr>
                        <tr>
                            <td className='fs-5 text-primary'> Age Restriction </td>
                            <td className='fs-5'>{quest.applicableAgeGroupsDescription}</td>
                        </tr>
                        <tr>
                            <td className='fs-5 text-primary'> Bonus Question</td>
                            <td className='fs-5'> {quest.bonus_question} </td>
                        </tr>
                        <tr>
                            <td className='fs-5 text-primary'> Answer A</td>
                            <td className='fs-5'> {quest.choice_A} </td>
                        </tr>
                        <tr>
                            <td className='fs-5 text-primary'> Answer B</td>
                            <td className='fs-5'> {quest.choice_B} </td>
                        </tr>
                        <tr>
                            <td className='fs-5 text-primary'> Answer c</td>
                            <td className='fs-5'> {quest.choice_C} </td>
                        </tr>
                        <tr>
                            <td className='fs-5 text-primary'> Answer D</td>
                            <td className='fs-5'> {quest.choice_D} </td>
                        </tr>
                        <tr>
                            <td className='fs-5 text-primary'> Answer </td>
                            <td className='fs-5'> {quest.answer} </td>
                        </tr>
                        <tr>
                            <td className='fs-5 text-primary'> Bonus Amount</td>
                            
                            <td className='fs-4 currency'> 
                            <CurrencyFormat value={parseFloat(quest.bonus_amount)}  
                            fixedDecimalScale={2} decimalScale={2} displayType={'text'} 
                            thousandSeparator={true} prefix={'$'} 
                            /> 
                            </td>
                        </tr>
                    </tbody>
                </Table>

                <Table className='my-4'>
                    <thead className='bg-dark'>
                        <tr>
                            <th className='fs-5 text-white p-2' > Stocks Added to Quest:</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(quest.stocks_and_mf.length == 0)?
                        <>
                        <tr> <td className='text-center fs-4 text-danger'> 
                            No STOCKS have been added to this quest. 
                        </td>
                        
                        </tr>
                        </>
                        :
                        <>
                        {quest.stocks_and_mf.map((s)=>( 
                            <tr key={s.id} className='mx-5'> 
                            {
                                (s.type === "STOCK") ?
                                <>
                                <td> 
                                <Image  src={require(`../assets/logos/${s.image}`)}  rounded width="50px" height="auto" />
                                <span className='fs-5  mx-4'>{s.registered_entity} </span>
                             </td>
                                </>
                                :
                                <></>
                            }                              
                             
                             
                            </tr>
                        ))}
                        </>
                        }
                        
                    </tbody>
                </Table>

                <Table className='my-4'>
                    <thead className='bg-dark'>
                        <tr>
                            <th className='fs-5 text-white p-2' > Mutual Funds Added to Quest:</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(quest.stocks_and_mf.length == 0)?
                        <>
                        <tr> <td className='text-center fs-4 text-danger'> 
                            No MUTUAL FUNDS have been added to this quest. 
                        </td>
                        
                        </tr>
                        </>
                        :
                        <>
                        {quest.stocks_and_mf.map((s)=>( 
                            <tr key={s.id} className='mx-5'> 
                            {
                                (s.type === "MUTUAL_FUND") ?
                                <>
                                <td> 
                                <Image  src={require(`../assets/logos/${s.image}`)}  rounded width="50px" height="auto" />
                                <span className='fs-5  mx-4'>{s.registered_entity} </span>
                             </td>
                                </>
                                :
                                <></>
                            }                              
                             
                             
                            </tr>
                        ))}
                        </>
                        }
                        
                    </tbody>
                </Table>

                <Table className='my-4'>
                    <thead className='bg-dark'>
                        <tr>
                            <th className='fs-5 text-white p-2' > Bonds Added to Quest:</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(quest.bonds == 0)?
                        <>
                        <tr> <td className='text-center fs-4 text-danger'> 
                            No BONDS have been added to this quest. 
                        </td>
                        
                        </tr>
                        </>
                        :
                        <>
                        {quest.bonds.map((b)=>( 
                            <tr key={b.id} className='mx-5'> 
                            <td> 
                                <Image  src={require(`../assets/logos/${b.image}`)}  rounded width="50px" height="auto" />
                                <span className='fs-5  mx-4'>{b.registered_entity} </span>
                             </td>
                            </tr>
                        ))}
                        </>
                        }
                        
                    </tbody>
                </Table>

            
            </>
            :
            <>
            <div className="p-3 mb-3 text-center">
                <LoadingSpinner/>
            </div>
            </>
            }
           
            
        </Row>
        </Col>
        

    <Col  md={12} lg={1}>
    </Col>

    </Row>

    <Modal show={show} onHide={handleClose} backdrop='static' centered>  
        <Modal.Header closeButton className='bg-dark py-1'>
            <Modal.Title className='text-light fs-3 text-center  '> 
              Close Quest
            </Modal.Title>
          </Modal.Header>      
        <Modal.Body className='bg-light'> 
            <p className='fs-4 '> Are you sure it's time to CLOSE this quest? </p> 
                    
        </Modal.Body>
        <Modal.Footer className='bg-dark py-1'>
            <Button variant="warning" onClick={closeQuest}> Confirm </Button> 
            <Button variant="warning" onClick={handleClose}> Cancel </Button> 
        </Modal.Footer>
    </Modal> 

    <Modal show={show1} onHide={handleClose1} backdrop='static' centered>  
        <Modal.Header closeButton className='bg-dark py-1'>
            <Modal.Title className='text-light fs-3 text-center  '> 
              Close Quest
            </Modal.Title>
          </Modal.Header>      
        <Modal.Body className='bg-light'> 
            <p className='fs-4 '> Quest Closed. </p> 
                    
        </Modal.Body>
        <Modal.Footer className='bg-dark py-1'>
            <Button variant="warning" onClick={() => navigate('/admin/manageQuest/')}> Continue </Button>             
        </Modal.Footer>
    </Modal>

    <Modal show={show2} onHide={handleClose2} scrollable={true} size="lg" >  
        <Modal.Header closeButton className='bg-dark py-1'>
            <Modal.Title className='text-light fs-3 '> 
              Leaderboard
            </Modal.Title>
          </Modal.Header>      
        <Modal.Body > 
        <Table responsive='lg' className='px-5'>
            <thead>
                <tr className='bonds-inv-c2 fs-4 currency '>
                    <th width='15%'>  </th>                    
                    <th width='20%'> Player </th>
                    <th width='20%'> Rank </th>
                    <th width='25%' className='text-end'> Portfolio Value </th>
                    
                </tr>
            </thead>
            <tbody>
            { leadData.map((lb, index)=>(
                <tr key={index} className='fs-5 roboto-font'> 
                <td className='text-center'>                
                <Image  src={require(`../assets/images/${lb.player_image}`)}   width="45px" height="auto" />
                </td>
                <td >               
                    {lb.player_name}
                 </td>
                {(parseInt(lb.rank) <= 10)?
                    <td className='text-primary'> {ordinal(lb.rank)} </td>
                :
                    <td> {ordinal(lb.rank)} </td>
                }
                
                
                
                <td className='fs-4 text-end currency fw-bold'>
                <CurrencyFormat value={parseFloat(lb.portfolio_value)}  
                            fixedDecimalScale={2} decimalScale={2} displayType={'text'} 
                            thousandSeparator={true} prefix={'$'} 
                    />   
                </td>                
                
                
                
                
            </tr>
        ))}

            </tbody>
       
</Table>


                            
        </Modal.Body>
        <Modal.Footer className='bg-dark py-1'>
            
            <Button variant="warning" onClick={handleClose2}> Close </Button> 
        </Modal.Footer>
    </Modal>


    <Modal show={show3} onHide={handleClose3} scrollable={true}  centered>  
        <Modal.Header closeButton className='bg-dark py-1'>
            <Modal.Title className='text-light fs-3 '> 
              Quest Players
            </Modal.Title>
          </Modal.Header>      
        <Modal.Body > 
            <p className=' fs-5'> Number of player in this Quest: <span className='text-primary'> {nPlayers} </span>  </p> 

                            
        </Modal.Body>
        <Modal.Footer className='bg-dark py-1'>
            
            <Button variant="warning" onClick={handleClose3}> Close </Button> 
        </Modal.Footer>
    </Modal>


   

    </>);
}

export default ActiveQuest;