import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useNavigate,  useOutletContext } from 'react-router-dom';
import {  useState,  useEffect } from 'react';
import coins from '../assets/audio/coins.mp3';
import Page from "../Components/Page";


const BonusQuestion = ()=>{
    
    const [gameData, setGameData] = useOutletContext();
    const [yourAnswer, setYourAnswer] = useState("");
    const [correctAnswer, setCorrectAnswer] = useState("");

    const audio = new Audio(coins);
    var CurrencyFormat = require('react-currency-format');

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show1, setShow1] = useState(false);
    const handleClose1 = () => {        
        setShow1(false);
    }
    
    const handleShow1 = () => {
        setShow1(true);
        audio.play();
    }

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => {
        setShow2(false);
    }
    const handleShow2 = () => setShow2(true);

    const [show3, setShow3] = useState(false);
    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => setShow3(true);

    useEffect(() => {
        if (gameData.quest.answer === 'A')
            setCorrectAnswer(gameData.quest.choice_A);
        else if (gameData.quest.answer === 'B')
        setCorrectAnswer(gameData.quest.choice_B);
        else if (gameData.quest.answer === 'C')
        setCorrectAnswer(gameData.quest.choice_C);
        else
            setCorrectAnswer(gameData.quest.choice_D);
      }, []);

    const handleChange = (e) => {
        e.persist();
        setYourAnswer(e.target.value);
      };

    const handleSubmit = (event)=>{
        event.preventDefault();
        if(yourAnswer === ''){
            handleShow();
            return;
        }
        if(yourAnswer === gameData.quest.answer){
            let added = answeredCorrect();
            if(added){
                let bonus = parseFloat(gameData.quest.bonus_amount)
                let cash = parseFloat(gameData.cash_value)
                let totalCash = cash + bonus
                let tc = totalCash.toFixed(2)
                setGameData(original => ({...original, cash_value: `${tc}`}))

                let totalCurrent = parseFloat(gameData.current_value) + bonus
                let newCurrent = totalCurrent.toFixed(2);
                setGameData(original => ({...original, current_value: `${newCurrent}`}))
                setGameData(original => ({...original, question_answered: 'YES'}))
                handleShow1();
            }
            else{ handleShow3(); }            
        }            
        else             
            handleShow2();
            answeredWrong();
            setGameData(original => ({...original, question_answered: 'YES'}))
      }

      const answeredCorrect = async () =>{
        const response = await fetch('/gameapi/bonusQuestionCorrect/', {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                qpID: `${gameData.id}`,
                bonus_amount: `${gameData.quest.bonus_amount}`
             })
        });
        const data = await response.json();
        if(data.message === 'BONUS_AMOUNT_ADDED')
            return true;
        return false;
    }

    const answeredWrong = async () =>{
        const response = await fetch('/gameapi/bonusQuestionWrong/', {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                qpID: `${gameData.id}`,
             })
        });
        const data = await response.json();
    }
    

    return (<>
        <Page title='Bonus Question'/>
    <Row className='border border-2 border-dark mb-3'>
    
    <Row className='my-3'> 
        <Col xs={1} ></Col>

        <Col xs={10} >
        <h1 className='mt-3 mb-2 trade-heading1 text-center'>Bonus Question</h1>
        

        {(gameData.question_answered === 'NO')?
        <>
        <p className='mt-3 fs-3 roboto-font inv-color'> Answer the following question 
        to earn: <span className='currency text-danger'> <CurrencyFormat 
                            value={parseFloat(gameData.quest.bonus_amount)}  
                            fixedDecimalScale={2} decimalScale={2} displayType={'text'} 
                            thousandSeparator={true} prefix={'$'} 
        /> 
        </span>
        </p>
        <p className='fs-5 roboto-font inv-color'>
           <span className='fs-5 text-danger my-3'> * </span>  You only get one chance to answer this question.</p>

        <p className='fs-4  mt-5 mb-3 currency '> 
            {gameData.quest.bonus_question}</p>

            <Form  onSubmit={handleSubmit} >
            <Form.Group controlId="yourAnswer" required>
                
                <Form.Check
                value="A"
                type="radio"
                id="A"
                label={gameData.quest.choice_A}
                onChange={handleChange}
                checked={yourAnswer === "A"}
                className='my-2 fs-5 currency  '
                />
                <Form.Check
                value="B"
                type="radio"
                id="B"
                label={gameData.quest.choice_B}
                onChange={handleChange}
                checked={yourAnswer === "B"}
                className='my-2 fs-5 currency  '
                />
                <Form.Check
                value="C"
                type="radio"
                id="C"
                label={gameData.quest.choice_C}
                onChange={handleChange}
                checked={yourAnswer === "C"}
                className='my-2 fs-5 currency  '
                />
                <Form.Check
                value="D"
                type="radio"
                id="D"
                label={gameData.quest.choice_D}
                onChange={handleChange}
                checked={yourAnswer === "D"}
                className='mt-2  mb-5 fs-5 currency  '
                />
            </Form.Group>
        <Button variant="warning" type="submit">
            Submit Answer
        </Button>
       
      </Form>
        </>
        :
        <>
        <p className='text-primary text-center p-3 fs-5 currency '>
            You have already answered the bonus question in this quest.
        </p>

        <div className='fs-5 currency '>
        <p>  {gameData.quest.bonus_question} </p>  
        <ul>
            <li>{gameData.quest.choice_A} </li>
            <li>{gameData.quest.choice_B} </li>
            <li>{gameData.quest.choice_C} </li>
            <li>{gameData.quest.choice_D}</li>
        </ul>      
        <p className='mt-2 mb-5 text-danger'>  Correct Answer: {correctAnswer}</p>
        </div>

        </>
        
        }
     



        </Col>
    </Row>


    <Col xs={1} ></Col>
    </Row>

    <Modal show={show} onHide={handleClose} centered>  
        <Modal.Header closeButton className='bg-dark py-1'>
            <Modal.Title className='text-light fs-3 text-center  '> 
              Bonus Question
            </Modal.Title>
          </Modal.Header>      
        <Modal.Body className='bg-light'> 
            <p className='fs-4 '> You must select an answer. </p> 
                    
        </Modal.Body>
        <Modal.Footer className='bg-dark py-1'>
            <Button variant="warning" onClick={handleClose}> Continue </Button>             
        </Modal.Footer>
    </Modal>


    <Modal show={show1} onHide={handleClose1} static centered>  
        <Modal.Header closeButton className='bg-dark py-1'>
            <Modal.Title className='text-light fs-3 text-center  '> 
              Bonus Question
            </Modal.Title>
          </Modal.Header>      
        <Modal.Body className='bg-light'> 
            <p className='fs-3  text-center text-primary roboto-font'> CORRECT! </p> 

            <p className='fs-5 '> 
            <span className='currency'> ${gameData.quest.bonus_amount} </span> 
            would be added to your account. Your rank would be updated within 24 hours.</p> 
                    
        </Modal.Body>
        <Modal.Footer className='bg-dark py-1'>
            <Button variant="warning" onClick={handleClose1}> Continue </Button>             
        </Modal.Footer>
    </Modal>

    <Modal show={show2} onHide={handleClose2} static centered>  
        <Modal.Header closeButton className='bg-dark py-1'>
            <Modal.Title className='text-light fs-3 text-center  '> 
            Bonus Question
            </Modal.Title>
          </Modal.Header>      
        <Modal.Body className='bg-light'> 
            <p className='fs-5 '> 
            <span className='text-primary'> That's incorrect</span>, better luck next time. </p> 
                    
        </Modal.Body>
        <Modal.Footer className='bg-dark py-1'>
            <Button variant="warning" onClick={handleClose2}> Continue </Button>             
        </Modal.Footer>
    </Modal>

    <Modal show={show3} onHide={handleClose3} static centered>  
        <Modal.Header closeButton className='bg-dark py-1'>
            <Modal.Title className='text-light fs-3 text-center  '> 
            Bonus Question
            </Modal.Title>
          </Modal.Header>      
        <Modal.Body className='bg-light'> 
            <p className='fs-5 '> 
            Something went wrong, try answering the question at a later date.  </p> 
                    
        </Modal.Body>
        <Modal.Footer className='bg-dark py-1'>
            <Button variant="warning" onClick={handleClose3}> Continue </Button>             
        </Modal.Footer>
    </Modal>
    
    </>);
}

export default BonusQuestion;