import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useNavigate, useOutletContext, } from 'react-router-dom';
import {  useState, useEffect } from 'react';
import Image from 'react-bootstrap/Image';
import {  AiOutlineLineChart} from "react-icons/ai";
import {  FaLongArrowAltDown, FaLongArrowAltUp } from "react-icons/fa";
import StockMFgraph from '../stockComponents/StockMFgraph';
import Page from "../Components/Page";

const StockMarketSummary = () =>{

    const navigate = useNavigate();
    const [gameData, setGameData] = useOutletContext();
    const [stockData, setStockData] = useState([]);
    const [mfData, setMfData] = useState([]);

    const [sID, setSID] = useState(0);
    const [color, setColor] = useState('');
    const [name, setName] = useState('');

    const [show, setShow] = useState(false); 
    const handleClose = () => {
      setShow(false);      
    }
    const handleShow = (id, c, n) => {
        setSID(id);
        setColor(c);
        setName(n);
        setShow(true);
    }

    useEffect(() => {
        calculatePercentChange();
      }, []);

    const calculatePercentChange = ()=>{        
        gameData.quest.stocks_and_mf.map((sec, index) => {
            let per = ((sec.current_value - sec.previous_value) / sec.previous_value ) *100;
            let details = {
                id : sec.id,
                name : sec.registered_entity,
                image : sec.image,
                current_value: sec.current_value,
                previous_value: sec.previous_value,
                percent : per,
                color: sec.color
            }
            if (sec.type === 'STOCK'){
                setStockData(original => [...original, details]);
            }
            else{
                setMfData(original => [...original, details])
            }
                
        });        
    }




    return(<>
    <Page title='Stock Market Summary'/>
    <Row className='border border-2 border-dark mb-3'>
    
        <Row className='my-3'> 
        <Col sm={1}></Col>

        <Col sm={10} > {/* start middle/main column */}
        <h1 className='my-3 roboto-font fs-2 text-success text-center '>Stock Market Summary</h1>
        
        <Row className='my-4 border border-1 p-2'>
        <Col xs={8}>       
        <span className='fs-5 text-primary roboto-font'> 
        This is only a summary of the data available, for full details go to the Stock Exchange: </span>  
        </Col>

        <Col xs={4} className='text-end'> 
        <Button variant="outline-dark"  onClick={() => navigate("/game/stockMarket/allStocks/")}>  
        <AiOutlineLineChart  /> Stock Exchange
        </Button>
        </Col>

        </Row>

        <Row className='mb-5'> {/* Stock Data Table */}
            <p className='quest_stock-heading1 my-3'> Stock Data: </p>
            <Table  hover responsive>
            <thead className='text-secondary'>
            <tr>
                <th></th>
                <th>Name</th>
                <th className='text-end'>Today's Value</th>
                <th className='text-end'>Yesterday Close</th>
                <th className='text-end'>% Change</th>
                <th></th>
                <th width='15%'></th>
            </tr>
            </thead>
            <tbody>  
                       


            { stockData.map((s)=>( 
                <tr key={s.id}> 

                <td>
                <Image  src={require(`../assets/logos/${s.image}`)}  rounded width="50px" height="auto" />
                </td>
                 
                <td className='fw-bold'>{s.name}</td>
                <td className='currency fs-5 text-end'>${s.current_value}</td>
                <td className='currency fs-5 text-end'>${s.previous_value}</td>
                
                {(s.percent < 0) ?
                    <> 
                    <td className='text-danger text-end'>{s.percent.toFixed(1)} %</td>
                    <td><FaLongArrowAltDown size={25} style={{ fill: 'red' }} /></td>
                    </>
                    :
                    <> 
                    <td className='text-success text-end'>{s.percent.toFixed(1)} %</td>
                    <td><FaLongArrowAltUp size={25} style={{ fill: 'green' }} /></td>
                    </>

                }

                <td>
                <Button onClick={()=>handleShow(s.id, s.color, s.name)} size='sm' >
                    Price History
                </Button>
                </td>
                
                </tr> 
            ))}
        </tbody>
        </Table>
        

        </Row> {/* End Stock Data Table */}

        <Row className='mb-5'> {/* Mutual Funds Data Table */}
            <p className='quest_stock-heading1 my-3'> Mutual Funds Data: </p>
            <Table responsive hover>
            <thead className='text-secondary'>
            <tr>
                <th></th>
                <th>Name</th>
                <th className='text-end'>Today's Value</th>
                <th className='text-end'>Yesterday Close</th>
                <th className='text-end'>% Change</th>
                <th></th>
                <th width='15%'></th>
            </tr>
            </thead>
            <tbody>  
                       


            { mfData.map((m)=>( 
                <tr key={m.id}> 

                <td>
                <Image  src={require(`../assets/logos/${m.image}`)}  rounded width="50px" height="auto" />
                </td>
                 
                <td className='fw-bold'>{m.name}</td>
                <td className='currency fs-5 text-end'>${m.current_value}</td>
                <td className='currency fs-5 text-end'>${m.previous_value}</td>
                
                {(m.percent < 0) ?
                    <> 
                    <td className='text-danger text-end'>{m.percent.toFixed(1)} %</td>
                    <td><FaLongArrowAltDown size={25} style={{ fill: 'red' }} /></td>
                    </>
                    :
                    <> 
                    <td className='text-success text-end'>{m.percent.toFixed(1)} %</td>
                    <td><FaLongArrowAltUp size={25} style={{ fill: 'green' }} /></td>
                    </>

                }

            <td>
                <Button onClick={()=>handleShow(m.id, m.color, m.name)} size='sm' >
                    Price History
                </Button>
                </td>
                
                
                </tr> 
            ))}
        </tbody>
        </Table>

        
        
        </Row> {/* End Mutual Funds Data Table */}


        <Row className='mb-5'> {/* Bonds Data Table */}
            <p className='quest_stock-heading1 my-3'> Bonds Data: </p>
            <Table responsive="sm" hover  >
                <thead className='text-secondary'>
                    <tr style={{ borderBottomColor: 'red' }}>
                        <th></th>
                        <th>Name</th>
                        <th>Options</th>
                    </tr>
                </thead>
            
            

            { gameData.quest.bonds.map((b)=>( 
                <tbody key={b.id}> 
                <tr style={{ borderBottomColor: 'red' }} >
                <td width='15%' rowSpan={3}>
                <Image  src={require(`../assets/logos/${b.image}`)}  rounded width="75px" height="auto" />
                </td>
                <td className='fw-bold' rowSpan={3} width='35%'>{b.registered_entity}</td>
                <td className='text-primary fs-6'> 
                    <span className=' fw-bold fs-6'> Option 1:</span> {b.option1Y} years ( Interest Rate - {b.option1I}% )
                </td>
                </tr>

                <tr style={{ borderBottomColor: 'black' }} >                
                
                <td className='text-dark fs-6'>
                <span className=' fw-bold'> Option 2:</span> {b.option2Y} years ( Interest Rate - {b.option2I}% )</td>
                </tr>

                <tr style={{ borderBottomColor: 'red' }}>                
                
                <td className='text-success fs-6'>
                <span className=' fw-bold'> Option 3:</span> {b.option3Y} years ( Interest Rate - {b.option3I}% ) </td>

                </tr>
                
                
                
                </tbody> 
            ))}
        
        </Table>
       

        </Row> {/* End Bonds Table */}
        


               

        </Col> {/* end middle column */}

        <Col sm={1}></Col>
        </Row>
    </Row>

    <Modal show={show} onHide={handleClose} size='lg' centered>  
        <Modal.Header closeButton className='bg-dark py-1'>
            <Modal.Title className='text-light fs-3 '> 
              {name}
            </Modal.Title>
          </Modal.Header>      
        <Modal.Body className='bg-light'> 
        <StockMFgraph id = {sID} color={color}/> 
                    
        </Modal.Body>
        <Modal.Footer className='bg-dark py-1'>
            
            <Button variant="warning" onClick={handleClose}> Close </Button> 
        </Modal.Footer>
    </Modal>
    
    </>);
}

export default StockMarketSummary;