import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import { useNavigate, useOutletContext, Link } from 'react-router-dom';
import {useState, useEffect } from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import { FiEyeOff, FiEye, FiInfo } from "react-icons/fi";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Page from "./Page";




const Register2 = () =>{
    const navigate = useNavigate();
    const [details, setDetails] = useOutletContext();
    const [emailAvail, setEmailAvail] = useState(true); //false: email already registered.
    const [acceptPassword, setAcceptPassword] = useState(false); //true: password is valid
    const [validPasswordMessage, setValidPasswordMessage] = useState(""); //if password is invalid
    const [passwordMessage, setPasswordMessage] = useState("");//if passwords don't match
    const [emailMessage, setEmailMessage] = useState("");//if emails don't match
    const [showPassword, setShowPassword] = useState(false);//user can toggle seeing password

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => { //Accessing page 2 before page 1
       if(details.fName === ""){ 
            navigate("/register/")
        }
      },[]);
    
    useEffect(() => { //message if emails don't match
        if(details.email1 === "" || details.email2 === ""){
            setEmailMessage("");
        }
        else{
            if(details.email1 === details.email2){
                setEmailMessage("");
            }
            if(details.email1 !== details.email2){
                setEmailMessage("*Emails don't match");
            }
        }        
      }, [ details.email1, details.email2]);

      useEffect(() => { //message if passwords don't match
        if(details.p1 === "" || details.p2 === ""){
            setPasswordMessage("");
        }
        else{
            if(details.p1 === details.p2){
                setPasswordMessage("");
            }
            if(details.p1 !== details.p2){
                setPasswordMessage("*Passwords don't match");
            }
        }
      },[details.p1, details.p2]);

      useEffect(() => { //message if password doesn't meet the requirements
        
        if(details.p1.length === 0){
            setValidPasswordMessage("");
            setAcceptPassword(false);
        }
        else{
            //1 lowercase, 1 uppercase, 1 digit:
            let expression = new RegExp ("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)");
            let accept = expression.test(details.p1);
            let space = new RegExp ("^(?=.*\\s)"); //if the password has spaces
            let hasSpace = space.test(details.p1);
            if(details.p1.length < 6 || !accept){
                setValidPasswordMessage("*Password does not meet the requirements.");
                setAcceptPassword(false);
            }
            else if(hasSpace){
                setValidPasswordMessage("*Spaces not allowed.");
                setAcceptPassword(false);
            }
            else if(details.p1 === details.email1){
                setValidPasswordMessage("*Password cannot be the same as the email.");
                setAcceptPassword(false);
            }            
            else{
                setValidPasswordMessage("");
                setAcceptPassword(true);
            }
        }
        
      },[details.p1]);

      const togglePassword = ()=>{
        setShowPassword(!showPassword);
      }

    function changeDetails(e){    
        setDetails({...details,[e.target.name]:e.target.value});
        if(e.target.name === "email1")    {
            setEmailAvail(true)
        }
    }

    const registerUser = async (event) =>{
        event.preventDefault();
        if(details.email1 === details.email2 && details.p1 === details.p2 && acceptPassword){
            const available = await checkEmail();
            if(available === "YES"){
              handleShow();
            }
            else{
                setEmailAvail(false);
            }
        }
    }

    
    const checkEmail = async () => {
        const response = await fetch('/api/playerEmailAvailable/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email: `${details.email1}` })
        });
        const data = await response.json();        
        return data.message;
    }

    const addPlayer = async () =>{
        const response = await fetch('/api/createPlayer/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                first_name: `${details.fName}`, 
                last_name: `${details.lName}`,
                email: `${details.email1}`,
                password: `${details.p1}`,
                playername: `${details.pName}`,
                dob: `${details.dob}`,
             })
        });
        const data = await response.json();      
        return data.message; 
    }

    const renderTooltip = (props) => (
        <Tooltip id="link-tooltip"  {...props}>        
          Minimum 6 characters, at least 1 lowercase, 1 uppercase & 1 digit required
        </Tooltip>
      );

      const emailTooltip = (props) => (
        <Tooltip id="link-tooltip"  {...props}>        
          An account activation link would be sent to this email.
        </Tooltip>
      );

    return (
    <>
        <Page title='Create An Account - Step 2'/>
                <Row className="p-1 border border-2 border-warning rounded">
        <Row classname = "my-1">
          <Col sm={12}>
            <div className='text-center my-3'>
              <Image src={require('../assets/logos/IQlogo.png')} width="20%" height="auto"/>
            </div>
          
          <h2 className="mb-3 text-center form-heading"> Create An Account (Step 2) </h2>
          <p className="form-extras "> *Required Field</p>
          </Col>
        </Row>

      
          
        <Form className='p-1' onSubmit={registerUser} >
            <Row className='px-1'>
              <Col md={12} lg={6}>
              <Form.Group className="mb-3" >
                <Form.Label className="form-labels">    Email* 
                <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={emailTooltip}
                >
                <Link to="" style={{ color: 'black'}}>{< FiInfo/>}</Link>  
              </OverlayTrigger>
                </Form.Label>
                <Form.Control type="email"  value={details.email1} maxlength= "100" name = 'email1' onChange={changeDetails} required/>
                { ( !emailAvail)  ?(
                    <Form.Text className="text-danger"> *Email is already registered </Form.Text>)
                    : (<Form.Text className="text-danger">  </Form.Text>)
                }
            </Form.Group>
              </Col>

              <Col md={12} lg={6}>
              <Form.Group className="mb-3" >
                <Form.Label className="form-labels">Confirm Email*</Form.Label>
                <Form.Control type="email" value={details.email2}  name = 'email2' onChange={changeDetails} required/>
                <Form.Text className="text-danger">
                    {emailMessage}
                </Form.Text>
            </Form.Group>

              </Col>
            </Row>

            <Row className='px-1'>
              <Col md={12} lg={6}>
              <Form.Group className="mb-3" >
                <Form.Label className="form-labels">
                    Password*                    
                    <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip}
                >
                <Link to="" style={{ color: 'black'}}>{< FiInfo/>}</Link>  
              </OverlayTrigger>
                    
                </Form.Label>
                <Form.Control type={showPassword ? "text" : "password"} maxlength= "100" value={details.p1}  name = 'p1' onChange={changeDetails} required>
                </Form.Control>

                <Form.Text className="text-danger">
                    {validPasswordMessage}
                </Form.Text>
            </Form.Group>
              </Col>

              <Col md={12} lg={6}>
              <Form.Group className="mb-3" >
                <Form.Label className="form-labels"> 
                Confirm Password*
                <Link className="px-1"to=""  style={{ color: 'black' }} onClick={() => togglePassword()}>
                            {showPassword ?  ( <FiEyeOff /> )  : ( <FiEye/> )}
                 </Link>
                </Form.Label>
                <Form.Control type={showPassword ? "text" : "password"}  value={details.p2}  name = 'p2' onChange={changeDetails} required/>
                <Form.Text className="text-danger">
                    {passwordMessage}
                </Form.Text>
            </Form.Group>
              </Col>
            </Row>        
                       

            <Row className="my-2 px-1">
              <Col sm={12} >
              <div className="text-center">
            
              <Button variant="primary"  type='submit'>
                Create Account
              </Button>
            <Button variant="primary" className='mx-2' onClick={() => navigate("/register")}>
                Back to Step 1
            </Button>
            <Button variant="outline-danger"  className='mx-2' onClick={() => navigate("/begin/")}>
                Cancel Registration
            </Button>

          </div>  
              </Col>
              
            </Row>

            
        </Form>
        

      </Row>
      <Row className="m-4">

      </Row>

      <Modal show={show} onHide={handleClose} centered backdrop='static' 
          keyboard={false} size="lg" scrollable={true}>  
        <Modal.Header closeButton className='py-1'>
            <Modal.Title className='currency fs-3 text-center'> 
            Terms of Service for IQTT2.0 Online Investment Game
            </Modal.Title>
          </Modal.Header>      
          <Modal.Body className='currency fs-5'> 
            <p className='fs-5 '> 
            Please read this Terms of Service carefully. This administers your access to 
            and use of the online educational platform and information offered by the 
            Trinidad and Tobago Securities Exchange Commission (the "TTSEC").  
            </p> 
            <p className='fs-5 '> 
            By clicking "I accept", you agree to be bound to the terms and conditions of 
            these terms of service, as it may be amended from time to time. 
            You also acknowledge all provisions as laid out in the TTSEC's  Privacy Policy
             Statement. If you do not agree to the terms outlined in this Terms of 
             Service then you may not use the platform: 
            </p> 
            <ul>
            <li>
            The IQTT 2.0 platform is intended to educate and engage participants and 
            shall not be construed as offering investment advice.
            </li>
            <li>
            There are no Fees or costs charged for your use of the IQTT 2.0 educational platform.
            </li>
            <li>
            Inappropriate behavior or illegal conduct will not be tolerated under any 
            circumstances.
            </li>
            <li>
            Participants that do not comply with the IQTT 2.0 Terms of Service will be 
            removed from the platform.
            </li>
            <li>
            The authority to remove a participant from the platform resides solely with the TTSEC.
            </li>
            <li>
            Participants are responsible for maintaining the secrecy of their password 
            and the integrity of their portfolio. 
            </li>
            <li>
            Participants are responsible for any opening or closing transactions entered 
            in their portfolios.
            </li>
            <li>
            Participants may not enter transactions in portfolios other than their own. 
            </li>
            <li>
            Only transactions stored on the IQTT2.0 database will be recognised for the purposes 
            of evaluating portfolio rankings.
            </li>
            <li>
            In its sole discretion, the TTSEC may modify or replace, any terms of these Terms 
            of Service; or change, suspend or discontinue the platform without notice. 
            </li>
            <li>
            By entering into these Terms of Service, you consent to electronic delivery 
            of all current and future forms of advertisements and e-mail alerts. 
            </li>
            <li>
            TTSEC makes no representation that the IQTT2.0 platform or the content is 
            appropriate or available for use in locations outside of Trinidad and Tobago. 
            </li>  
          </ul>
                    
        </Modal.Body>
        <Modal.Footer className=' py-1'>
            <Button variant="primary" onClick={()=> navigate("/register/confirm")}> I Accept </Button> 
            <Button variant="primary" onClick={handleClose}> Decline </Button> 
        </Modal.Footer>
    </Modal> 
       
    </>
    );
}

export default Register2;