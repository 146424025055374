import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import { useOutletContext, } from 'react-router-dom';
import {  useState,  useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import LoadingSpinner from '../Components/LoadingSpinner';
import { DateTime } from "luxon";
import Page from "../Components/Page";



const BondsOwnedHistory = ()=>{

    const[showPage, setShowPage] = useState(false);
    const [bHistory, setBHistory] = useState([]);
    const [gameData, setGameData] = useOutletContext();
    var CurrencyFormat = require('react-currency-format');

    useEffect(() => {
        getBondHistory();
      }, []);

    const getBondHistory = async () =>{
        const response = await fetch('/gameapi/getAllBondsOwnedHistory/', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
              qpID: `${gameData.id}`
             })
        });
        const data = await response.json(); 
        if(! ('message' in data))  {    //24th may  update: all_pending_transactions   in views
            setBHistory(data);
        }  
        setShowPage(true);
      }


    return(<>
    <Page title='History of Owned Bond'/>
    <Row className='border border-2 border-dark mb-5'>

        

    <h1 className='mt-3 mb-3 quest-play-sectionheadings2 text-center '>
   Bonds Owned History
        </h1>
 
        <p className='fs-6 text-danger  text-center currency'> 
        Displays details for bonds that have been sold or matured.
            </p>

 

    {(showPage) ?
    <>
    <Row className='mt-4 mb-5'> 
        <Col xs={1}></Col>   
        <Col xs={10} > {/* main section*/}

        {(bHistory.length == 0) ?
        <Row className='mb-3'>
            
            <p className='currency fs-4 text-center text-primary'>
                There are currently no transaction details to display.
            </p>
        </Row>
        :
        <>
        <Table responsive='lg'>
            <thead>
                <tr className='text-primary fs-5 currency '>
                    <th> Date </th>
                    <th> Issue ID </th>
                    <th> Company </th>
                    <th> Action </th>
                    <th className='text-end'> Cash Received </th>
                </tr>
            </thead>
            <tbody>
            { bHistory.map((h, index)=>(
                <tr key={index} className='fs-6 roboto-font'> 
                <td >
                {DateTime.fromISO(h.activity_date).toLocaleString(DateTime.DATE_MED)} 
                </td>
                <td>  B-{h.bond_id} </td>
                <td >
                <Image  src={require(`../assets/logos/${h.b_image}`)}   width="45px" height="auto" />
                    {h.b_name}
                 </td>
                
                <td className='text-success'>{h.activity}  </td>                
                
                
                <td className='text-end'>
                <CurrencyFormat value={parseFloat(h.value_received)}  
                            fixedDecimalScale={2} decimalScale={2} displayType={'text'} 
                            thousandSeparator={true} prefix={'$'} 
                    /> 
                </td>
                
                
            </tr>
        ))}

            </tbody>
       
</Table>

  



            

        </>            
        }

        
        
        </Col> {/* end main section*/}
        <Col xs={1}></Col> 
    </Row>



    </>
    :
    <div className="p-3 mb-3 text-center"> 
        <LoadingSpinner/>  
    </div>

    }
    </Row>   
    </>);
}

export default BondsOwnedHistory;