export const getPlayerData = async (authToken)=>{
    const response = await fetch('/api/playerDetails/', {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + String(authToken.access)
        },            
    })
    const data = await response.json()
    return data;
}

export const getAdminData = async (authToken)=>{
    const response = await fetch('/api/adminDetails/', {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + String(authToken.access)
        },            
    })
    const data = await response.json()
    return data;
}