import { useState, useContext, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useOutletContext, useNavigate } from 'react-router-dom';
import ProfileChoice from './ProfileChoice';
import Page from "../Components/Page";


const Step3 = ()=>{
    const navigate = useNavigate();
    const [data, setData] = useOutletContext();
    const [yourAnswer, setYourAnswer] = useState("");

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () =>  setShow1(true);

    useEffect(() => {
        setData(original => ({...original, job_profile: yourAnswer}));  
    }, [yourAnswer]);

      //update this to add broker& profile and create a new quest
      
      const handleChange = (e) => {
        e.persist();
        setYourAnswer(e.target.value);
      };

      const handleSubmit = (event)=>{
        event.preventDefault();
        if(data.job_profile === '')
            handleShow();
        else
            handleShow1();
        
      }


    



    return (<>
    <Page title='Select a Profile - Get Started'/>
    <Row className='starting-header'>  
        
        <div className="p-1 mb-2 text-center" >
        <span className="text-light fs-1 roboto-font"> Inve$tor Quest TT 2.0</span>  
        
        <p className="text-light fs-3 roboto-font"> 
        Unlock The Investor In You! </p>
            
        </div>   

        <div>
        <span className="text-warning fs-5 roboto-font">   Selecting A Profile </span>
        </div>

    </Row>

    <Row className='border border-2 border-dark mb-5'>
    <Col xs={1}></Col> 

    <Col xs={10} > 
        <h2 className="text-center trade-heading1 mt-3 mb-3"> Your Profile </h2>

        
        <Row> {/* Playing the game section */}
    <Col>
    
    {/* Start Playing the game */}
    <p className='ttsec-blue fs-4 roboto-font '> Your Virtual Career: 
    <span className='getting-started-c1'> Who would you like to be in Investor Quest? </span></p> 
    
    <p className='fs-5 currency justify-text mb-5'>
    To make your profile space more personalised, we are giving you the opportunity to select an 
    Avatar which will showcase your fictitious career path. 
    View the options below and select one that best represents you. If you can't make a decision, 
    select the random option and one will be automatically assigned to you. 
    Remember, learning is fun! 
    </p>

    <p className='bg-dark px-2 py-1 mb-3'>
        <span className="text-light fs-4 roboto-font"> Virtual Career: </span> 
        <span className="quest-color fs-4 roboto-font"> Select One </span> 
    </p>
    
    <Row className='p-3'>
    <Form  onSubmit={handleSubmit} >
    <Form.Group controlId="yourAnswer" required>
      <Row>
        <Col sm={12} md={6}>
          <Row>
            <Col>
            <div className='text-center'>
            <ProfileChoice career={'Teacher'} i={'teacher.png'}/>
                  <Form.Check
                    value="Teacher"
                    type="radio"
                    id="Teacher"
                    onChange={handleChange}
                    checked={yourAnswer === "Teacher"}
                    className='my-1 '
                  />
            </div>            
            </Col>
            <Col>
            <div className='text-center'>
            <ProfileChoice career={'Lawyer'} i={'lawyer.png'}/>
                  <Form.Check
                    value="Lawyer"
                    type="radio"
                    id="Lawyer"
                    onChange={handleChange}
                    checked={yourAnswer === "Lawyer"}
                    className='my-1'
                  />
              </div>
            </Col>
          </Row>      
        </Col>

        <Col sm={12} md={6}>
        <Row>
            <Col>
            <div className='text-center'>
            <ProfileChoice career={'Accountant'} i={'accountant.png'}/>
                <Form.Check
                    value="Accountant"
                    type="radio"
                    id="Accountant"
                    onChange={handleChange}
                    checked={yourAnswer === "Accountant"}
                    className='my-1'
                  />
            </div>            
            </Col>
            <Col>
            <div className='text-center'>
            <ProfileChoice career={'Doctor'} i={'doctor.png'}/>
                <Form.Check
                    value="Doctor"
                    type="radio"
                    id="Doctor"
                    onChange={handleChange}
                    checked={yourAnswer === "Doctor"}
                    className='my-1'
                  />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <hr/>

      <Row>
        <Col sm={12} md={6}>
          <Row>
            <Col>
            <div className='text-center'>
            <ProfileChoice career={'Carnival Promoter'} i={'event_planner.png'}/>
                <Form.Check
                    value="Carnival Promoter"
                    type="radio"
                    id="Carnival Promoter"
                    onChange={handleChange}
                    checked={yourAnswer === "Carnival Promoter"}
                    className='my-1'
                  />
            
            </div>            
            </Col>
            <Col>
            <div className='text-center'>
            <ProfileChoice career={'Financial Planner'} i={'financial_planner.png'}/>
                  <Form.Check
                    value="Financial Planner"
                    type="radio"
                    id="Financial Planner"
                    onChange={handleChange}
                    checked={yourAnswer === "Financial Planner"}
                    className='my-1'
                  />
              </div>
            </Col>
          </Row>      
        </Col>

        <Col sm={12} md={6}>
        <Row>
            <Col>
            <div className='text-center'>
            <ProfileChoice career={'Maxi Driver'} i={'maxi_driver.png'}/>
                <Form.Check
                    value="Maxi Driver"
                    type="radio"
                    id="Maxi Driver"
                    onChange={handleChange}
                    checked={yourAnswer === "Maxi Driver"}
                    className='my-1'
                  />
            </div>            
            </Col>
            <Col>
            <div className='text-center'>
            <ProfileChoice career={'Soca Artist / DJ'} i={'soca_artist_dj.png'}/>
                <Form.Check
                    value="Soca Artist / DJ"
                    type="radio"
                    id="Soca Artist / DJ"
                    onChange={handleChange}
                    checked={yourAnswer === "Soca Artist / DJ"}
                    className='my-1'
                  />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <hr/>

      <Row>
        <Col sm={12} md={6}>
          <Row>
            <Col>
            <div className='text-center'>
            <ProfileChoice career={'Aesthetician'} i={'aesthetician.png'}/>
                  <Form.Check
                    value="Aesthetician"
                    type="radio"
                    id="Aesthetician"
                    onChange={handleChange}
                    checked={yourAnswer === "Aesthetician"}
                    className='my-1'
                  />
            </div>            
            </Col>
            <Col>
            <div className='text-center'>
            <ProfileChoice career={'Influencer'} i={'influencer.png'}/>
                  <Form.Check
                    value="Influencer"
                    type="radio"
                    id="Influencer"
                    onChange={handleChange}
                    checked={yourAnswer === "Influencer"}
                    className='my-1'
                  />
            </div>
            </Col>
          </Row>      
        </Col>

        <Col sm={12} md={6}>
        <Row>
            <Col>
            <div className='text-center'>
            <ProfileChoice career={'Doubles Vendor'} i={'doubles_vendor.png'}/>
                  <Form.Check
                    value="Doubles Vendor"
                    type="radio"
                    id="Doubles Vendor"
                    onChange={handleChange}
                    checked={yourAnswer === "Doubles Vendor"}
                    className='my-1'
                  />
            </div>            
            </Col>
            <Col>
            <div className='text-center'>
            <ProfileChoice career={'Athlete'} i={'athlete.png'}/>
                <Form.Check
                    value="Athlete"
                    type="radio"
                    id="Athlete"
                    onChange={handleChange}
                    checked={yourAnswer === "Athlete"}
                    className='my-1'
                  />
            </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <hr/>

      <Row>
        <Col sm={12} md={6}>
          <Row>
            <Col>
            
            <div className='text-center'>
            <ProfileChoice career={'Web Developer'} i={'web_developer.png'}/>
                <Form.Check
                    value="Web Developer"
                    type="radio"
                    id="Web Developer"
                    onChange={handleChange}
                    checked={yourAnswer === "Web Developer"}
                    className='my-1'
                  />
            </div>            
            </Col>
            <Col>
            <div className='text-center'>
            <ProfileChoice career={'Tour Guide'} i={'tour_guide.png'}/>
                  <Form.Check
                    value="Tour Guide"
                    type="radio"
                    id="Tour Guide"
                    onChange={handleChange}
                    checked={yourAnswer === "Tour Guide"}
                    className='my-1'
                  />
              </div>
            </Col>
          </Row>      
        </Col>

        <Col sm={12} md={6}>
        <Row>
            <Col>
            <div className='text-center'>
            <ProfileChoice career={'Farmer'} i={'farmer.png'}/>
                <Form.Check
                    value="Farmer"
                    type="radio"
                    id="Farmer"
                    onChange={handleChange}
                    checked={yourAnswer === "Farmer"}
                    className='my-1'
                  />
            
            </div>            
            </Col>
            <Col>
            <div className='text-center'>
            <ProfileChoice career={'Clerical Assistant'} i={'clerical_assistant.png'}/>
                <Form.Check
                    value="Clerical Assistant"
                    type="radio"
                    id="Clerical Assistant"
                    onChange={handleChange}
                    checked={yourAnswer === "Clerical Assistant"}
                    className='my-1'
                  />
            </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <hr/>

      <Row>
        <Col sm={12} md={6}>
          <Row>
            <Col>
            
            <div className='text-center'>
            <ProfileChoice career={'Social Media Manager'} i={'social_media.png'}/>
                  <Form.Check
                    value="Social Media Manager"
                    type="radio"
                    id="Social Media Manager"
                    onChange={handleChange}
                    checked={yourAnswer === "Social Media Manager"}
                    className='my-1'
                  />
            
            </div>            
            </Col>
            <Col>
            <div className='text-center'>
            <ProfileChoice career={'Random'} i={'random_av.png'}/>
                <Form.Check
                    value="Random"
                    type="radio"
                    id="Random"
                    onChange={handleChange}
                    checked={yourAnswer === "Random"}
                    className='my-1'
                  />
              </div>
            </Col>
          </Row>      
        </Col>

        <Col sm={12} md={6}>
        <Row>
            <Col>
            <div className='text-center'>
            
            </div>            
            </Col>
            <Col>
            <div className='text-center'>
            
            </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <hr/>

        

        </Form.Group>
        

        <Row className='my-3'>
        <Col className='text-end'>
            <Button variant="warning" type="submit">  
                Select Profile
            </Button>
        </Col>
      </Row> 
     </Form>

    </Row>

  {/* End Playing the game */}
  </Col>
  </Row>

         


    </Col>
    
    <Col xs={1}></Col> 
    </Row>

    <Modal show={show} onHide={handleClose} centered>  
        <Modal.Header closeButton className='bg-dark py-1'>
            <Modal.Title className='text-light fs-3 text-center  '> 
            Selecting Your Career
            </Modal.Title>
          </Modal.Header>      
        <Modal.Body className='bg-light'> 
            <p className='fs-4 '> <span className='text-primary'> You didn't select a 
            virtual Career. </span>
           </p> 
                    
        </Modal.Body>
        <Modal.Footer className='bg-dark py-1'>
            <Button variant="warning" onClick={handleClose}> Continue </Button>             
        </Modal.Footer>
    </Modal>

    <Modal show={show1} onHide={handleClose1} centered>  
        <Modal.Header closeButton className='bg-dark py-1'>
            <Modal.Title className='text-light fs-3 text-center  '> 
            Selecting Your Career
            </Modal.Title>
          </Modal.Header>      
        <Modal.Body className='bg-light'> 
            
              {(yourAnswer !== "Random")?
              <p className='fs-5 '> <span className='text-primary'>
                <span className='text-primary'></span>You selected {yourAnswer}. </span>
                Click confirm to continue.
              </p>               
            :
            <p className='fs-5 '> <span className='text-primary'>
              <span className='text-primary'></span>You would be randomly assigned a career. </span>
              Click confirm to continue.
            </p>
            }
              
                    
        </Modal.Body>
        <Modal.Footer className='bg-dark py-1'>
            <Button variant="warning" onClick={() => navigate("/start/details")}> Confirm </Button>    
            <Button variant="warning" onClick={handleClose1}> Cancel </Button>          
        </Modal.Footer>
    </Modal>


   

    </>)
}

export default Step3;
