import { useNavigate, useOutletContext, Link } from 'react-router-dom';
import {useState, useEffect} from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from "react-bootstrap/Modal";
import coins from '../assets/audio/coins.mp3';
import congrats from '../assets/audio/congrats.mp3';
import Page from "../Components/Page";



const BuyStocks = ()=>{
    const navigate = useNavigate();
    var CurrencyFormat = require('react-currency-format');
    const [gameData, setGameData] = useOutletContext();
    const [up, setUp] = useState(0);
    const [stocks, setStocks] = useState([{}])
    const [showStocks, setShowStocks] = useState(false);
    const [pendingTrades, setPendingTrades] = useState([]);
    const [cashSpending, setCashSpending] = useState();
    const [availableCash, setAvailableCash] = useState(0);

    const [brokerName, setBrokerName] = useState("");
    const [exchFee, setExchFee] = useState(0);
    const [brFee, setBrFee] = useState(0);

    const [buyId, setBuyId] = useState(-1);
    const [buyQty, setBuyQty] = useState(1);
    const [buyPrice, setBuyPrice] = useState(0);
    const [totalBuy, setTotalBuy] = useState();
    const [brokerFeeBuy, setBrokerFeeBuy] = useState();
    const [exchangeBuy, setExchangeBuy] = useState();
    const [feeBuy, setFeeBuy] = useState();
    const audio = new Audio(coins);
    const audio2 = new Audio(congrats);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show1, setShow1] = useState(false);
    const handleClose1 = () => {
        setShow1(false);
        navigate(`/game/quest/${gameData.quest.id}`);
    }    
    const handleShow1 = () => {
        setShow1(true);
        audio.play();
    }

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => {
        setShow2(false);
        navigate(`/game/quest/${gameData.quest.id}`);
    }
    const handleShow2 = () => {
        setShow2(true);
        audio2.play();
    }

    const [show4, setShow4] = useState(false);
    const handleClose4 = () => setShow4(false);
    const handleShow4 = () => setShow4(true);
    

    useEffect(() => { 
        getStocksOnly();
        getAvailableCash();
        getBrokerRates();
      },[]);

      useEffect(() => { 
        if (buyId !== -1)
         updatePrice();
      },[buyId]);

      useEffect(() => { 
        if (up > 0)
         calculateFees();
      },[up]);

    const getBrokerRates = ()=>{
        if (gameData.player.broker_tag === 'Elite'){
            setBrokerName('Elite Securities Limited');
            setExchFee(0.14);
            setBrFee(1.5);
        }
        else if (gameData.player.broker_tag === 'Capital'){
            setBrokerName('360 Capital Market Brokers Limited');
            setExchFee(0.14);
            setBrFee(1.25);
        }
        else{
            setBrokerName('Butterfly Interactive Brokers Limited');
            setExchFee(0.14);
            setBrFee(1.0);
        }
    }

    const getAvailableCash = ()=>{
        let spend = parseFloat(gameData.cash_value) - parseFloat(gameData.fees_owed);
        setAvailableCash(spend.toFixed(2));
    }

    const changeBuyId = (e)=>{
        setBuyId(e.target.value);  
        let p = findPrice(e.target.value); 
        setBuyPrice(p);
    }

    const updatePrice = (e)=>{ 
        let p = findPrice(buyId); 
        setBuyPrice(p);
    }

    const changeBuyQty = (e)=>{
        setBuyQty(e.target.value);  
    }


    const calculateFees = ()=>{  
        let total = 0;
        pendingTrades.map((pt, index) => {
            total = total + parseFloat(pt.total);
        });      
        
        let bFee = ((brFee/100) * total);
        let eFee = ((exchFee/100) * total);
        let fees = (total + bFee + eFee);
        setTotalBuy(total.toFixed(2));
        setBrokerFeeBuy(bFee.toFixed(2));
        setExchangeBuy(eFee.toFixed(2));
        setFeeBuy(fees.toFixed(2));

        let spend = parseFloat(gameData.cash_value) - (fees + parseFloat(gameData.fees_owed));
        setCashSpending(spend.toFixed(2));
    }

    const getStocksOnly = ()=>{        
        gameData.quest.stocks_and_mf.map((s, index) => {
            if (s.type === 'STOCK')
                setStocks(original => [...original, s])
        });
        setShowStocks(true);          
    }

    const findStock = (sid)=>{
        const searchIndex = stocks.findIndex((s) => s.id == sid);
        return stocks[searchIndex];
    }

    const findPrice = (sid)=>{
        const searchIndex = stocks.findIndex((s) => s.id == sid);
        if (searchIndex < 0)
            return 0;
        else
            return stocks[searchIndex].current_value;
    }

    const alreadyAdded = (sid)=>{
        const index = pendingTrades.findIndex((s) => s.id == sid);
        if(index < 0)
            return false;
        return true;
    }

    const deleteStock = (sid)=>{
        setPendingTrades((current) =>
            current.filter((s) => {
              return s.id !== sid;
            }))

            setUp(up+1);
      }

    const addTrade = (e)=>{
        e.preventDefault();
        let added = alreadyAdded(buyId);
        if(added)
        {
            handleShow();
            return;
        }
        let stock = findStock(buyId); 
        let costStock = (buyQty * parseFloat(stock.current_value)).toFixed(2); 
        
        let s = {id: buyId, 
                name: stock.registered_entity, 
                quantity: buyQty, 
                current_price: stock.current_value,
                action: 'BUY',
                total: costStock,
                questId: gameData.id
       };
       setPendingTrades(original => [...original, s] );
       setUp(up+1);
    }

    const executeTrade = async () =>{
        const response = await fetch('/gameapi/tradeStatus/', {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        });
        const data = await response.json(); 
        if(data.message === 'YES'){
            
            const response = await fetch('/gameapi/amountCashAvailable/', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                  qpID: `${gameData.id}`,
                 })
            });
            const data = await response.json();
            let cash_amt = parseFloat(data.cash_avail)
            if (cash_amt < feeBuy){
                alert("Your data is out of sync, unable to complete trade.");
                navigate(`/game/`);
                return;
            }

            executeBuy();
        }
        else
            handleShow4();

    }


    const executeBuy = ()=>{
        let v = JSON.stringify(pendingTrades)
        let s = `{"stocks" : ${v}, "fees": "${feeBuy}", "qpID": "${gameData.id }"}`;        
        let totalFee = (parseFloat(feeBuy) + parseFloat(gameData.fees_owed)).toFixed(2);
        setGameData(original => ({...original, fees_owed: totalFee}))
        buyStocks(s);     
    }

    const buyStocks = async (jsonString) =>{
        const response = await fetch('/gameapi/addPendingTransaction/', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: jsonString
        });
        const data = await response.json(); 
        if(data.message === 'PENDING_VALUES_UPDATES'){
            if(gameData.active === 'NO'){
                handleShow1();
                setGameData(original => ({...original, active: 'YES'}))
            }
            else
                handleShow2();
                
            setPendingTrades([]);
        }
        else
            alert('Unable to complete transaction, try agina later');        
        
      }

    

return(<>
    <Page title='Stock Trading - Buy'/>
    <Row className='border border-2 border-dark mb-3'>

    
    <Row className='my-3'> 
        <Col xs={1}></Col>

        <Col xs={10} >
        <h1 className='my-3 trading-main-header text-center '>Stock Trading 
        <span className='roboto-font fs-3 inv-color'> (Buy) </span> 
        </h1>
        <Table bordered className='mt-3 mb-2 px-5 bg-dark'>
            <tbody>
            <tr >
                <td className='fs-4 currency text-info'>
                    Available Cash: <CurrencyFormat value={parseFloat(availableCash)}  
                            fixedDecimalScale={2} decimalScale={2} displayType={'text'} 
                            thousandSeparator={true} prefix={'$'} 
                        />
                    <span className='fs-5 text-light'> (Cash Value - Pending Fees) </span></td>
            </tr>
            </tbody>            
        </Table>

        <Row className="mb-4 ">   {/* start heading info */} 

        <Col >                    
        <Table className='p-2 my-2'>
              <tr className='border-bottom'>
                    <td className="quest-play-subheading2">  Broker:</td>
                    <td className= "fs-4 currency text-end "> {brokerName} </td>
                </tr>

                <tr className='border-bottom'>
                    <td className="quest-play-subheading2">  Broker Fees:</td>
                    <td className= "fs-4 currency text-end "> 
                    <span className='text-primary'> {brFee.toFixed(2)}% </span> of Transaction </td>
                </tr>

                <tr className='border-bottom'>
                    <td className="quest-play-subheading2">  Stock Exchange Fees:</td>
                    <td className= "fs-4 currency text-end "> 
                    <span className='text-primary'> {exchFee.toFixed(2)}% </span> of Transaction </td>
                </tr>
                
            </Table>                     
        </Col>   
                             
    </Row> {/* end heading info */}

        <h3 className='my-3 trade-heading1'>Select Stocks to Buy: </h3>
        <Form className=' mb-3 py-1' onSubmit={addTrade} > 
        <Row className='px-1'>
              <Col md={12} lg={6}>   
              <Form.Group className="mb-1" >
                <Form.Label className="form-labels fs-5">
                  Company:
                </Form.Label>
                <Form.Select className="mb-3"  value={buyId}   onChange={changeBuyId} required>
                { stocks.map((s, index)=>(                            
                    <option key={s.id} value={s.id}> {s.registered_entity}  </option>
                ))}
            
                </Form.Select>
            </Form.Group>
            </Col>  
            <Col md={12} lg={6}>
            <Form.Group className="mb-1" >
              <Form.Label className="form-labels fs-5">
                Quantity: 
              </Form.Label>
              <Form.Control type="number" min='1' value={buyQty} onChange={changeBuyQty}  required/>
            </Form.Group>
            </Col>
            </Row>

            <Row>
            <Col md={12} lg={6}> 
             <Form.Group className="mb-1" >
              <Form.Label className="currency fs-4">
                Market Price: 
                <CurrencyFormat value={parseFloat(buyPrice)}  
                    fixedDecimalScale={2} decimalScale={2} displayType={'text'} 
                    thousandSeparator={true} prefix={'$'} 
                />
              </Form.Label>              
            </Form.Group>
            </Col>
           
            <Col md={12} lg={6}> 
              <div className="text-end">
            
            <Button variant="primary" type="submit" >
              Add Stock
            </Button>
            
            </div>  
              </Col>

            </Row>
        </Form>

        </Col>

        <Col xs={1}></Col>
    </Row>

    
    <Row className='my-3 ' >
            

            <Col xs={0} sm={1} ></Col>

            <Col sm={10} >
            <h3 className='my-3 trade-heading2 '> Buy Order: </h3>     
            {(pendingTrades.length === 0) ?
                <p className='text-secondary fs-5 '> No Stocks selected - Add stocks in order to buy them. </p>
            :
            <>
                <Table responsive='sm' bordered className='my-3'>
                    <thead className='fs-5 text-white bg-secondary text-center'>
                        <th> Company</th>
                        <th> Market Price </th>
                        <th> Quantity </th>
                        <th> Sub-Total </th>
                        <th> </th>
                    </thead>
                    <tbody className='fs-5  '>
                    {pendingTrades.map((pt, index)=>(
                    <tr key={index} className='fs-5 currency '> 
                        <td> {pt.name} </td>
                        <td className='text-end'> 
                        <CurrencyFormat value={parseFloat(pt.current_price)}  
                            fixedDecimalScale={2} decimalScale={2} displayType={'text'} 
                            thousandSeparator={true} prefix={'$'} 
                        />
                        
                        </td>
                        <td className='text-center'> {pt.quantity}  </td>
                        <td className='text-end'> 
                        <CurrencyFormat value={parseFloat(pt.total)}  
                            fixedDecimalScale={2} decimalScale={2} displayType={'text'} 
                            thousandSeparator={true} prefix={'$'} 
                        />
                        </td>   
                        <td> <Button variant="danger" size='sm'
                               onClick={()=>deleteStock(pt.id)}> Remove </Button>
                        </td>                 
                    </tr>
                ))}

                <tr className='currency'>              
                    <td colSpan={3} className='text-end bg-light fw-bold text-primary'> Stocks Total Cost: </td>
                    <td className='text-end bg-light'>
                    <CurrencyFormat value={parseFloat(totalBuy)}  
                            fixedDecimalScale={2} decimalScale={2} displayType={'text'} 
                            thousandSeparator={true} prefix={'$'} 
                        />
                    </td>
                    <td></td>
                </tr>
                <tr className='currency'>              
                    <td colSpan={3} className='text-end bg-light fw-bold text-primary'> Broker Fee: </td>
                    <td className='text-end bg-light'>
                    <CurrencyFormat value={parseFloat(brokerFeeBuy)}  
                            fixedDecimalScale={2} decimalScale={2} displayType={'text'} 
                            thousandSeparator={true} prefix={'$'} 
                        />
                     </td>
                    <td></td>
                </tr>
                <tr className='currency'>              
                    <td colSpan={3} className='text-end bg-light fw-bold text-primary'> Stock Exchange Fee: </td>
                    <td className='text-end bg-light'>
                    <CurrencyFormat value={parseFloat(exchangeBuy)}  
                            fixedDecimalScale={2} decimalScale={2} displayType={'text'} 
                            thousandSeparator={true} prefix={'$'} 
                        />
                     </td>
                    <td></td>
                </tr>
                <tr className='currency'>              
                    <td colSpan={3} className='text-end bg-warning fw-bold'> Total: </td>
                    <td className='text-end bg-warning fw-bold'>  
                    <CurrencyFormat value={parseFloat(feeBuy)}  
                            fixedDecimalScale={2} decimalScale={2} displayType={'text'} 
                            thousandSeparator={true} prefix={'$'} 
                        />
                    </td>
                    <td></td>
                </tr>

                <tr>              
                    <td colSpan={5} className='text-end '> 
                    {(cashSpending >= 0)?
                    <Button variant="primary" 
                        onClick={executeTrade}> Place Order </Button>
                    :
                    <p className='fs-6 text-danger'> *You do not have enough cash to complete this trade.</p>
                    }
                    
                    
                    
                    </td>
                    
                </tr>

                    </tbody>
                </Table>
            
            </>
            }

            </Col>

            <Col xs={0} sm={1} >
            </Col>

    </Row>
    </Row>

    <Modal show={show} onHide={handleClose} centered>  
        <Modal.Header closeButton className='bg-dark py-1'>
            <Modal.Title className='text-light fs-3 text-center  '> 
              Buying Stocks
            </Modal.Title>
          </Modal.Header>      
        <Modal.Body className='bg-light'> 
            <p className='fs-5 text-primary'> This Stock has already been added. </p> 
                    
        </Modal.Body>
        <Modal.Footer className='bg-dark py-1'>
            <Button variant="warning" onClick={handleClose}> Continue </Button>             
        </Modal.Footer>
    </Modal>

    <Modal show={show1} onHide={handleClose1} centered backdrop='static' keyboard={false}>  
        <Modal.Header closeButton className='bg-dark py-1'>
            <Modal.Title className='text-light fs-3 text-center  '> 
              Trading
            </Modal.Title>
          </Modal.Header>      
        <Modal.Body className='bg-light'> 
            <p className='fs-5 text-primary'> Congratulations on making your first trade in {gameData.quest.name}. It
             would be executed within 24 hours.</p> 
                    
        </Modal.Body>
        <Modal.Footer className='bg-dark py-1'>
            <Button variant="warning" onClick={handleClose1}> Continue </Button>             
        </Modal.Footer>
    </Modal>

    <Modal show={show2} onHide={handleClose2} centered backdrop='static' keyboard={false}>  
        <Modal.Header closeButton className='bg-dark py-1'>
            <Modal.Title className='text-light fs-3 text-center  '> 
              Trading
            </Modal.Title>
          </Modal.Header>      
        <Modal.Body className='bg-light'> 
            <p className='fs-5 text-primary'> Your trade has been successfully posted. It would be executed 
             within 24 hours. </p> 
                    
        </Modal.Body>
        <Modal.Footer className='bg-dark py-1'>
            <Button variant="warning" onClick={handleClose2}> Continue </Button>             
        </Modal.Footer>
    </Modal>


    <Modal show={show4} onHide={handleClose4} centered backdrop='static' keyboard={false}>  
        <Modal.Header closeButton className='bg-dark py-1'>
            <Modal.Title className='text-light fs-3 text-center  '> 
              Trading
            </Modal.Title>
          </Modal.Header>      
        <Modal.Body className='bg-light'> 
            <p className='fs-5 text-primary'> Trading is currently closed while the system is being
             updated. Try back again in an hour.
            </p> 
                    
        </Modal.Body>
        <Modal.Footer className='bg-dark py-1'>
            <Button variant="warning" onClick={handleClose4}> Continue </Button>             
        </Modal.Footer>
    </Modal>

   


           

           
</>);
}

export default BuyStocks;