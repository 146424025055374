import { useNavigate, useParams } from 'react-router-dom';
import {useState, useEffect} from 'react';
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import LoadingSpinner from '../Components/LoadingSpinner';
import Modal from 'react-bootstrap/Modal';



const AddBondsToQuest = ()=>{
    const navigate = useNavigate();
    const{questId} = useParams();

    const [bondData, setBondData] = useState([{}]);
    const [loaded, setLoaded] = useState(false);
    const [showCheckBox, setShowCheckBox] = useState(true);
    const [selectedBonds, setSelectedBonds] = useState([]);
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);

    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
   

    useEffect(() => {
        getAllBonds();
      }, []);

      
      const getAllBonds = async ()=>{
        const response = await fetch('/gameapi/allBonds/', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        });
        const data = await response.json();
        if(data.length !== 0){
            setBondData(data);
            setLoaded(true);
        }        
    }   

  
    const updateSelectedBonds = (e)=>{ 
        //e.preventDefault();
        if(e.target.checked) 
            setSelectedBonds(selectedBonds => [...selectedBonds, e.target.id])
        else{
            setSelectedBonds((current) =>
                current.filter((v) => {
                return v !== e.target.id;
            }))
        }       
    }

    const addToQuest = async (e) =>{
        e.preventDefault();
        if(selectedBonds.length <= 0){
            handleShow();
        }
        else{
            var selectedIds = JSON.stringify({selectedIds: `${selectedBonds}`})
            selectedIds = selectedIds.substring(1, selectedIds.length)
            var jsonStr = `{"qid": "${questId}"` + "," + selectedIds;            

            const response = await fetch('/gameapi/addBondsToQuest/', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: jsonStr
            });
            const data = await response.json();
            if(data.message === "ERROR_ADDING_BONDS"){
                alert('Something went wrong! Try adding it again.');
            }
            else{
              handleShow1();
            } 

        }


    }

    return (<>

    <Row >
        <Col md={12} lg={1}>
        </Col>
        <Col md={12} lg={10}>
        
        <Row className="p-1 border border-2 border-primary rounded mb-5">             
            <h2 className="my-4 text-center form-heading"> Add Bonds to Quest (Step 4)  </h2>           

            {(loaded) ?
            <>
            <Form className=' mb-3' onSubmit={addToQuest} > 
            <Table responsive="sm" >
                <thead>    <tr>
                <th></th>
                <th className=' text-danger'>Company</th>
                <th className='text-danger'>Sector & Industry</th>
                 <th className='text-center text-danger'>Add To Quest?</th>
                </tr>  </thead>
                <tbody>
                
            { bondData.map((b, index)=>(         
                
                <tr key={b.id}> 
                    <td> 
                    <Image  src={require(`../assets/logos/${b.image}`)}  rounded width="50px" height="auto" />
                    </td>
                    <td className='fw-bold'>
                        {b.registered_entity}
                    </td>
                    <td>
                        {b.sector}, {b.industry}
                    </td>
                    <td>
                    {(showCheckBox)?
                    <>
                   <div className="text-center">
                   <Form.Check
                        name={b.id}
                        type='checkbox'
                        id={b.id}
                        onChange={updateSelectedBonds}                   
                    />
                </div>
                    </>
                    :<></>

                    }
                    
                    </td>
                </tr> 
            ))} 
            </tbody>
            </Table>
            <div className="text-center">
            
            <Button variant="primary" type="submit" >
              Add to Quest
            </Button>
            

          </div> 
            </Form>
            </>
            :
            <>
            <div className="p-3 mb-3 text-center">
                <LoadingSpinner/>
            </div>
            </>
            }

        </Row>

       

        </Col>

        <Col  md={12} lg={1}>
        </Col>
    
    </Row>

    <Modal show={show} onHide={handleClose} backdrop='static' centered>  
        <Modal.Header closeButton className='bg-dark py-1'>
            <Modal.Title className='text-light fs-3 text-center  '> 
              Adding Bonds to Quest
            </Modal.Title>
          </Modal.Header>      
        <Modal.Body className='bg-light'> 
            <p className='fs-4 '> You must select at least 1 Bond</p> 
                    
        </Modal.Body>
        <Modal.Footer className='bg-dark py-1'>
            <Button variant="warning" onClick={handleClose}> Close </Button> 
        </Modal.Footer>
    </Modal> 

    <Modal show={show1} onHide={handleClose1} backdrop='static' centered>  
        <Modal.Header closeButton className='bg-dark py-1'>
            <Modal.Title className='text-light fs-3 text-center  '> 
             Adding Bonds to Quest
            </Modal.Title>
          </Modal.Header>      
        <Modal.Body className='bg-light'> 
            <p className='fs-4 '> Bonds sucessfully added.</p> 
                    
        </Modal.Body>
        <Modal.Footer className='bg-dark py-1'>            
            <Button variant="warning" 
                onClick={() => navigate('/admin/manageQuest/')}
            > 
                Continue </Button>
            
            
        </Modal.Footer>
    </Modal>
    
    </>);
}

export default AddBondsToQuest;