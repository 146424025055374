import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Image from 'react-bootstrap/Image';
import { FaLongArrowAltDown, FaLongArrowAltUp, FaMinus } from "react-icons/fa";
import LoadingSpinner from '../Components/LoadingSpinner';
import { useState, useEffect } from "react";
import { Link } from 'react-router-dom';

const SecurityTable = ({sec_type}) =>{

    const [stockData, setStockData] = useState([{}]);
    const [stockPercentage, setStockPercentage] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [loadPercentage, setLoadPercentage] = useState(false);
    const [initialRender, setInitialRender] = useState(true);

    var CurrencyFormat = require('react-currency-format');

    useEffect(() => {
        getAllStocksOrMF();
      }, []);

      
      useEffect(() => {
        if(initialRender){
            setInitialRender(false);
          } else{
            calculatePercentChange();
          }        
      }, [loaded]);

      
      const getAllStocksOrMF = async ()=>{
        const response = await fetch('/gameapi/allStocksOrMF/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                type: `${sec_type}`,
             })
        });
        const data = await response.json();
        if(data.length !== 0){
            setStockData(data);
            setLoaded(true);
        }        
    }

    const calculatePercentChange = ()=>{
        setStockPercentage(new Array (stockData.length))
        for (let i = 0; i < stockData.length; i++) {
            var p = ((stockData[i].current_value-stockData[i].previous_value) 
                      /stockData[i].previous_value) * 100;
            stockPercentage[i] = p;            
        }
        var newArray = [...stockPercentage];
        setStockPercentage(newArray); 
        setLoadPercentage(true);
    }

    function percentChange(open, close){
        var p = ((open-close) /close) * 100;
        return p.toFixed(2);
    }

    return(<>

<Row>
        

        <Col sm={12} md={12} className="m-auto px-3 ">
        <Table responsive="sm" className=''>
        <thead className='text-secondary'>
          <tr>
            <th></th>
            <th>Ticker</th>
            <th>Name</th>
            <th className='text-end'>Today's Value</th>
            <th className='text-end'>Yesterday Close</th>
            <th className='text-center'>% Change</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
        { (loaded && loadPercentage) ?
                    <>
                      { stockData.map((s, index)=>(           
                
                <tr key={s.id}>
                <td>
                <Image  src={require(`../assets/logos/${s.image}`)}  rounded width="50px" height="auto" />
                </td>
                <td> <Link to={`/game/stockMarket/details/${s.id}`} >{s.ticker}</Link> </td> 
                <td className='fw-bold'>{s.registered_entity}</td>
                <td className='text-end currency fs-5'>
                <CurrencyFormat value={parseFloat(s.current_value)}  
                                    fixedDecimalScale={2} decimalScale={2} displayType={'text'} 
                                    thousandSeparator={true} prefix={'$'} 
                />
                </td>
                <td className='text-end currency fs-5'>
                <CurrencyFormat value={parseFloat(s.previous_value)}  
                                    fixedDecimalScale={2} decimalScale={2} displayType={'text'} 
                                    thousandSeparator={true} prefix={'$'} 
                />
                </td>
                {(stockPercentage[index] > 0) ?
                    <> 
                        <td className='text-success text-center'>{stockPercentage[index].toFixed(1)} %</td>
                        <td><FaLongArrowAltUp size={25} style={{ fill: 'green' }} /></td>
                    </>:
                    <> 
                        {(stockPercentage[index] < 0) ?
                            <> 
                            <td className='text-danger text-center'>{stockPercentage[index].toFixed(1)} %</td>
                            <td><FaLongArrowAltDown size={25} style={{ fill: 'red' }} /></td>
                            </>
                            :
                            <> 
                            <td className='text-dark text-center'>{stockPercentage[index].toFixed(1)} %</td>
                            <td><FaMinus size={25} style={{ fill: 'black' }} /></td>
                            </>

                        }
                        
                    </>
                }
                
                </tr>
                ))
             }
             </> :
            <>
            <tr>
                <td>  </td>
                <td> </td>
                <td> </td>
                <td><LoadingSpinner /> </td>
                <td> </td>
                <td> </td>
                <td> </td>
            </tr>
                    </>
            }
          
        </tbody>
      </Table>  

          

           
        </Col>

      </Row>
    
    
    </>);
}

export default SecurityTable;